import { NgModule } from '@angular/core';
import { FileListComponent } from './component/file-list/file-list.component';
import { DownloadFileComponent } from './page/download-file/download-file.component';
import { DeviceSharedModule } from '../device-shared/device-shared.module';
import { FileListCardComponent } from './component/file-list-card/file-list-card.component';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

const declarations = [FileListComponent, FileListCardComponent, DownloadFileComponent];
@NgModule({
  declarations: declarations,
  exports: declarations,
  imports: [DeviceSharedModule, TranslocoLocaleModule.forRoot()],
})
export class DeviceFilesModule {}
