import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line  @angular-eslint/directive-selector
  selector: 'app-list-view>ng-template[column]',
})
export class ListViewColumnDirective {
  @Input() column?: string;
  @Input() header?: string;
  @Input() sizing = ['col'];

  constructor(public template: TemplateRef<any>) {}
}
