import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Subscriptions } from '../../util/Subscriptions';
import { MessageInfo } from '../../model/errors';

@Component({
  selector: 'app-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputTextAreaComponent),
    },
  ],
})
export class InputTextAreaComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private subscriptions: Subscriptions = new Subscriptions();

  @Input() inputId: string = '';
  @Input() label: string = '';
  labelKey: string = '';

  @Input() placeholder: string = '';

  @Input() required: boolean = false;

  @Input() isValid: boolean = true;
  @Input() messageInfo?: MessageInfo;

  @Input() disabled: boolean = false;

  value: string = '';

  @Input() validateOnKeyPress: boolean = false;

  constructor(private translocoService: TranslocoService) {}

  onChange = (_: any) => {};
  onTouch = (_: any) => {};

  registerOnChange(fn: any): void {
    if (fn) this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    if (fn) this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onKeyup(target: EventTarget | null): void {
    if (this.validateOnKeyPress) {
      const input = target as HTMLInputElement;
      const value = input.value || '';
      this.writeValue(value);
    }
  }

  writeValue(value?: string): void {
    this.value = value || '';
    this.onChange(value);
  }

  ngOnInit(): void {
    this.labelKey = this.label;
    this.subscriptions.put(
      this.translocoService.langChanges$.subscribe(() => {
        if (this.labelKey) {
          this.label = this.translocoService.translate(this.labelKey);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
