import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogType } from '../confirm-dialog/model/confirmation-dialog-types';

@Component({
  selector: 'app-simple-modal',
  templateUrl: './simple-modal.component.html',
})
export class SimpleModalComponent {
  @Input() title: string = '';
  @Input() body: string = '';
  @Input() type: ConfirmDialogType = ConfirmDialogType.Info;

  @Input() useTranslationKeys = false;

  constructor(public activeModal: NgbActiveModal) {}
}
