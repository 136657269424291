import {
  createDeviceModelReference,
  DeviceLike,
  DeviceModelReference,
} from '../../device/device-shared/model/device.model';

export interface LatestDeviceModelFirmwareVersion {
  version: string;
  releasedAt: Date;
}

export interface AvailableDeviceModelFirmwareUpdate {
  deviceModel: DeviceModelReference;
  key: string;
  version: string;
  latestVersion: LatestDeviceModelFirmwareVersion;
}

export interface DeviceModelFirmwareVersionReference {
  deviceModel: DeviceModelReference;
  key: string;
  version: string;
}

export function createDeviceModelFirmwareVersionReferences(device: DeviceLike): DeviceModelFirmwareVersionReference[] {
  if (!device) {
    return [];
  }
  return device.firmwares.map(firmware => {
    return {
      deviceModel: createDeviceModelReference(device),
      key: firmware.key,
      version: firmware.version,
    };
  });
}
