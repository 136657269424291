import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent {
  @Input() name: string = '';
  @Input() value: string = '';
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  checked: boolean = false;
  
  private changeCallback: (value: any) => void = () => {};
  
  onInputChange(): void {
    if (!this.disabled) {
      this.checked = true;
      this.changeCallback(this.value);
    }
  }
  
  registerChangeCallback(fn: (value: any) => void): void {
    this.changeCallback = fn;
  }
}
