import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  Device,
  DeviceConnectionStatus,
  DeviceLike,
  DeviceModel,
  DeviceOperationalStatus,
} from 'src/app/device/device-shared/model/device.model';
import { DeviceModelFormatterPipe } from '../../pipe/device-model-formatter.pipe';
import { TranslocoService } from '@ngneat/transloco';
import { Subscriptions } from 'src/app/shared/util/Subscriptions';
import { ImageSize } from '../../../../shared/pipe/device-image.pipe';

@Component({
  selector: 'app-device-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() device?: Device;

  id!: string;
  name!: string;
  virtualLaboratoryName!: string;
  model!: DeviceModel;
  connectionStatus!: DeviceConnectionStatus;
  operationalStatus?: DeviceOperationalStatus;
  lastUpdateInformation!: string;

  imageMap = ImageSize;

  private subscriptions: Subscriptions = new Subscriptions();

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    if (this.device) {
      this.updateData(this.device);
    }
    this.subscriptions.put(
      this.translocoService.langChanges$.subscribe(_ => {
        if (this.device) {
          return (this.lastUpdateInformation = this.createLastUpdateInformation(this.device));
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.device.isFirstChange() && changes.device.currentValue) {
      this.updateData(changes.device.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  get modelString(): string {
    if (this.device) {
      return new DeviceModelFormatterPipe().transform(this.device.model);
    }
    return '';
  }

  private updateData(device: Device): void {
    this.id = device.id;
    this.name = device.name;
    this.virtualLaboratoryName = device.virtualLaboratory.name;
    this.model = device.model;
    this.connectionStatus = device.status.connection;
    this.operationalStatus = device.status.operational;
    this.lastUpdateInformation = this.createLastUpdateInformation(device);
  }

  private createLastUpdateInformation(device: DeviceLike): string {
    const calculateTemporalInformation = (timestamp: Date) => {
      const assembleString = (key: string, value: number) => {
        return `${this.translocoService.translate(key, { value: value })}`;
      };

      const time = (new Date().getTime() - timestamp.getTime()) / 60000;

      if (time > 10080) {
        return `${assembleString('device.status.time-updated.week', Math.round(time / 10000))}`;
      } else if (time > 1440) {
        return `${assembleString('device.status.time-updated.day', Math.round(time / 1440))}`;
      } else if (time > 60) {
        return `${assembleString('device.status.time-updated.hour', Math.round(time / 60))}`;
      } else if (time > 1) {
        return `${assembleString('device.status.time-updated.minute', Math.round(time))}`;
      } else {
        return `${this.translocoService.translate('device.status.time-updated.recently')}`;
      }
    };

    if (this.device?.status.connection.type === 'CONNECTED' && device.reportedPropertiesUpdatedAt) {
      return `${this.translocoService.translate(
        'device.data-update-status.last-updated-at'
      )} ${calculateTemporalInformation(device.reportedPropertiesUpdatedAt)}`;
    } else if (device.status.connection.lastUpdatedAt) {
      return `${this.translocoService.translate(
        'device.connection-status.last-connected-at'
      )} ${calculateTemporalInformation(device.status.connection.lastUpdatedAt)}`;
    } else {
      return this.translocoService.translate('device.status.time-unavailable');
    }
  }
}
