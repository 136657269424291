import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DevicePermissions, SharedDeviceApiService } from 'src/app/device/device-shared/api/shared-device-api.service';
import { mapIfDefined } from '../util/map-if-defined.operator';
import { DeviceSummary } from '../../device/device-shared/model/device.model';
import { CollectionItem } from '../../access-rights/permissions.utils';
import { DeviceModelApiService } from 'src/app/core/api/device-model-api.service';

const AKZ_REG_EX = /^(?:http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9-]+\.[a-z]{2,5}\/\?akz=(.*)?$/;
const SERIAL_REG_EX =
  /^(?:http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9-]+\.[a-z]{2,5}\/\?serialNo=(.*)?$/;

@Injectable({
  providedIn: 'root',
})
export class BarcodeParserService {
  constructor(private deviceModelService: DeviceModelApiService, private deviceService: SharedDeviceApiService) {}

  transformBarcodeUrlToTargetUrl(url: string): Observable<string> {
    let targetUrl: Observable<string | undefined> = of(url);
    const akz = this.parseAkz(url);
    const serialNumber = this.parseSerialNo(url);
    if (akz) {
      targetUrl = this.resolveAkzToDeviceModelUrl(akz);
    } else if (serialNumber) {
      targetUrl = this.resolveSerialNumberToDeviceDetailsUrl(serialNumber);
    }
    return targetUrl.pipe(map(aTargetUrl => aTargetUrl ?? url));
  }

  resolveAkzToDeviceModelUrl(akz: string): Observable<string | undefined> {
    return this.deviceModelService.getAll().pipe(
      map(deviceModels => deviceModels.find(deviceModel => deviceModel.akz === akz)),
      mapIfDefined(model =>
        ['/product-information', model.vendor, model.identifier, model.version ?? 'latest'].join('/')
      )
    );
  }

  resolveSerialNumberToDeviceDetailsUrl(serialNumber: string): Observable<string | undefined> {
    return this.deviceService.getDevices().pipe(
      map(result => {
        return result.collection.find(device => device.data?.serialNumber === serialNumber);
      }),
      mapIfDefined((d: CollectionItem<DeviceSummary, DevicePermissions> | undefined) =>
        [
          '/devices',
          d?.data.vendor,
          d?.data.type,
          d?.data.model.identification.id,
          d?.data.model.identification.version ?? 'latest',
          d?.data.id,
        ].join('/')
      )
    );
  }

  parseAkz(url: string): string | undefined {
    const akzMatch = AKZ_REG_EX.exec(url);
    if (akzMatch) {
      const akz = akzMatch[1];
      return akz;
    }
  }

  parseSerialNo(url: string): string | undefined {
    const serialMatch = SERIAL_REG_EX.exec(url);
    if (serialMatch) {
      const serialNo = serialMatch[1];
      return serialNo;
    }
  }
}
