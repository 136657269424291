import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedMediaQueryService {
  constructor() {}

  screenSizeSubject: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(undefined);
  /**
   * @deprecated Don't use this observable it will trigge an update for each windows size change
   */
  screenSize$ = this.screenSizeSubject.asObservable();

  /**
   * @deprecated The method should not be used , use the existing media breakpoint xs,sm,medium,lg or
   */
  checkScreenSize(query: string, orientation: string): Observable<void> {
    const mediaQuery = window.matchMedia(`(max-width:${query}px), (orientation: ${orientation})`);
    return fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
      startWith(mediaQuery),
      map((list: MediaQueryList) => this.screenSizeSubject.next(list.matches))
    );
  }
}
