<ng-container *transloco="let t">
  <div class="mt-5 card">
    <div ngbAccordion activeIds="0">
      <div ngbAccordionItem>
        <div ngbAccordionHeader>
          <div [class.collapsed]="!opened" class="header" [class.card-header-new]="opened">
            <ng-content select="[firstHalfSection]"></ng-content>
            @if (!opened) {
              <div class="outter-meta-wrapper">
                <ng-content select="[secondHalfSection]"></ng-content>
              </div>
            }
            <!-- Activate when the files api returns a description -->
            <!--  <button ngbAccordionToggle class="btn toggle-button">-->
            <!--    <i class="fa" [ngClass]="!opened ? 'fa-expand' : ' fa-compress'" aria-hidden="true"></i>-->
            <!--  </button>-->
          </div>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <ng-content select="[panelContent]"></ng-content>
            </ng-template>
          </div>
        </div>
      </div>
  </div>
</div>

<ng-template #defaultNoResultsTemplate>
  <div>{{ t('card-grid-no-items') }}</div>
</ng-template>
</ng-container>
