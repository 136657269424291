import { Injectable } from '@angular/core';
import { CoreLoginService } from '../core/service/core-login.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { handleError } from '../shared/util/http-utils';
import { HateoasLinkMap, preparePermissions } from './permissions.utils';

export interface IndexResponse {
  _links: HateoasLinkMap;
}

export interface MenuPermissions {
  device_firmwares: boolean;
  devices: boolean;
  service_groups: boolean;
  tenants: boolean;
  virtual_laboratories: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class BackendPermissionsService {
  index$ = new BehaviorSubject<MenuPermissions>({} as MenuPermissions);

  constructor(coreLoginService: CoreLoginService, private http: HttpClient) {
    coreLoginService.currentUser
      .pipe(
        map(user => {
          if (!user) {
            return;
          }

          return user;
        })
      )
      .subscribe();

    this.loadMainPermissions().subscribe(permissions => this.index$.next(permissions));
  }

  private loadMainPermissions(): Observable<MenuPermissions> {
    return this.http.get<IndexResponse>(`${environment.backendApi.shared.uri}/index`).pipe(
      map((response: IndexResponse) => {
        return preparePermissions<MenuPermissions>(response._links);
      }),
      catchError(handleError)
    );
  }
}
