import { Injectable, signal } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import {
  AvailableDeviceModelFirmwareUpdate,
  createDeviceModelFirmwareVersionReferences,
} from 'src/app/device-firmware/model/device-firmware-available-update.model';
import { DeviceFirmwareApiService } from 'src/app/device-firmware/api/device-firmware-api.service';
import { SharedDeviceService } from './shared-device.service';

@Injectable({ providedIn: 'root' })
export class DeviceAvailableFirmwareUpdatesService {
  #availableFirmwareUpdatesSubject = signal<AvailableDeviceModelFirmwareUpdate[]>([]);
  availableFirmwareUpdates = this.#availableFirmwareUpdatesSubject.asReadonly();

  constructor(private deviceService: SharedDeviceService, private deviceFirmwareApiService: DeviceFirmwareApiService) {}

  getAvailableFirmwareUpdatesForDevices(deviceId?: string): Observable<void> {
    let devices = this.deviceService.allDevices()?.collection.map(device => device.data);

    if (!devices) return of();

    if (deviceId) devices = devices.filter(device => device.id === deviceId);

    const firmwareVersionReferences = devices
      .map(device => createDeviceModelFirmwareVersionReferences(device))
      .reduce((result, current) => result.concat(current), []);

    return this.deviceFirmwareApiService
      .getAllAvailableUpdates(firmwareVersionReferences)
      .pipe(map(availableUpdates => this.#availableFirmwareUpdatesSubject.set(availableUpdates)));
  }

  emptyAvailableFirmwareUpdates(): void {
    this.#availableFirmwareUpdatesSubject.set([]);
  }
}
