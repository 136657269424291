import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Device } from 'src/app/device/device-shared/model/device.model';

@Component({
  selector: 'app-device-information',
  templateUrl: './information.component.html',
})
export class InformationComponent implements OnInit, OnChanges {
  @Input() private device!: Device;

  name!: string;
  serialNumber!: string;
  minimalTestingForce: string | undefined;
  maximalTestingForce: string | undefined;

  constructor() {}

  ngOnInit() {
    this.updateData(this.device);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.device.isFirstChange() && changes.device.currentValue) {
      this.updateData(changes.device.currentValue);
    }
  }

  private updateData(device: Device) {
    this.name = device.name;
    this.serialNumber = device.serialNumber;
    
    if (device.genericProperties?.additionalDeviceInformationParameters) {
      this.minimalTestingForce = device.genericProperties.additionalDeviceInformationParameters.minimalTestingForce;
      this.maximalTestingForce = device.genericProperties.additionalDeviceInformationParameters.maximalTestingForce;
    }
  }
}
