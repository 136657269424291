
@switch (vendor$ | async) {
  @case (ThemeVendor.VERDER) {
    <img
      src="assets/images/logo-verder.svg"
      alt="Verder Scientific GmbH & Co. KG"
      height="40px" />
  }
  @case (ThemeVendor.CARBOLITE) {
    <img
      src="assets/images/logo-carbolite.svg"
      alt="Carbolite Gero"
      height="40px" />
  }
  @case (ThemeVendor.ELTRA) {
    <img src="assets/images/logo-eltra.svg" alt="Eltra GmbH" height="40px" />
  }
  @case (ThemeVendor.RETSCH) {
    <img src="assets/images/logo-retsch.svg" alt="Retsch GmbH" height="40px" />
  }
  @case (ThemeVendor.MICROTRAC) {
    <img
      src="assets/images/logo-microtrac.svg"
      alt="Microtrac Retsch GmbH"
      height="40px" />
  }
  @case (ThemeVendor.QATM) {
    <img src="assets/images/logo-qatm.svg" alt="ATM Qness GmbH" height="40px" />
  }
  @default {
    <img src="assets/images/logo-iot.svg" alt="IoT App" height="40px" />
  }
}
