<div class="modal-header">
  <h5 class="modal-title">
    {{ 'reset-password' | transloco }}
  </h5>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row modal-content-row">
    <div class="col my-auto text-center">
      <p>
        {{ 'password-reset-success-msg-part1' | transloco }}<br />{{ 'password-reset-success-msg-part2' | transloco }}
      </p>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">
    {{ 'ok' | transloco }}
  </button>
</div>
