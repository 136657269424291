import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'com.verder.scientific.iot.client',
  appName: 'vsiot-webapp',
  webDir: 'dist/vsiot-webapp',
  bundledWebRuntime: false,
  plugins: {
    PushNotifications: {
      presentationOptions: ['badge', 'sound', 'alert'],
    },
  },
  ios: {
    contentInset: 'always',
  },
  // Uncomment below and fill you host ip for live reload
  // server: {
  //   url: 'https://<YOUR_IP_ADDRESS>:4200',
  //   cleartext: true
  // }
};

export default config;
