import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoreLoginService } from '../service/core-login.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectIfLoggedInGuard {
  constructor(private loginService: CoreLoginService, private msalService: MsalService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (!route.data.redirectToIfLoggedIn) {
      throw new Error(`$Route:${route.url} misconfigured. You need to provide data.redirectToIfLoggedIn!`);
    }

    return this.msalService.handleRedirectObservable().pipe(
      catchError(err => of(true)),
      map(() => {
        const val = this.loginService.isLoggedIn() ? this.router.parseUrl(route.data.redirectToIfLoggedIn) : true;
        return val;
      })
    );
  }
}
