import { Component, OnInit } from '@angular/core';
import {
  faHouseChimneyUser,
  faMicroscope,
  faCircleInfo,
  faUserGear,
  faServer,
  faLayerGroup,
  faPowerOff,
  faHome,
  faQrcode,
} from '@fortawesome/free-solid-svg-icons';
import { BarcodeScannerService } from 'src/app/core/service/bar-scanner.service';
import { CoreLoginService } from 'src/app/core/service/core-login.service';
import { Subscriptions } from 'src/app/shared/util/Subscriptions';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['../navigation.component.scss'],
})
export class MobileNavigationComponent implements OnInit {
  private subscriptions = new Subscriptions();

  user$ = this.loginService.currentUser;

  scanActive = false;
  canScan = false;

  qrCodeIcon = faQrcode;
  tenantIcon = faHouseChimneyUser;
  labsIcon = faMicroscope;
  infoHubIcon = faCircleInfo;
  userSettingsIcon = faUserGear;
  iotAdminIcon = faServer;
  deviceListIcon = faLayerGroup;
  logoutIcon = faPowerOff;
  homeIcon = faHome;

  constructor(private barcodeScannerService: BarcodeScannerService, private loginService: CoreLoginService) {}

  ngOnInit() {
    this.subscriptions.put(
      this.barcodeScannerService.isScanning$.subscribe((isScanning: boolean) => {
        this.scanActive = isScanning;
        const canScan = !isScanning && this.barcodeScannerService.supportsScanner();
        if (canScan) {
          this.barcodeScannerService.prepare();
        }
        this.canScan = canScan;
      })
    );
  }

  async startScanner() {
    await this.barcodeScannerService.startScanner();
  }

  async stopScanner(): Promise<void> {
    await this.barcodeScannerService.stopScanner();
  }

  login(): void {
    this.loginService.startLogin();
  }

  logout(): void {
    this.loginService.logout();
  }
}
