import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  Collection,
  CollectionItem,
  createResourceEmbeddedCollection,
  GetCollectionResponse,
  HateoasLinkMap,
  preparePermissions,
} from 'src/app/access-rights/permissions.utils';
import { BasicCollectionPermissions } from 'src/app/access-rights/permissions/permission-models';
import { DeviceVendor } from 'src/app/device/device-shared/model/device.model';
import { handleError } from 'src/app/shared/util/http-utils';
import { environment } from 'src/environments/environment';
import {
  DeviceModelFirmwareVersionReference,
  AvailableDeviceModelFirmwareUpdate,
} from '../model/device-firmware-available-update.model';
import { DeviceFirmwareCreateDto } from '../model/device-firmware.model';
import { DeviceFirmwarePermissions } from './device-firmware-permissions.models';

export enum DeleteDeviceFirmwareResult {
  Success,
  NotAuthorized,
  FirmwareDoesNotExist,
  UnprocessableRequest,
}

export enum CreateDeviceFirmwareResult {
  Success,
  NotAuthorized,
  FirmwareDoesNotExist,
  UnprocessableRequest,
  Conflict,
}

export interface DeviceFirmware {
  id: string;
  vendor: DeviceVendor;
  key: string;
  name: string;
  description?: string;
}

export interface DeviceFirmwareResponse extends DeviceFirmware {
  _links: HateoasLinkMap;
}

export type FirmwaresResponse = Collection<DeviceFirmware, BasicCollectionPermissions, DeviceFirmwarePermissions>;
export type FirmwareResponse = CollectionItem<DeviceFirmware, DeviceFirmwarePermissions>;

const DEVICE_FIRMWARE_ENDPOINT = `${environment.backendApi.shared.uri}/device-firmwares`;

@Injectable({
  providedIn: 'root',
})
export class DeviceFirmwareApiService {
  constructor(private http: HttpClient) {}

  private readonly oneDayInMs = 24 * 60 * 60 * 1000;

  getAllAvailableUpdates(
    deviceModelFirmwareVersionReferences: DeviceModelFirmwareVersionReference[]
  ): Observable<AvailableDeviceModelFirmwareUpdate[]> {
    return timer(0, this.oneDayInMs).pipe(
      switchMap(() =>
        this.http
          .post<{ _embedded: { collection: AvailableDeviceModelFirmwareUpdate[] } }>(
            `${DEVICE_FIRMWARE_ENDPOINT}/available-updates`,
            { firmwareVersions: deviceModelFirmwareVersionReferences }
          )
          .pipe(
            map(response => response._embedded.collection),
            catchError(handleError)
          )
      )
    );
  }

  getAll(): Observable<FirmwaresResponse> {
    return this.http.get<GetCollectionResponse<DeviceFirmware>>(DEVICE_FIRMWARE_ENDPOINT).pipe(
      map(response => {
        return {
          collection: createResourceEmbeddedCollection<DeviceFirmware, DeviceFirmwarePermissions>(response?._embedded),
          permissions: preparePermissions<BasicCollectionPermissions>(response._links),
        };
      }),
      catchError(handleError)
    );
  }

  oneFirmareId?: any;
  getOne$?: Observable<CollectionItem<DeviceFirmware, DeviceFirmwarePermissions>>;

  get(firmwareId: string): Observable<FirmwareResponse> {
    if (!this.getOne$ || this.oneFirmareId !== firmwareId) {
      this.oneFirmareId = firmwareId;
      this.getOne$ = this.http.get<DeviceFirmwareResponse>(`${DEVICE_FIRMWARE_ENDPOINT}/${firmwareId}`).pipe(
        map(response => {
          const { id, vendor, key, name, description } = response;
          return {
            data: { id, vendor, key, name, description } as DeviceFirmware,
            permissions: preparePermissions<DeviceFirmwarePermissions>(response?._links),
          };
        }),
        catchError(handleError)
      );
    }
    return this.getOne$;
  }

  create(firmware: DeviceFirmwareCreateDto): Observable<CreateDeviceFirmwareResult> {
    return this.http.post<{ id: string }>(DEVICE_FIRMWARE_ENDPOINT, firmware).pipe(
      map(_ => CreateDeviceFirmwareResult.Success),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          const httpErrorResponse: HttpErrorResponse = error;
          switch (httpErrorResponse.status) {
            case 400:
              return of(CreateDeviceFirmwareResult.UnprocessableRequest);
            case 403:
              return of(CreateDeviceFirmwareResult.NotAuthorized);
            case 404:
              return of(CreateDeviceFirmwareResult.FirmwareDoesNotExist);
            case 409:
              return of(CreateDeviceFirmwareResult.Conflict);
            case 422:
              return of(CreateDeviceFirmwareResult.UnprocessableRequest);
            default:
              throw error;
          }
        } else {
          throw error;
        }
      })
    );
  }

  update(firmware: DeviceFirmware): Observable<CreateDeviceFirmwareResult> {
    return this.http.put<void>(`${DEVICE_FIRMWARE_ENDPOINT}/${firmware.id}`, firmware).pipe(
      map(_ => CreateDeviceFirmwareResult.Success),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          const httpErrorResponse: HttpErrorResponse = error;
          switch (httpErrorResponse.status) {
            case 400:
              return of(CreateDeviceFirmwareResult.UnprocessableRequest);
            case 403:
              return of(CreateDeviceFirmwareResult.NotAuthorized);
            case 404:
              return of(CreateDeviceFirmwareResult.FirmwareDoesNotExist);
            case 409:
              return of(CreateDeviceFirmwareResult.Conflict);
            case 422:
              return of(CreateDeviceFirmwareResult.UnprocessableRequest);
            default:
              throw error;
          }
        } else {
          throw error;
        }
      })
    );
  }

  delete(firmware: DeviceFirmware): Observable<DeleteDeviceFirmwareResult> {
    return this.http.delete(`${DEVICE_FIRMWARE_ENDPOINT}/${firmware.id}`).pipe(
      map(_ => DeleteDeviceFirmwareResult.Success),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          const httpErrorResponse: HttpErrorResponse = error;
          switch (httpErrorResponse.status) {
            case 403:
              return of(DeleteDeviceFirmwareResult.NotAuthorized);
            case 404:
              return of(DeleteDeviceFirmwareResult.FirmwareDoesNotExist);
            case 422:
              return of(DeleteDeviceFirmwareResult.UnprocessableRequest);
            default:
              throw error;
          }
        } else {
          throw error;
        }
      })
    );
  }
}
