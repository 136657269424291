import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { CurrentUserApi } from 'src/app/core/api/current-user-api.service';
import { SessionKey } from 'src/app/shared/service/session-manager.service';
import { environment } from 'src/environments/environment';

export type UserPreference = {
  name: string;
  settings: [{ key: string; value: string }];
};

export type UserPreferences = {
  sections: UserPreference[];
};
const defaultUserPreferences = [SessionKey.defaultDashboard, SessionKey.defaultListSetting, SessionKey.pinnedDevice];
const BASE = environment.backendApi.shared.uri;

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  userId?: string;
  constructor(private http: HttpClient, private currentUserService: CurrentUserApi) {
    this.currentUserService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe(user => (this.userId = user.id));
  }

  saveUserPreferences = (userId = this.userId): Observable<UserPreferences> => {
    const userPreferences: UserPreferences = {
      sections: [],
    };

    defaultUserPreferences.forEach(key => {
      const value = localStorage.getItem(key);
      if (!value) return;
      const preference: UserPreference = { name: key, settings: [{ key, value }] };
      userPreferences.sections = [...userPreferences.sections, preference];
    });
    return this.http
      .post<UserPreferences>(`${BASE}/Users/${userId}/preferences`, userPreferences)
      .pipe(map(response => response));
  };

  getUserPreferences = (userId = this.userId): Observable<UserPreferences> => {
    return this.http.get<UserPreferences>(`${BASE}/Users/${userId}/preferences`).pipe(
      map(response => {
        response.sections.forEach(section => {
          localStorage.setItem(section.settings[0].key, section.settings[0].value);
        });
        return response;
      })
    );
  };
}
