import { Component, EventEmitter, Output } from '@angular/core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { BarcodeScannerService } from 'src/app/core/service/bar-scanner.service';

@Component({
  selector: 'app-qr-scanner-overlay',
  templateUrl: './qr-scanner-overlay.component.html',
  styleUrls: ['./qr-scanner-overlay.component.scss'],
})
export class QrScannerOverlayComponent {
  backButton = faXmark;

  constructor(private barcodeScanner: BarcodeScannerService) {}

  @Output() backPressed = new EventEmitter();

  goBack() {
    this.backPressed.emit();
    this.barcodeScanner.stopScanner();
  }
}
