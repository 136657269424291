import { TranslocoService } from '@ngneat/transloco';

export function countLowercase(value: string): number {
  return value.length - value.replace(/[a-z]/g, '').length;
}

export function countUppercase(value: string): number {
  return value.length - value.replace(/[A-Z]/g, '').length;
}

export function isUpperCase(value: string): boolean {
  return countUppercase(value) === value.length;
}

export function asTranslationKey(enumName: string) {
  const nrOfLowercase = countLowercase(enumName);
  const nrOfCapitals = countUppercase(enumName);
  const isPascalCase = nrOfLowercase > 0 && nrOfCapitals > 0;
  if (isPascalCase) {
    const cleanedUp = enumName.replace('_', '').trim();
    return Array.from(cleanedUp)
      .map((char, idx) => {
        if (isUpperCase(char)) {
          if (idx === 0) {
            return char.toLowerCase();
          }
          return `-${char.toLowerCase()}`;
        }
        return char;
      })
      .join('');
  } else {
    const cleanedUp = enumName.replace(/_/g, '-').trim();
    return cleanedUp.toLowerCase();
  }
}

export function translateEnumKey(translocoService: TranslocoService, enumName: string) {
  try {
    const key = asTranslationKey(enumName);
    return translocoService.translate(key);
  } catch (error) {
    console.log(`Couldn't translate key: ${enumName}`, error);
    return enumName;
  }
}
