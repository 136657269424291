import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { ProductInformationSharedModule } from 'src/app/product-information/product-information-shared.module';
import { SharedModule } from '../../shared/shared.module';
import { AsyncButtonComponent } from './component/async-button/async-button.component';
import { ConnectionStatusComponent } from './component/connection-status/connection-status.component';
import { DashboardFrameComponent } from './component/dashboard-frame/dashboard-frame.component';
import { PrepareDeviceRegistrationDialogComponent } from './component/device-registration-dialog/device-registration-dialog.component';
import { FirmwaresComponent } from './component/firmwares/firmwares.component';
import { InformationComponent } from './component/information/information.component';
import { NotificationBadgeComponent } from './component/notification-badge/notification-badge.component';
import { NotificationsListComponent } from './component/notification-list/notification-list.component';
import { NotificationComponent } from './component/notification/notification.component';
import { OperationalStatusProgressBarComponent } from './component/operational-status/operational-status-progress-bar.component';
import { OperationalStatusProgressInfoTextComponent } from './component/operational-status/operational-status-progress-info-text.component';
import { OperationalStatusComponent } from './component/operational-status/operational-status.component';
import { DeviceProductInformationSideNavigationItemComponent } from './component/product-information-side-navigation-item/device-product-information-side-navigation-item.component';
import { SummaryComponent } from './component/summary/summary.component';
import { DeviceProductInformationFilesPageComponent } from './page/product-information-files/device-product-information-files-page.component';
import { DeviceModelFormatterPipe } from './pipe/device-model-formatter.pipe';
import { SortNotificationsPipe } from './pipe/sort-notifications.pipe';
import { DeviceMoveDialogComponent } from './component/device-move-dialog/device-move-dialog.component';
import { DeviceListItemComponent } from '../device-list/component/device-list-item.component';
import { DeviceFilterPipe } from '../device-list/pipe/device-filter.pipe';
import { SortDevicesPipe } from '../device-list/pipe/sort-devices.pipe';
import { SummaryConnectionStatusComponent } from './component/summary/summary-connection-status.component';
import { DeviceCountersComponent } from './component/counters/device-counters.component';

const declarations = [
  NotificationsListComponent,
  NotificationComponent,
  SummaryComponent,
  SummaryConnectionStatusComponent,
  OperationalStatusComponent,
  InformationComponent,
  FirmwaresComponent,
  DashboardFrameComponent,
  NotificationBadgeComponent,
  ConnectionStatusComponent,
  OperationalStatusProgressInfoTextComponent,
  OperationalStatusProgressBarComponent,
  SortNotificationsPipe,
  AsyncButtonComponent,
  DeviceModelFormatterPipe,
  DeviceProductInformationFilesPageComponent,
  DeviceProductInformationSideNavigationItemComponent,
  PrepareDeviceRegistrationDialogComponent,
  DeviceMoveDialogComponent,
  DeviceListItemComponent,
  DeviceFilterPipe,
  SortDevicesPipe,
  DeviceCountersComponent,
];

@NgModule({
  declarations: declarations,
  exports: [...declarations, SharedModule],
  imports: [ CommonModule, SharedModule, ProductInformationSharedModule, TranslocoLocaleModule.forRoot() ],
  providers: [SortNotificationsPipe, SortDevicesPipe],
})
export class DeviceSharedModule {}
