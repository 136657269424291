import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleExclamation, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { AvailableActions, MessageType } from '../../model/mass-backup';
import { Option } from '../multiselect/multiselect.component';
import { translateEnumKey } from '../../util/translate-helper';
import { TranslocoService } from '@ngneat/transloco';
import { ITenantMassActionsResponse } from '../../../tenant/api/tenant-mass-actions.service';

@Component({
  selector: 'app-mass-actions-for-devices',
  templateUrl: './mass-actions-for-devices.component.html',
  styleUrls: ['./mass-actions-for-devices.component.scss'],
})
export class MassActionsForDevicesComponent implements OnInit {
  @Input() title = '';
  @Input() messageType = MessageType.warning;
  @Input() message = '';
  @Input() messageIcon = faCircleExclamation;
  @Input() caption = '';
  @Input() isLoading = false;
  @Input() actionsDisabled = false;
  @Input() actionResponse: ITenantMassActionsResponse | null = null;

  @Output() backupToCloud = new EventEmitter();
  @Output() updateDeviceSoftware = new EventEmitter();

  cloudIcon = faCloudArrowUp;
  isAlertVisible = true;
  typeOfMessage = MessageType;
  dropdownOptions!: Option[];
  chosenOption!: string;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.dropdownOptions = this.enumToOptionArray(AvailableActions);
  }

  triggerAction(): void {
    switch (this.chosenOption) {
      case AvailableActions.updateSoftware: {
        this.updateDeviceSoftware.emit();
        break;
      }
      case AvailableActions.massBackup: {
        this.backupToCloud.emit();
        break;
      }
    }
  }

  closeAlert() {
    this.isAlertVisible = false;
  }

  enumToOptionArray(enumObj: any): Option[] {
    return Object.values(enumObj).map((objValue: any) => ({
      value: objValue,
      label: translateEnumKey(this.translocoService, objValue.toString()),
    }));
  }
}
