<ng-container>
  @if (label) {
    @if (label.length > 0) {
      <label class="form-label" for="{{ inputId }}">{{ label }}</label>
    }
  }
  <input
    id="{{ inputId }}"
    name="{{ inputId }}"
    type="datetime-local"
    class="form-control w-100"
    [class.is-invalid]="!isValid"
    placeholder="{{ placeholder }}"
    autocomplete="none"
    [value]="value"
    (change)="writeValue($any($event.target).value)"
    (keyup)="onKeyup($event.target)"
    [disabled]="disabled" />

  @if (!isValid) {
    <app-input-info-message [show]="!isValid" [message]="messageInfo"></app-input-info-message>
  }
</ng-container>
