import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-categorized-page-title',
  templateUrl: './categorized-page-title.component.html',
})
export class CategorizedPageTitleComponent {
  @Input() category?: string;
  @Input() title?: string;
}
