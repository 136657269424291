import { Pipe, PipeTransform } from '@angular/core';
import { DeviceSummary } from 'src/app/device/device-shared/model/device.model';
import { DevicesFilter } from '../component/device-list.component';

@Pipe({
  name: 'devicesFilter',
  pure: true,
})
export class DeviceFilterPipe implements PipeTransform {
  transform(devices: DeviceSummary[] | undefined, devicesFilter: DevicesFilter): DeviceSummary[] | undefined {
    return devices?.filter(device => {
      return (
        this.matchesVirtualLaboratoryFilter(device, devicesFilter) &&
        this.matchesDeviceModelsFilter(device, devicesFilter)
      );
    });
  }

  private matchesVirtualLaboratoryFilter(device: DeviceSummary, devicesFilter: DevicesFilter): boolean {
    return (
      (devicesFilter.virtualLaboratories || []).length === 0 ||
      !!devicesFilter.virtualLaboratories!.find(
        virtualLaboratoryId =>
          device.virtualLaboratory.id.toLocaleLowerCase() === virtualLaboratoryId.toLocaleLowerCase()
      )
    );
  }

  private matchesDeviceModelsFilter(device: DeviceSummary, devicesFilter: DevicesFilter): boolean {
    return (
      (devicesFilter.deviceModels || []).length === 0 ||
      !!devicesFilter.deviceModels!.find(
        deviceModelId => device.model.baseModelIdentification?.id ?? device.model.identification.id === deviceModelId
      )
    );
  }
}
