import { Injectable, signal } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  IVirtualLaboratoryMassActionsResponse, MassUpdateSoftwareRequest,
  VirtualLaboratoryApiService,
  VirtualLaboratoryCollectionResult,
  VirtualLaboratoryResult
} from '../api/virtual-laboratory-api.service';

export interface VirtualLaboratoryDto {
  tenantId: string;
  name: string;
  description: string;
}

export interface UpdateVirtualLaboratoryDto {
  virtualLaboratoryId: string;
  name: string;
  description: string;
  tenantId: string;
}

@Injectable({ providedIn: 'root' })
export class VirtualLaboratoryService {
  virtualLaboratory = signal<VirtualLaboratoryResult | undefined>(undefined);
  virtualLaboratoryCollection = signal<VirtualLaboratoryCollectionResult | undefined>(undefined);
  virtualLaboratoryCollectionByTenant = signal<VirtualLaboratoryCollectionResult | undefined>(undefined);
  accessKey = signal<{ accessKey: string } | undefined>(undefined);

  constructor(private apiService: VirtualLaboratoryApiService) {}

  getAll(): Observable<void> {
    return this.apiService
      .getAll()
      .pipe(switchMap(labCollection => of(this.virtualLaboratoryCollection.set(labCollection))));
  }

  get(virtualLaboratoryId: string): Observable<void> {
    return this.apiService.get(virtualLaboratoryId).pipe(switchMap(lab => of(this.virtualLaboratory.set(lab))));
  }

  getAllByTenant(tenantId: string): Observable<void> {
    return this.apiService
      .findByTenantId(tenantId)
      .pipe(switchMap(labCollection => of(this.virtualLaboratoryCollectionByTenant.set(labCollection))));
  }

  getAccessKey(virtualLaboratoryId: string): Observable<void> {
    return this.apiService
      .fetchAccessKey(virtualLaboratoryId)
      .pipe(switchMap(accessKey => of(this.accessKey.set(accessKey))));
  }

  regenerateAccessKey(virtualLaboratoryId: string): Observable<void> {
    return this.apiService
      .regenerateAccessKey(virtualLaboratoryId)
      .pipe(switchMap(accessKey => of(this.accessKey.set(accessKey))));
  }

  create(virtualLibrary: VirtualLaboratoryDto): Observable<any> {
    return this.apiService.create(virtualLibrary.tenantId, virtualLibrary.name, virtualLibrary.description);
  }

  update(virtualLibrary: UpdateVirtualLaboratoryDto): Observable<void> {
    return this.apiService
      .update(virtualLibrary.virtualLaboratoryId, virtualLibrary.name, virtualLibrary.description)
      .pipe(
        switchMap(() => {
          return this.get(virtualLibrary.virtualLaboratoryId);
        })
      );
  }

  delete(virtualLaboratoryId: string): Observable<any> {
    return this.apiService.delete(virtualLaboratoryId);
  }

  massBackup(virtualLaboratoryId: string | undefined): Observable<IVirtualLaboratoryMassActionsResponse> {
    if(!virtualLaboratoryId) return of();
    return this.apiService.massBackup([virtualLaboratoryId]);
  }

  updateSoftware(massUpdateSoftwareRequest: MassUpdateSoftwareRequest): Observable<IVirtualLaboratoryMassActionsResponse> {
    if (massUpdateSoftwareRequest.VirtualLaboratoriesId?.length === 0) return of();
    return this.apiService.updateSoftware(massUpdateSoftwareRequest);
  }
}
