import { Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ListViewColumnDirective } from './list-view-column.directive';
import { Subscriptions } from '../../util/Subscriptions';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent<T> implements OnInit, OnDestroy {
  private subscriptions: Subscriptions = new Subscriptions();

  @Input() value: T[] = [];

  @Input() emptyTextKey!: string;
  @Input() clickable = false;

  @Input() requestInProgress = false;

  @Output() rowClicked = new EventEmitter<T>();

  emptyText = 'No entries';

  @ContentChildren(ListViewColumnDirective)
  columns?: QueryList<ListViewColumnDirective>;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    if (!this.emptyTextKey) {
      this.subscriptions.put(
        this.translocoService.langChanges$.subscribe(() => {
          this.emptyText = this.translocoService.translate('no-entries');
        })
      );
    } else {
      this.emptyText = this.emptyTextKey;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  onScrolled() {
    console.log('Scrolled');
  }
}
