import config from 'capacitor.config';

export const environment = {
  production: true,
  mockBackendApi: false,
  capacitorLiveReload: config.server,
  appVersion: require('../../package.json').version,
  backendApi: {
    shared: {
      uri: 'https://iot-test.verder-scientific.com/shared/api',
      key: 'FhdIQjHjkixKVpFKJKkmCmPgGrantpkrJOgpOal9a6sSWdzBw1QN6w==',
      id: 'WebApp',
    },
    carbolite: {
      uri: 'https://iot-test.verder-scientific.com/carbolite/api',
      key: 'P5sB3yOiVloiOYUGE4opihd9eT6nJTTEcb3VJ5rnQaH7g312htzqKA==',
      id: 'WebApp',
    },
    eltra: {
      uri: 'https://iot-test.verder-scientific.com/eltra/api',
      key: 'qsLTPSq0QiGGIImkaPoZIYj5kiqTPhFL4/BooT8xBYh0nIPRIZtO8A==',
      id: 'WebApp',
    },
    microtrac: {
      uri: 'https://iot-test.verder-scientific.com/microtrac/api',
      key: 'gKw4SSLFWUDQPa7c0zJI32IsviRPurNolpu3NPa4I7MFhdp2UNXCuA==',
      id: 'WebApp',
    },
    qatm: {
      uri: 'https://iot-test.verder-scientific.com/qatm/api',
      key: '8Ga7dGXRSYINmUpmP6xvm/yRN8ZeObEe0TwqzSu/fD9cVjPuuGHHBg==',
      id: 'WebApp',
    },
    retsch: {
      uri: 'https://iot-test.verder-scientific.com/retsch/api',
      key: 'R7LgAFc2afIRF5wjE73eJKJHiTc3RkU5Pw7RuPm2Ctlw1jrek0Vkqg==',
      id: 'WebApp',
    },
    retschMockDevice: {
      uri: 'https://retsch.tarekmues.com/device',
      id: 'mockdevice',
    },
  },
  b2cAuth: {
    clientId: '3f2fd08e-2254-4bdb-a2c5-509605eff688',
    signInName: 'B2C_1_si',
    signUpName: 'B2C_1_sign_up_staging',
    signInAuthority: 'https://verderiotb2cdev.b2clogin.com/verderiotb2cdev.onmicrosoft.com/b2c_1_si',
    signUpAuthority: 'https://verderiotb2cdev.b2clogin.com/verderiotb2cdev.onmicrosoft.com/B2C_1_sign_up_staging',
    resetPasswordAuthority: 'https://verderiotb2cdev.b2clogin.com/verderiotb2cdev.onmicrosoft.com/B2C_1_reset',
    redirectUri: 'https://iot-test.verder-scientific.com',
    knownAuthorities: ['verderiotb2cdev.b2clogin.com'],
  },
  appInsights: {
    enabled: true,
    instrumentationKey:
      'InstrumentationKey=5ed822a7-d19d-4a59-8c6d-8fbdc8c92370;IngestionEndpoint=https://westeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
  },
};
