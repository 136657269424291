<ng-container *transloco="let t">
  @if (user$ | async; as user) {
    <div class="navigation-list">
      <a class="sidebar-logo" [routerLink]="['/devices']">
        <app-vendor-logo></app-vendor-logo>
      </a>
      <a (click)="startScanner()" class="navigation-list-item d-flex">
        <fa-icon [icon]="qrCodeIcon" class="list-icon"></fa-icon>
        <div>
          {{ t('qr-code-scanner-button') }}
        </div>
      </a>
      @if (user && !user.deleted) {
        <div>
          <div [hidden]="!!sideOutlet2?.isActivated">
            <router-outlet #sideOutlet="outlet" name="second-level-navigation"></router-outlet>
          </div>
          <router-outlet #sideOutlet2="outlet" name="third-level-navigation"></router-outlet>
        </div>
      }
      <div class="sidebar-divider"></div>
      <!-- <div class="navigation-list-header" *ngIf="user">
      {{ user.email }}
    </div> -->
    <!-- * Enable when available -->
    <!-- <a
    [routerLink]="['/404']"
    routerLinkActive="active"
    [class.backg-light]="isOpen"
    [class.active-circle]="!isOpen"
    class="navigation-list-item d-flex">
    <fa-icon [icon]="infoHubIcon" class="global-menu-item-icon pr-2"></fa-icon>
    <div>
      {{ t('information-hub') }}
    </div>
  </a> -->
  <a [routerLink]="['/user-settings']" routerLinkActive="active" class="navigation-list-item d-flex">
    <fa-icon [icon]="userSettingsIcon" class="list-icon"></fa-icon>
    <div>
      {{ t('user-settings') }}
    </div>
  </a>
  @if (!user) {
    <div class="navigation-list-item d-flex" (click)="login()">
      <fa-icon [icon]="logoutIcon" class="list-icon"></fa-icon>
      <div>{{ t('sign-in') }}</div>
    </div>
  }
  @if (user) {
    <div class="navigation-list-item d-flex" (click)="logout()">
      <fa-icon [icon]="logoutIcon" class="list-icon"></fa-icon>
      <div>{{ t('logout') }}</div>
    </div>
  }
</div>
}
</ng-container>
