import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { partition } from 'src/app/shared/util/arrays';
import { v4 as uuidv4 } from 'uuid';

export enum NotificationType {
  Information,
  Warning,
  Error,
}

export interface Notification {
  id: string;
  type: NotificationType;
  title: string;
  text: string;
  expiresAt?: Date;
  onAcknowledgeCallback: () => any;
}

export interface PushedNotification {
  key?: string;
  type: NotificationType;
  title: string;
  text: string;
  expiresAt?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private static DEFAULT_EXPIRY_TIMEOUT_MS = 5000;

  public static createDefaultExpiry() {
    return new Date(new Date().getTime() + NotificationService.DEFAULT_EXPIRY_TIMEOUT_MS);
  }
  private notificationSubject = new BehaviorSubject<Notification[]>([]);

  public get notifications$(): Observable<Notification[]> {
    return this.notificationSubject.asObservable();
  }

  public pushNotification(
    pushedNotification: PushedNotification,
    onAcknowledgeCallback: () => any = () => {
      return;
    }
  ): void {
    const id = pushedNotification.key || uuidv4();

    this.notificationSubject.value
      .filter(
        notification => notification.id === id || NotificationService.isEquivalent(notification, pushedNotification)
      )
      .forEach(notification => this.acknowledgeNotification(notification.id));

    const notification = Object.assign(pushedNotification, { id: id, onAcknowledgeCallback: onAcknowledgeCallback });

    this.notificationSubject.next([...this.notificationSubject.value, notification]);

    if (pushedNotification.expiresAt) {
      setTimeout(
        () => this.acknowledgeNotification(id),
        Math.max(0, pushedNotification.expiresAt.getTime() - new Date().getTime())
      );
    }
  }

  public acknowledgeNotification(id: string): void {
    const { matching, remainder } = partition(this.notificationSubject.value, notification => notification.id === id);
    const relevantNotifications = matching;
    const remainingNotifications = remainder;

    if (relevantNotifications.length === 0) {
      return;
    }

    this.notificationSubject.next(remainingNotifications);

    relevantNotifications.forEach(notification => notification.onAcknowledgeCallback());
  }

  private static isEquivalent = (notification: Notification, pushedNotification: PushedNotification) =>
    notification.type === pushedNotification.type &&
    notification.title === pushedNotification.title &&
    notification.text === pushedNotification.text;
}
