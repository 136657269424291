import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Observable, of, switchMap } from 'rxjs';
import { concatMap, filter, first, map } from 'rxjs/operators';
import { DefaultDashboard, DefaultDashboardType } from 'src/app/user-settings/model/user-settings.model';
import { VirtualLaboratoryUserAssignmentRole } from 'src/app/virtual-laboratory/model/user-assignment.model';
import { SessionKey } from 'src/app/shared/service/session-manager.service';
import { CoreLoginService } from '../service/core-login.service';
import { UserPreferencesService } from '../../user-settings/service/user-preferences.service';

@Injectable({
  providedIn: 'root',
})
export class InitialRedirectGuard {
  constructor(
    private loginService: CoreLoginService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private userPreferencesService: UserPreferencesService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter(status => status === InteractionStatus.None),
      first(),
      concatMap(() => {
        return this.loginService.currentUser.pipe(
          first(),
          switchMap(user => {
            if (user) {
              return this.userPreferencesService.getUserPreferences(user?.id).pipe(map(() => user))
            }
            return of(user);
          }),
          map(user => {
            if (user) {
              const startingDashboard = this.getStartingDashboard();

              if (startingDashboard) {
                if (!user.admin && user.tenantAssignments.length === 0 && user.virtualLaboratoryAssignments.length === 0) {
                  localStorage.removeItem('default-dashboard');
                  return this.router.parseUrl('/unauthorized');
                }
                return this.router.parseUrl(startingDashboard);
              } else if (user.admin) {
                return this.router.parseUrl('/administration');
              } else if (user?.tenantAssignments.length > 0) {
                if (user?.tenantAssignments.length === 1) {
                  return this.router.parseUrl(`/tenants/${user?.tenantAssignments[0].tenantId}`);
                } else {
                  return this.router.parseUrl('/tenants');
                }
              } else if (user?.virtualLaboratoryAssignments.length > 0) {
                if (user?.virtualLaboratoryAssignments.length === 1) {
                  const assignment = user?.virtualLaboratoryAssignments[0];

                  if (assignment.role === VirtualLaboratoryUserAssignmentRole.LabManager) {
                    return this.router.parseUrl(
                      `/virtual-laboratories/${user?.virtualLaboratoryAssignments[0].virtualLaboratoryId}`
                    );
                  } else {
                    return this.router.parseUrl('/devices');
                  }
                } else {
                  return this.router.parseUrl('/virtual-laboratories');
                }
              } else {
                return this.router.parseUrl('/unauthorized');
              }
            }

            return this.router.parseUrl('/home');
          })
        );
      })
    );
  }

  private getStartingDashboard(): string | undefined {
    if ((localStorage.getItem(SessionKey.defaultDashboard) && this.router.url === '/') || '/home') {
      const { type, objectId: id }: DefaultDashboard = JSON.parse(
        localStorage.getItem(SessionKey.defaultDashboard) || '{}'
      );
      return this.getStartingDashboardUrl(type, id);
    }

    return undefined;
  }

  private getStartingDashboardUrl(type?: DefaultDashboardType, id?: string): string | undefined {
    switch (type) {
      case DefaultDashboardType.Tenant:
        return `/tenants/${id}`;
      case DefaultDashboardType.VirtualLaboratory:
        return `/virtual-laboratories/${id}`;
      default:
        return undefined;
    }
  }
}
