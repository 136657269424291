import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class FunctionsKeyHeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newRequest = request;

    if (request.url.startsWith(environment.backendApi.shared.uri)) {
      newRequest = request.clone({
        headers: request.headers.set('x-functions-key', environment.backendApi.shared.key),
      });
    } else if (request.url.startsWith(environment.backendApi.qatm.uri)) {
      newRequest = request.clone({
        headers: request.headers.set('x-functions-key', environment.backendApi.qatm.key),
      });
    } else if (request.url.startsWith(environment.backendApi.retschMockDevice.uri)) {
      newRequest = request.clone({
        headers: request.headers.set('x-functions-key', environment.backendApi.shared.key),
      });
    }

    return next.handle(newRequest);
  }
}
