import { Observable, throwError } from 'rxjs';
import { ParamMap } from '@angular/router';

const STATUS_MSG_MAP = new Map<number, string>();
STATUS_MSG_MAP.set(404, 'Not Found');
STATUS_MSG_MAP.set(403, 'Access Denied');
STATUS_MSG_MAP.set(500, 'Internal Server Error');

export function handleError(error: { error: { message: any }; status: string | number }): Observable<never> {
  let errorMsg: string;
  if (error.error instanceof ErrorEvent) {
    errorMsg = `Error: ${error.error.message}`;
  } else {
    const errVal: number = (<string>error.status)?.length
      ? (parseInt(<string>error.status) as number)
      : (error.status as number);
    errorMsg = `${STATUS_MSG_MAP.get(errVal) ?? 'Unknown Server Error'}: ${error.error?.message ?? error}`;
  }
  return throwError(errorMsg);
}

export function isValidHttpUrl(str: string | undefined): boolean {
  if (!str) {
    return false;
  }
  const trimmed = str.trim();
  if (trimmed.indexOf(' ') > -1) {
    return false;
  }
  try {
    const url = new URL(str);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
}

export function toMap(paramMap: ParamMap): Map<string, any> {
  const map = new Map<string, any>();
  paramMap.keys.forEach(key => map.set(key, paramMap.get(key)));
  return map;
}
