import { Pipe, PipeTransform } from '@angular/core';
import {
  SortByDeviceId,
  SortByDeviceOperationalStatus,
  SortByNotification,
  SortByStatus,
} from 'src/app/device/device-list/pipe/sort-devices-comparators.helper';
import { AbstractSortingPipe } from 'src/app/shared/pipe/helper/AbstractSortingPipe';
import { DeviceSummary } from '../../device-shared/model/device.model';

@Pipe({
  name: 'sortDevicesPipe',
  pure: true,
})
export class SortDevicesPipe extends AbstractSortingPipe<DeviceSummary> implements PipeTransform {
  constructor() {
    super([
      new SortByStatus(),
      new SortByNotification('critical'),
      new SortByNotification('error'),
      new SortByNotification('warning'),
      new SortByDeviceOperationalStatus(),
      new SortByDeviceId(),
    ]);
  }
}
