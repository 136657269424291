import { Component, Input, ContentChildren, QueryList, forwardRef, AfterContentInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioComponent } from './radio/radio.component';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
  ],
})
export class RadioGroupComponent implements ControlValueAccessor, AfterContentInit {
  @Input() name: string = '';
  
  @ContentChildren(forwardRef(() => RadioComponent)) radios!: QueryList<RadioComponent>;
  
  private _value: any;
  private onChange: (selected: any[]) => void = () => {};
  private onTouched = () => {};
  
  ngAfterContentInit(): void {
    this.radios.forEach(radio => {
      radio.name = this.name;
      radio.registerChangeCallback((value: any) => {
        this.onRadioChange(value);
      });
    });
  }
  
  get value(): any {
    return this._value;
  }
  
  writeValue(value: any): void {
    this._value = value;
    
    if (this.radios) {
      this.radios.forEach(radio => radio.checked = radio.value === this._value);
    }
  }
  
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  
  setDisabledState(isDisabled: boolean): void {
    if (this.radios) {
      this.radios.forEach(radio => radio.disabled = isDisabled);
    }
  }
  
  onRadioChange(value: any): void {
    this.writeValue(value);
    this.onChange(value);
  }
}
