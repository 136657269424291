<label *ngIf="label" class="form-label" for="{{ id }}">{{ label }}</label>

<div ngbDropdown [placement]="placement">
  @if (!disabled) {
    <div class="multiselect-label-container" ngbDropdownToggle tabindex="0">
      @if (selected.length === 0) {
        <div class="multiselect-label">
          <span class="mr-1">{{ placeholder }} ({{ _options.length }})</span>
        </div>
      }
      @if (selected.length === 1) {
        <div class="multiselect-label">
          @for (selectedOption of selected; track selectedOption) {
            <span class="badge badge-primary mr-1">{{ selectedOption.label }}</span>
          }
        </div>
      }
      @if (selected.length > 1) {
        <div class="multiselect-label">
          <span class="mr-1">{{ 'common.selected-items' | transloco }} ({{ selected.length }})</span>
        </div>
      }
    </div>
  } @else {
    <div class="multiselect-label-container text-muted">
      @if (selected.length === 0) {
        <div class="multiselect-label">
          {{ placeholder }}
        </div>
      }
      @if (selected.length === 1) {
        <div class="multiselect-label">
          @for (selectedOption of selected; track selectedOption) {
            <span class="badge badge-primary mr-1">{{ selectedOption.label }}</span>
          }
        </div>
      }
      @if (selected.length > 1) {
        <div class="multiselect-label">
          <span class="mr-1">{{ 'common.selected-items' | transloco }} ({{ selected.length }})</span>
        </div>
      }
    </div>
  }
  <div ngbDropdownMenu class="multiselect-dropdown">
    <div class="select-all custom-control custom-checkbox">
      <input
        #selectAll
        type="checkbox"
        class="custom-control-input"
        id="{{ name }}-selectAllCheck"
        [ngModel]="allSelected"
        (ngModelChange)="toggleSelectAll($event)" />
      <label class="custom-control-label" for="{{ name }}-selectAllCheck">{{ 'select-all' | transloco }}</label>
    </div>
    <div class="multiselect-options vertically-scrollable-content">
      <ng-container
        *ngTemplateOutlet="optionsTemplate; context: { $implicit: 'default', options: uncategorizedOptions }">
      </ng-container>
      @for (category of optionCategories(); track category) {
        <div class="option-group">
          <div class="font-weight-bold">{{ category }}</div>
          <ng-container
            *ngTemplateOutlet="optionsTemplate; context: { $implicit: category, options: categorizedOptions(category) }">
          </ng-container>
        </div>
      }
    </div>
  </div>
</div>

<ng-template #optionsTemplate let-category let-options="options">
  @for (option of options; track option; let i = $index) {
    <div class="custom-control custom-checkbox">
      <input
        type="checkbox"
        class="custom-control-input"
        id="{{ name }}-{{ category }}-option-{{ i }}"
        [ngModel]="selected.includes(option)"
        (ngModelChange)="selectOption(option)" />
      <label class="custom-control-label" for="{{ name }}-{{ category }}-option-{{ i }}">{{ option.label }}</label>
    </div>
  }
</ng-template>
