import { Injectable } from '@angular/core';

import { CoreLoginService } from '../service/core-login.service';

@Injectable({
  providedIn: 'root',
})
export class HandleAuthGuard {
  constructor(private loginService: CoreLoginService) {}

  canActivate(): boolean {
    this.loginService.handleAppStart();
    return true;
  }
}
