export enum DefaultDashboardType {
  Empty = 'EMPTY',
  Tenant = 'TENANT',
  VirtualLaboratory = 'VIRTUAL_LABORATORY',
}

export enum ViewListTarget {
  Tenant = 'TENANT',
  VirtualLaboratory = 'VIRTUAL_LABORATORY',
  Both = 'BOTH',
}

export enum ListViewSettings {
  ViewAll = 'VIEW_ALL',
  SelectedItems = 'SELECTED_ITEMS',
  ViewFavorites = 'VIEW_FAVORITES',
}

export interface DefaultDashboard {
  type: DefaultDashboardType;
  objectId: string;
}
