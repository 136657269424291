import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NgbModalDismissAllGuard {
  constructor(private modalService: NgbModal) {}

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
    return true;
  }
}
