import {
  DeviceFirmware,
  DeviceJournalItemLevel,
  DeviceJournalItemPriority,
  DeviceJournalItemType,
  DeviceModel,
  DeviceNotificationLevel,
  DeviceNotificationPriority,
  DeviceNotificationSummary,
  DeviceRemoteControlRequestResponseStatus,
  DeviceStatistics,
  DeviceStatus,
  DeviceVendor,
  DeviceVirtualLaboratory,
} from './device.model';

export interface ApiDevicePlatformSettingsFeatureSettingsBackups {
  accessible: boolean;
}

export interface ApiDevicePlatformSettingsFeatureSettingsLogs {
  accessible: boolean;
}

export interface ApiDevicePlatformSettingsFeatureSettingsJournal {
  accessible: boolean;
}

export interface ApiDevicePlatformSettingsFeatureSettings {
  backups: ApiDevicePlatformSettingsFeatureSettingsBackups;
  logs: ApiDevicePlatformSettingsFeatureSettingsLogs;
  journal: ApiDevicePlatformSettingsFeatureSettingsJournal;
  generic: string[];
}

export interface ApiDevicePlatformSettings {
  features: ApiDevicePlatformSettingsFeatureSettings;
}

export interface ApiDeviceRemoteControlRequest {
  id: string;
  requestType: string;
  requestPayload: string;
  responseStatus: DeviceRemoteControlRequestResponseStatus;
  responsePayload: string;
  respondedAt: Date;
  requestedAt: Date;
}

export interface ApiDeviceJournalItem {
  id: string;
  type: DeviceJournalItemType;
  level: DeviceJournalItemLevel;
  priority: DeviceJournalItemPriority;
  code: number;
  summary: string;
  text: string;
  reportedAt: Date;
}

export interface ApiDeviceNotification {
  id: string;
  level: DeviceNotificationLevel;
  priority: DeviceNotificationPriority;
  summary: string;
  text: string;
  reportedAt: Date;
}

export interface ApiDeviceSummary {
  id: string;
  virtualLaboratory: DeviceVirtualLaboratory;
  name: string;
  description?: string;
  vendor: DeviceVendor;
  type: string;
  model: DeviceModel;
  status: DeviceStatus;
  serialNumber: string;
  firmwares: DeviceFirmware[];
  statistics: DeviceStatistics;
  notificationSummary: DeviceNotificationSummary;
  genericProperties?: any;
}

export interface ApiDevice {
  id: string;
  userGroup?: DeviceVirtualLaboratory;
  virtualLaboratory?: DeviceVirtualLaboratory;
  name: string;
  description?: string;
  vendor: DeviceVendor;
  type: string;
  model: DeviceModel;
  status: DeviceStatus;
  serialNumber: string;
  firmwares: DeviceFirmware[];
  statistics: DeviceStatistics;
  platformSettings: ApiDevicePlatformSettings;
  genericProperties?: any;
  reportedPropertiesUpdatedAt: Date;
}
