import { Pipe, PipeTransform } from '@angular/core';
import { DeviceNotification } from 'src/app/device/device-shared/model/device.model';
import { AbstractSortingPipe } from '../../../shared/pipe/helper/AbstractSortingPipe';
import { SortByDate, SortByLevel, SortByPriority } from './sort-notifications.helper';

@Pipe({
  name: 'sortNotificationsPipe',
})
export class SortNotificationsPipe extends AbstractSortingPipe<DeviceNotification> implements PipeTransform {
  constructor() {
    super([new SortByLevel(), new SortByPriority(), new SortByDate()]);
  }
}
