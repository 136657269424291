<div class="dropdown-reset">
  <div ngbDropdown class="dropdown">
    <button
      ngbDropdownToggle
      class="btn {{ buttonType }} grid-card-action-menu dropdown-toggle"
      type="button"
      aria-expanded="false">
      <span class="text-muted">&vellip;</span>
    </button>
    <ul ngbDropdownMenu class="dropdown-action-menu">
      @for (action of actions; track action) {
        @if (action.link && action.link.length > 0) {
          <li>
            <a ngbDropdownItem href="{{ action.link }}">{{ action.title }}</a>
          </li>
        } @else {
          <li>
            <a ngbDropdownItem [class.disabled]="action.isEnabled && !action.isEnabled()" (click)="action?.handler()">
              {{ action.title }}
            </a>
          </li>
        }
        <ng-template #button>
          <li>
            <a ngbDropdownItem [class.disabled]="action.isEnabled && !action.isEnabled()" (click)="action?.handler()">
              {{ action.title }}
            </a>
          </li>
        </ng-template>
      }
    </ul>
  </div>
</div>
