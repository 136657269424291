import { Component, Input } from '@angular/core';
import { ProgressInformation } from './progress-information.calculator';

export interface ViewItem {
  icon: string;
  text: string;
}

@Component({
  selector: 'app-operational-status-progress-info-text',
  templateUrl: './operational-status-progress-info-text.component.html',
  styleUrls: ['./operational-status-progress-info-text.component.scss'],
})
export class OperationalStatusProgressInfoTextComponent {
  @Input() progressInformation?: ProgressInformation;

  get hasEstimatedSecondsRemaining(): boolean {
    return typeof this.progressInformation?.estimatedSecondsRemaining === 'number';
  }
}
