import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-close-dialog',
  templateUrl: './modal-close-button.html',
})
export class ModalCloseButtonComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
