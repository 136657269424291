import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';
import { ProductInformationService } from 'src/app/product-information/service/product-information.service';
import { ButtonType, LongRunningTask } from 'src/app/shared/component/button/button.component';
import { Action } from 'src/app/shared/component/card-header/card-header';
import { Subscriptions } from 'src/app/shared/util/Subscriptions';
import { ProductInformationFile, ProductInformationFileType } from '../../model/product-information-file.model';
import { DateSorting, FilterCriteria } from '../../model/product-information-filter-criteria.model';
import { faComputer, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-information-files-listing',
  templateUrl: './product-information-files-listing.component.html',
  styleUrls: ['./product-information-files-listing.component.scss'],
})
export class ProductInformationFilesListingComponent implements OnDestroy, OnChanges {
  @Input() filterCriteria?: FilterCriteria;
  @Input() files?: ProductInformationFile[];
  @Input() fileTypes?: ProductInformationFileType[];

  filteredFiles: ProductInformationFile[] = [];
  subscriptions: Subscriptions = new Subscriptions();

  downloadIcon = faFileArrowDown;
  softwareIcon = faComputer;

  locale$?: Observable<string>;
  productInformationFileType = ProductInformationFileType;

  constructor(private productFilesService: ProductInformationService, private translocoService: TranslocoService) {
    this.locale$ = translocoService.langChanges$;
  }

  ngOnChanges(): void {
    this.filteredFiles = this.files || [];
    if (this.filteredFiles && this.filterCriteria?.dateSorting) {
      this.sortFilesByDate(this.filterCriteria.dateSorting);
    }
  }

  createActions(fileId: string) {
    return [
      new Action({
        title: this.translocoService.translate('download'),
        handler: async (): Promise<void> => {
          return new Promise(resolve => {
            this.downloadFile(fileId);
            resolve();
          });
        },
        isEnabled: () => true,
        type: ButtonType.VENDOR,
      }),
    ];
  }

  sortFilesByDate(sorting: DateSorting): void {
    if (!this.filteredFiles) return;

    if (sorting === DateSorting.Latest) {
      this.filteredFiles.sort(
        (a: ProductInformationFile, b: ProductInformationFile) =>
          new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime()
      );
    } else {
      this.filteredFiles.sort(
        (a: ProductInformationFile, b: ProductInformationFile) =>
          new Date(a.modifiedAt).getTime() - new Date(b.modifiedAt).getTime()
      );
    }
  }

  filesByFileType(files: ProductInformationFile[], type: ProductInformationFileType): ProductInformationFile[] {
    return files.filter((file: ProductInformationFile) => this.fileIsOfType(file, type));
  }

  private fileIsOfType(file: ProductInformationFile, type: ProductInformationFileType) {
    return file.type === type;
  }

  downloadFile(fileId: string) {
    this.productFilesService
      .getUri(fileId)
      .pipe(take(1))
      .subscribe(uri => window.open(uri, '_blank'));
  }

  noopHandler: LongRunningTask = async () => {};

  asContentLengthString(file: ProductInformationFile) {
    return `${file.contentLength}`;
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll;
  }
}
