import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements OnInit {
  @Input() name!: string;
  @Input() color!: string;
  @Input() label!: string;

  location = '';
  className = '';

  constructor() {}

  ngOnInit(): void {
    this.location = `assets/sprite.svg#${this.name}`;
    this.className = `svg-${this.name}`;
  }
}
