import { Component, Input, OnInit } from '@angular/core';
import { Notification, NotificationService, NotificationType } from '../../service/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './app-notification.component.html',
  styleUrls: ['./app-notification.component.scss'],
})
export class AppNotificationComponent implements OnInit {
  @Input() notification?: Notification;

  toastIconCssClass: string = 'fa-envelope';

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    switch (this.notification?.type) {
      case NotificationType.Warning:
        this.toastIconCssClass = 'fa-exclamation-triangle';
        break;
      case NotificationType.Error:
        this.toastIconCssClass = 'fa-exclamation-circle';
        break;
      default:
        this.toastIconCssClass = 'fa-envelope';
        break;
    }
  }

  acknowledgeNotification(notificationId: string): void {
    this.notificationService.acknowledgeNotification(notificationId);
  }
}
