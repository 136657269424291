import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../model/core-api-response';
import { CurrentUser } from '../model/current-user.model';

interface ApiCurrentUserTenantAssignment {
  tenantId: string;
  role: string;
}

interface ApiCurrentUserVirtualLaboratoryAssignment {
  virtualLaboratoryId: string;
  role: string;
}

interface ApiCurrentUser {
  id: string;
  email: string;
  admin: boolean;
  tenantAssignments: ApiCurrentUserTenantAssignment[];
  virtualLaboratoryAssignments: ApiCurrentUserVirtualLaboratoryAssignment[];
}

const USER_URL: string = environment.backendApi.shared.uri + '/users/current';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserApi {
  constructor(private http: HttpClient) {}

  getCurrentUser(): Observable<CurrentUser> {
    return this.http.get<ApiResponse<ApiCurrentUser>>(USER_URL).pipe(map(value => value.data as CurrentUser));
  }
}
