<a class="sidebar-logo">
  <app-vendor-logo></app-vendor-logo>
</a>

<div class="navbar-left-controls">
  <div class="navbar-left-background"></div>
</div>

<ng-container *transloco="let t">
  <div class="nav-wrapper d-flex" [class.bg-color]="isOpen" [class.local-sidebar-shadow]="!isOpen">
    @if (permissions$ | async; as menuPermissions) {
      <div
        class="global-scoped-nav pb-5"
        [id]="isOpen ? 'global-scoped-nav-open' : ''"
        [class.global-sidebar-shadow]="isOpen"
        >
        <div class="upper-global-sidenav">
          @if (!(user$ | async)?.deleted) {
            @if (menuPermissions.tenants) {
              <a
                [routerLink]="['/']"
                [class.backg-light]="isOpen"
                [class.active-circle]="!isOpen"
                class="global-menu-item d-flex align-items-center">
                <fa-icon [icon]="homeIcon" class="global-menu-item-icon"></fa-icon>
                <p class="global-menu-item-label" [class.d-none]="!isOpen">
                  {{ t('home.nav-title') }}
                </p>
              </a>
            }
            @if (menuPermissions.tenants) {
              <a
                [routerLink]="['/tenants']"
                routerLinkActive="active"
                [class.backg-light]="isOpen"
                [class.active-circle]="!isOpen"
                class="global-menu-item d-flex align-items-center">
                <fa-icon [icon]="tenantIcon" class="global-menu-item-icon"></fa-icon>
                <p class="global-menu-item-label" [class.d-none]="!isOpen">
                  {{ t('tenant.overview.nav-title') }}
                </p>
              </a>
            }
            @if (menuPermissions.virtual_laboratories) {
              <a
                [routerLink]="['/virtual-laboratories']"
                routerLinkActive="active"
                [class.backg-light]="isOpen"
                [class.active-circle]="!isOpen"
                class="d-flex global-menu-item align-items-center">
                <fa-icon [icon]="labsIcon" class="global-menu-item-icon"></fa-icon>
                <p class="global-menu-item-label" [class.d-none]="!isOpen">
                  {{ t('virtual-laboratory.overview.nav-title') }}
                </p>
              </a>
            }
            @if (menuPermissions.devices) {
              <a
                [routerLink]="['/devices']"
                routerLinkActive="active"
                [class.backg-light]="isOpen"
                [class.active-circle]="!isOpen"
                class="d-flex global-menu-item align-items-center">
                <fa-icon [icon]="deviceListIcon" class="global-menu-item-icon"></fa-icon>
                <p class="global-menu-item-label" [class.d-none]="!isOpen">
                  {{ t('device.overview.nav-title') }}
                </p>
              </a>
            }
            @if (menuPermissions.device_firmwares) {
              <a
                [routerLink]="['/administration']"
                routerLinkActive="active"
                [class.backg-light]="isOpen"
                [class.active-circle]="!isOpen"
                class="d-flex global-menu-item align-items-center">
                <fa-icon [icon]="iotAdminIcon" class="global-menu-item-icon"></fa-icon>
                <p class="global-menu-item-label" [class.d-none]="!isOpen">
                  {{ t('iot-administration') }}
                </p>
              </a>
            }
            @if (supportsScanner()) {
              <a (click)="startScanner()" class="d-flex global-menu-item align-items-center">
                <fa-icon [icon]="qrCodeIcon" class="global-menu-item-icon"></fa-icon>
                <p class="global-menu-item-label" [class.d-none]="!isOpen">
                  {{ t('qr-code-scanner-button') }}
                </p>
              </a>
            }
          }
        </div>
        <div class="bottom-global-sidenav">
          <a
            [routerLink]="['/user-settings']"
            routerLinkActive="active"
            [class.backg-light]="isOpen"
            [class.active-circle]="!isOpen"
            class="d-flex global-menu-item align-items-center account-settings"
            data-cy="account-settings">
            <fa-icon [icon]="userSettingsIcon" class="global-menu-item-icon"></fa-icon>
            <p class="global-menu-item-label" [class.d-none]="!isOpen">
              {{ t('user-settings') }}
            </p>
          </a>
          @if (user$ | async) {
            <div
              role="button"
              class="d-flex global-menu-item align-items-center"
              data-cy="logout"
              (click)="logout()">
              <fa-icon [icon]="logoutIcon" class="global-menu-item-icon"></fa-icon>
              <p class="global-menu-item-label" [class.d-none]="!isOpen">
                {{ t('logout') }}
              </p>
            </div>
          }
          <div class="global-menu-item open-toggle">
            @if (isOpen && hasSideNavigation) {
              <fa-icon
                [icon]="anglesLeft"
                class="global-menu-item-icon"
              (click)="onOpenSidebar()"></fa-icon>
            }
            @if (!isOpen && hasSideNavigation) {
              <fa-icon
                [icon]="anglesRight"
                class="global-menu-item-icon"
              (click)="onOpenSidebar()"></fa-icon>
            }
            @if (!hasSideNavigation) {
              <fa-icon
                [icon]="anglesLeft"
                class="global-menu-item-icon"
              [class.opacity_30]="!hasSideNavigation"></fa-icon>
            }
          </div>
        </div>
      </div>
    }
    @if (!isIframe && !(user$ | async)?.deleted) {
      <div class="local-context-nav">
        <div [hidden]="!!sideOutlet2?.isActivated">
          <router-outlet #sideOutlet="outlet" name="second-level-navigation"></router-outlet>
        </div>
        <router-outlet #sideOutlet2="outlet" name="third-level-navigation"></router-outlet>
      </div>
    }
  </div>
</ng-container>
