<div class="card" *transloco="let t">
  <div class="card-header">
    <i class="icon icon-notification"></i>
    <h5 class="card-title" data-cy="cardTitle">
      {{ t('notifications') }}
    </h5>
  </div>

  <div class="card-body">
    <div class="vertically-scrollable-content">
      @if (notifications$ | async; as notifications) {
        <div>
          @if (notifications.length > 0) {
            <div>
              @for (notification of notifications | sortNotificationsPipe; track notification) {
                <app-device-notification
                  [notification]="notification">
                </app-device-notification>
              }
            </div>
          }
          @if (notifications.length === 0) {
            <div>
              <span class="text-muted">{{ t('none') }}</span>
            </div>
          }
        </div>
      } @else {
        <app-loading-indicator></app-loading-indicator>
      }

    </div>
  </div>
</div>
