import { DateTime } from 'luxon';
import { DeviceNotification, DeviceNotificationLevel, DeviceNotificationPriority } from '../model/device.model';
import { Comparator, Compared } from '../../../shared/pipe/helper/Comparator';

const LEVELS: { [key in DeviceNotificationLevel]: number } = {
  INFO: 4,
  WARNING: 3,
  ERROR: 2,
  CRITICAL: 1,
};
export class SortByLevel implements Comparator<DeviceNotification> {
  constructor() {}

  compare(itemA: DeviceNotification, itemB: DeviceNotification): Compared {
    const valA = itemA?.level || undefined;
    const valB = itemB?.level || undefined;
    return Math.sign(LEVELS[valA] - LEVELS[valB]) as Compared;
  }
}

const PRIOS: { [key in DeviceNotificationPriority]: number } = {
  VERY_LOW: 5,
  LOW: 4,
  NORMAL: 3,
  HIGH: 2,
  VERY_HIGH: 1,
};
export class SortByPriority implements Comparator<DeviceNotification> {
  constructor() {}

  compare(itemA: DeviceNotification, itemB: DeviceNotification): Compared {
    const valA = itemA.priority || undefined;
    const valB = itemB.priority || undefined;
    return Math.sign(PRIOS[valA] - PRIOS[valB]) as Compared;
  }
}

export class SortByDate implements Comparator<DeviceNotification> {
  constructor() {}

  compare(itemA: DeviceNotification, itemB: DeviceNotification): Compared {
    const valA = itemA.reportedAt ? DateTime.fromJSDate(itemA.reportedAt).valueOf() : 0;
    const valB = itemB.reportedAt ? DateTime.fromJSDate(itemB.reportedAt).valueOf() : 0;
    return Math.sign(valB - valA) as Compared;
  }
}
