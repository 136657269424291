import {
  TRANSLOCO_LOADER,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { TranslocoPreloadLangsModule } from '@ngneat/transloco-preload-langs';
import { TRANSLOCO_PERSIST_LANG_STORAGE, TranslocoPersistLangModule } from '@ngneat/transloco-persist-lang';
import { GetLangParams } from '@ngneat/transloco-persist-lang/lib/persist-lang.config';
import { LocaleFormatOptions, TranslocoLocaleModule } from '@ngneat/transloco-locale';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  getTranslation(lang: string) {
    return import(`../assets/i18n/${lang}.json`).then(res => res.default);
  }
}

export function getLangFn({ cachedLang, browserLang, cultureLang, defaultLang }: GetLangParams): string {
  if (cachedLang) return cachedLang;
  if (cultureLang) return cultureLang;
  if (browserLang) return browserLang;
  return defaultLang;
}

const globalFormatConfig: LocaleFormatOptions = {
  decimal: {
    useGrouping: true,
    minimumIntegerDigits: 1,
    minimumSignificantDigits: 1,
    maximumFractionDigits: 3,
  },
};

@NgModule({
  imports: [
    TranslocoPreloadLangsModule.forRoot(['en', 'de']),
    TranslocoPersistLangModule.forRoot({
      getLangFn,
      storage: {
        provide: TRANSLOCO_PERSIST_LANG_STORAGE,
        useValue: localStorage,
      },
    }),
    TranslocoLocaleModule.forRoot({
      localeConfig: {
        global: globalFormatConfig,
      },
    }),
  ],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'de'],
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          logMissingKey: true,
          useFallbackTranslation: true,
        },
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
