import { Injectable, OnDestroy } from '@angular/core';
import { StateBehaviour } from '../../shared/model/state-behaviour';

@Injectable({
  providedIn: 'root',
})
export class AppStateService implements OnDestroy {
  isSideBarOpen: StateBehaviour<boolean> = new StateBehaviour<boolean>(false);

  constructor() {}

  unsubscribe(): void {
    this.isSideBarOpen.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
