import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-checkbox-display',
  template: '<app-svg-icon [name]="checkboxIconName"></app-svg-icon>',
})
export class CheckboxDisplayComponent {
  @Input() value: boolean = false;

  get checkboxIconName(): string {
    return this.value ? 'icon-checkbox-checked' : 'icon-checkbox-unchecked';
  }
}
