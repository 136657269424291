import { Component, HostListener, OnDestroy } from '@angular/core';
import { CheckVisibilityService } from '../../service/check-visibility.service';

@Component({
  selector: 'app-check-visibility',
  template: '<div></div>',
})
export class CheckVisibilityComponent implements OnDestroy {
  constructor(private visibilityService: CheckVisibilityService) {}

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    this.visibilityService.visibility = !document.hidden;
  }

  ngOnDestroy(): void {
    this.visibilityService.stop();
  }
}
