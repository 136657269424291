<ng-container *transloco="let t">
  <div class="card h-100">
    <div class="card-header">
      <i class="icon icon-gears"></i>
      <h2 class="card-title">
        {{ t('device-information') }}
      </h2>
    </div>
    <div class="tuple-list">
      <div class="tuple-list-item">
        <div class="tuple-list-key">
          {{ t('name') }}
        </div>
        <div class="tuple-list-value">{{ name }}</div>
      </div>

      <div class="tuple-list-item">
        <div class="tuple-list-key">
          {{ t('serial-number') }}
        </div>
        <div class="tuple-list-value">{{ serialNumber }}</div>
      </div>

      @if (minimalTestingForce && maximalTestingForce) {
        <div class="tuple-list-item">
          <div class="tuple-list-key">
            {{ t('force-range') }}
          </div>
          <div class="tuple-list-value">{{ minimalTestingForce }} gf - {{ maximalTestingForce }} gf</div>
        </div>
      }
    </div>
  </div>
</ng-container>
