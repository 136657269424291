import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DeviceOperationalStatusType } from 'src/app/device/device-shared/model/device.model';
import { ProgressInformation } from '../operational-status/progress-information.calculator';

@Component({
  selector: 'app-device-operational-status-progress-bar',
  templateUrl: './operational-status-progress-bar.component.html',
  styleUrls: ['./operational-status-progress-bar.component.scss'],
})
export class OperationalStatusProgressBarComponent implements OnInit, OnChanges {
  @Input() progressInformation?: ProgressInformation;
  @Input() height: string = '10px';

  progressPercentage?: number;

  showRegular: boolean = false;
  showInfinite: boolean = false;

  get visible(): boolean {
    return this.showRegular || this.showInfinite;
  }

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(): void {
    this.update();
  }

  update(): void {
    if (this.progressInformation?.status === DeviceOperationalStatusType.Active) {
      this.progressPercentage =
        typeof this.progressInformation?.progressPercentage === 'number'
          ? this.progressInformation?.progressPercentage / 100
          : undefined;

      this.showRegular = !!this.progressInformation && typeof this.progressPercentage === 'number';
      this.showInfinite = !!this.progressInformation && !this.showRegular;
    } else {
      this.reset();
    }
  }

  reset(): void {
    this.showRegular = false;
    this.showInfinite = false;
  }
}
