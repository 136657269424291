import { allKeys } from '../shared/util/objects';

export enum HttpMethod {
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  DELETE = 'delete',
  OPTION = 'option',
}

export interface HateoasLink {
  href: string;
  method?: HttpMethod;
}

export interface WithPermissions<P> {
  permissions: P;
}

export type HateoasLinkMap = Record<string, HateoasLink>;

export interface HasLink {
  _links: HateoasLinkMap;
}

export interface EmbeddedCollection<T> {
  collection: T[];
}

export interface GetCollectionResponse<T> {
  _embedded: EmbeddedCollection<T>;
  _links: HateoasLinkMap;
}

export interface CollectionItem<T, CIP> {
  data: T;
  permissions: CIP;
}

export interface Collection<T, CP, CIP> {
  collection: CollectionItem<T, CIP>[];
  permissions: CP;
}

export function emptyPermissions<T>(): T {
  return {} as T;
}

export function preparePermissions<P>(links?: HateoasLinkMap): P {
  if (!links) {
    return {} as P;
  }
  const allFields = allKeys(links).map(field => {
    if (!field) {
      console.error('Invalid permission-name: ', field, 'Permission-name is empty.');
      return;
    }
    return field.toLowerCase().trim();
  });
  if (allFields.length < 1) {
    return {} as P;
  }
  const permissions: Record<keyof P, boolean> = {} as Record<keyof P, boolean>;

  allFields.forEach(item => {
    if (item) {
      const key = convertParamName(item);
      permissions[key as keyof P] = true;
    }
  });
  return permissions as unknown as P;
}

export function createResourceCollection<T, CIP>(
  collection: any[],
  dataMapperFn?: (input: any) => T
): CollectionItem<T, CIP>[] {
  return collection.map(item => {
    const withLink = item as unknown as HasLink;
    if (!withLink?._links) {
      return {
        data: item,
        permissions: {} as CIP,
      };
    }
    return {
      data: dataMapperFn ? dataMapperFn(item) : item,
      permissions: preparePermissions<CIP>(withLink._links),
    } as CollectionItem<T, CIP>;
  });
}

export function createResourceEmbeddedCollection<T, CIP>(
  collection: EmbeddedCollection<any>,
  dataMapperFn?: (input: any) => T
): CollectionItem<T, CIP>[] {
  if (!collection) {
    return [];
  }
  return createResourceCollection<T, CIP>(collection.collection, dataMapperFn);
}

/**
 * use snake_case
 * @param relationName
 */
export function convertParamName(relationName: string): string {
  const converted = relationName.replace(/[\-\.\[\]\/]/g, '_').trim();
  if (converted.endsWith('_')) {
    return converted.substring(0, converted.length - 1);
  }
  return converted;
}
