<footer class="container-fluid verder-footer px-2 px-lg-5">
  <hr class="mb-5" />
  <div class="row align-items-center footer-bar">
    <app-svg-icon class="col-auto col-md-3" name="icon-iot-logo"></app-svg-icon>

    <a
      class="col-auto col-md-3 order-md-last ml-auto ml-md-0 d-flex justify-content-end"
      href="https://www.verder-scientific.com">
      <app-svg-icon name="icon-part-vs"></app-svg-icon>
    </a>

    <div class="col-12 col-md mt-5 mt-md-0">
      <div class="row align-items-center justify-content-center footer-links">
        <div class="col-auto px-2">
          <a [routerLink]="['/about']">{{ 'about' | transloco }}</a>
        </div>
        <div class="col-auto px-2">
          <a href="https://www.verder-scientific.com/imprint/">{{ 'imprint' | transloco }}</a>
        </div>
        <div class="col-auto px-2">
          <a href="https://www.verder-scientific.com/privacy-policy/">{{ 'privacy-policy' | transloco }}</a>
        </div>
        <div class="col-auto px-2">
          <a href="https://www.verder-scientific.com/terms-conditions/">{{ 'terms-&-conditions' | transloco }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-5">
    <div class="col-auto">
      <p>© {{ currentYear }} Verder Scientific GmbH & Co. KG</p>
    </div>
  </div>
</footer>
