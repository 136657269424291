<ng-container *transloco="let t">
  <div ngbDropdown placement="bottom-right">
    @if (!disabled) {
      <div class="radio-label-container" ngbDropdownToggle tabindex="0">
        @if (selectedOption) {
          <div class="radio-label">
            <span class="badge badge-primary mr-1">{{ selectedOption.label }}</span>
          </div>
        } @else {
          <div class="radio-label">
            <span class="mr-1">{{ t(placeholderKey) }} ({{ options.length }})</span>
          </div>
        }
      </div>
    } @else {
      <div class="radio-label-container text-muted">
        @if (selectedOption) {
          <div class="radio-label">
            <span class="badge badge-primary mr-1">{{ selectedOption.label }}</span>
          </div>
        } @else {
          <div class="radio-label">
            {{ t(placeholderKey) }}
          </div>
        }
      </div>
    }
    <div ngbDropdownMenu class="radio-dropdown">
      <app-radio-group class="w-100 h-100 d-flex flex-column" [name]="name + '-group'" (change)="onSelectedChange($event)" [(ngModel)]="selected">
        @for (option of options; track option.label) {
          <app-radio class="radio-dropdown-option" [value]="option.value" [label]="option.label"></app-radio>
        }
      </app-radio-group>
    </div>
  </div>
</ng-container>
