import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingStateService } from '../../service/routing-state.service';

@Component({
  selector: 'app-route-loading-indicator',
  templateUrl: './route-loading-indicator.component.html',
  styleUrls: ['./route-loading-indicator.component.scss'],
})
export class RouteLoadingIndicatorComponent {
  loading$: Observable<boolean>;

  constructor(routingState: RoutingStateService) {
    this.loading$ = routingState.loading$;
  }
}
