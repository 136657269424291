import { Injectable, signal } from '@angular/core';
import { DeviceResult, DeviceSummaryCollectionResult, SharedDeviceApiService } from '../api/shared-device-api.service';
import { SharedDeviceStateService } from './shared-device-state.service';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDeviceService {
  constructor(private apiService: SharedDeviceApiService, private stateService: SharedDeviceStateService) {}

  device = signal<DeviceResult | undefined>(undefined);
  allDevices = signal<DeviceSummaryCollectionResult | undefined>(undefined);

  getAllDevices(virtualLaboratoryId?: string): Observable<void> {
    return this.apiService.getDevices(virtualLaboratoryId).pipe(switchMap(devices => of(this.allDevices.set(devices))));
  }

  get(deviceId: string): Observable<void> {
    return this.apiService.getDeviceById(deviceId).pipe(
      switchMap(device => {
        return of(this.device.set(device));
      })
    );
  }

  startDeviceByIdPoller() {
    return this.apiService
      .startDeviceByIdPoller(this.device()?.data.id || '')
      .pipe(switchMap(device => of(this.device.set(device))));
  }

  startDevicesPolling(virtualLaboratoryId?: string) {
    return this.apiService
      .startDevicesPolling(virtualLaboratoryId)
      .pipe(switchMap(devices => of(this.allDevices.set(devices))));
  }
}
