import { Component } from '@angular/core';

export interface MetaItem {
  size: string;
  filetype: string;
  language: string;
}

@Component({
  selector: 'app-accordion-card',
  templateUrl: './accordion-card.component.html',
  styleUrls: ['./accordion-card.component.scss'],
})
export class AccordionCardComponent {
  
  opened = false;
  
  constructor() {}
}
