import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ApiUserPreviewReference } from 'src/app/virtual-laboratory/model/user-assignment.model';

export interface User {
  id: string;
  displayName: string;
}

export interface AssignedUserWithRole {
  role: string;
  user: ApiUserPreviewReference;
}

export interface AssignedUsersForRole {
  role: string;
  users: ApiUserPreviewReference[];
}

@Component({
  selector: 'app-assigned-user-with-role-summary',
  templateUrl: './assigned-user-with-role-summary.component.html',
  styleUrls: ['./assigned-user-with-role-summary.component.scss'],
})
export class AssignedUserWithRoleSummaryComponent implements OnInit {
  @Input() roleLabelTemplate?: TemplateRef<any>;
  @Input() assignedUsersWithRole?: AssignedUserWithRole[];

  assignedUsersForRole?: AssignedUsersForRole[];

  roles?: string[];
  userAssignmentCount?: number;

  ngOnInit(): void {
    if (this.assignedUsersWithRole) {
      this.assignedUsersForRole = this.assignedUsersWithRole.reduce((acc, curr) => {
        const role = curr.role;

        if (!acc) {
          return [{ role: role, users: [curr.user] }];
        } else {
          const existing = acc.find(assignment => assignment.role == role);

          if (!existing) {
            acc.push({ role: role, users: [curr.user] });
          } else {
            existing.users.push(curr.user);
          }
        }

        return acc;
      }, [] as AssignedUsersForRole[]);

      this.userAssignmentCount = this.assignedUsersWithRole.reduce(acc => {
        acc++;
        return acc;
      }, 0);

      this.roles = this.assignedUsersForRole.map(assignment => assignment.role);
    } else {
      this.roles = undefined;
      this.userAssignmentCount = undefined;
    }
  }

  getUsersWithRole(role: string) {
    return this.assignedUsersForRole
      ?.find(assignment => assignment.role == role)
      ?.users.sort((a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()));
  }
}
