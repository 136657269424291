import { Pipe, PipeTransform } from '@angular/core';
import { RebrandDeviceModelPipe } from 'src/app/shared/pipe/rebrand-device-model.pipe';
import { DeviceModel } from '../model/device.model';
import { resolvePrimaryDeviceModelId } from '../utils/device-model.utils';

@Pipe({
  name: 'deviceModelFormatter',
})
export class DeviceModelFormatterPipe implements PipeTransform {
  transform(model: DeviceModel, includeVersion: boolean = true): string {
    if (!model) {
      return '';
    }
    const deviceName = new RebrandDeviceModelPipe().transform(resolvePrimaryDeviceModelId(model));
    if (includeVersion) {
      const deviceVersion = model.identification.version ? ` (${model.identification.version})` : '';
      return deviceName + deviceVersion;
    } else {
      return deviceName;
    }
  }
}
