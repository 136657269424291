import { KeyValuePair } from '../component/key-value-list/key-value-list.component';

interface Partition<T> {
  matching: T[];
  remainder: T[];
}

export function pushIfNotUndefined(values: KeyValuePair[], newVal: KeyValuePair | undefined): void {
  if (!newVal) {
    return;
  }
  values.push(newVal);
}

export function partition<T>(arr: T[], criteria: (val: T) => boolean): Partition<T> {
  return arr.reduce(
    (acc, value) => {
      criteria(value) ? acc.matching.push(value) : acc.remainder.push(value);
      return acc;
    },
    { matching: [], remainder: [] } as Partition<T>
  );
}
