import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Action } from '../card-header/card-header';

export interface CardGridItem {
  image?: string;
  actions: Action[];
  clickable?: boolean;
}

@Component({
  selector: 'app-card-grid',
  templateUrl: './card-grid.component.html',
  styleUrls: ['./card-grid.component.scss'],
})
export class CardGridComponent<T extends CardGridItem> {
  @Input() items: T[] = [];

  @Input() clickable = false;

  @Input() contentTemplate: TemplateRef<any> | null = null;
  @Input() noResultsTemplate?: TemplateRef<any> | undefined;

  @Output() cardClicked = new EventEmitter<T>();

  ignoreClick(event: Event) {
    event.stopPropagation();
  }
}
