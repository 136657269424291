import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoRootModule } from '../transloco-root.module';
import { CardGridComponent } from './component/card-grid/card-grid.component';
import { CardHeaderComponent } from './component/card-header/card-header';
import { CheckVisibilityComponent } from './component/check-visability/check-visibility.component';
import { CheckboxDisplayComponent } from './component/checkbox-display/checkbox-display.component';
import { ComboboxWithGroupComponent } from './component/combobox-with-group/combobox-with-group.component';
import { ConfirmDialogComponent } from './component/confirm-dialog/confirm-dialog.component';
import { DialogComponent } from './component/dialog/dialog.component';
import { DropdownButtonComponent } from './component/dropdown-button/dropdown-button.component';
import { InputWithBadgesComponent } from './component/input-with-badges/input-with-badges.component';
import { KeyValueListComponent } from './component/key-value-list/key-value-list.component';
import { ListViewColumnDirective } from './component/list-view/list-view-column.directive';
import { ListViewComponent } from './component/list-view/list-view.component';
import { LoadingIndicatorComponent } from './component/loading-indicator/loading-indicator';
import { ModalCloseButtonComponent } from './component/modal-close-button/modal-close-button.component';
import { MultiselectComponent } from './component/multiselect/multiselect.component';
import { NavigationItemComponent } from './component/navigation-item/navigation-item.component';
import { SimpleModalComponent } from './component/simple-modal/simple-modal.component';
import { SvgIconComponent } from './component/svg-icon/svg-icon.component';
import { UnitaryValueComponent } from './component/unitary-value/unitary-value.component';
import { ByteFormatterPipe } from './pipe/bytes.pipe';
import { DeviceImagePipe } from './pipe/device-image.pipe';
import { RebrandDeviceModelPipe } from './pipe/rebrand-device-model.pipe';
import { SecondsFormatterPipe } from './pipe/seconds-formatter.pipe';
import { SortByPipe } from './pipe/sort-by.pipe';
import { FormatUnitaryUnitPipe } from './pipe/format-unitary-unit.pipe';
import { Argb2RgbPipe } from './pipe/argb2rgb.pipe';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { InputFiledComponent } from './component/input-field/input-field.component';
import { SelectFiledComponent } from './component/select-field/select-field.component';
import { ButtonComponent } from './component/button/button.component';
import { InputInfoMessageComponent } from './component/input-info-message/input-info-message.component';
import { InputTextAreaComponent } from './component/textarea-field/input-textarea.component';
import { DateInputFieldComponent } from './component/date-input-field/date-input-field.component';
import { DeviceModelImage } from './pipe/device-model-image.pipe';
import { NotificationDialogComponent } from './component/notification-dialog/notification-dialog.component';
import { ShouldShowErrorMessagePipe } from './pipe/should-show-error.pipe';
import { CategorizedCardGridComponent } from './component/categorized-card-grid/categorized-card-grid.component';
import { AssignedUserWithRoleSummaryComponent } from './component/assigned-user-with-role-summary/assigned-user-with-role-summary.component';
import { CategorizedPageTitleComponent } from './component/categorized-page-title/categorized-page-title.component';
import { LoadingIndicatorModalComponent } from './component/loading-indicator-modal/loading-indicator-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MassActionsForDevicesComponent } from './component/mass-actions-for-devices/mass-actions-for-devices.component';
import { StackedBarComponent } from './component/stacked-bar/stacked-bar.component';
import { RadioComponent } from './component/radio-group/radio/radio.component';
import { RadioGroupComponent } from './component/radio-group/radio-group.component';
import { PaginationNewComponent } from './component/pagination-new/pagination-new.component';
import { InputNewComponent } from './component/input-new/input-new.component';

const exportedDeclarations: any[] = [
  ByteFormatterPipe,
  SecondsFormatterPipe,
  KeyValueListComponent,
  SvgIconComponent,
  CheckboxDisplayComponent,
  MultiselectComponent,
  SortByPipe,
  ComboboxWithGroupComponent,
  NavigationItemComponent,
  ListViewColumnDirective,
  ListViewComponent,
  InputWithBadgesComponent,
  RebrandDeviceModelPipe,
  SimpleModalComponent,
  DeviceImagePipe,
  DeviceModelImage,
  FormatUnitaryUnitPipe,
  UnitaryValueComponent,
  CheckVisibilityComponent,
  LoadingIndicatorComponent,
  ConfirmDialogComponent,
  NotificationDialogComponent,
  ModalCloseButtonComponent,
  Argb2RgbPipe,
  CardHeaderComponent,
  DropdownButtonComponent,
  InputFiledComponent,
  SelectFiledComponent,
  ButtonComponent,
  DialogComponent,
  CardGridComponent,
  InputInfoMessageComponent,
  InputTextAreaComponent,
  DateInputFieldComponent,
  ShouldShowErrorMessagePipe,
  CategorizedCardGridComponent,
  AssignedUserWithRoleSummaryComponent,
  CategorizedPageTitleComponent,
  LoadingIndicatorModalComponent,
  MassActionsForDevicesComponent,
  RadioComponent,
  RadioGroupComponent,
  StackedBarComponent,
  InputNewComponent,
  PaginationNewComponent,
];

@NgModule({
  exports: [
    [
      TranslocoRootModule,
      TranslocoLocaleModule,
      NgbModule,
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      PlatformModule,
      FontAwesomeModule
    ].concat(exportedDeclarations),
    ListViewComponent,
    CardHeaderComponent,
    DialogComponent,
    InputFiledComponent,
    MultiselectComponent,
    SelectFiledComponent,
    ButtonComponent,
    DropdownButtonComponent,
    CardGridComponent,
    InputTextAreaComponent,
    SecondsFormatterPipe,
    MassActionsForDevicesComponent,
    StackedBarComponent,
    RadioComponent,
    RadioGroupComponent,
    LoadingIndicatorComponent,
    InputInfoMessageComponent,
    InputNewComponent,
    SortByPipe,
    PaginationNewComponent
  ],
  declarations: exportedDeclarations,
  imports: [
    TranslocoRootModule,
    TranslocoLocaleModule,
    NgbModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PlatformModule,
    FontAwesomeModule,
  ],
})
export class SharedModule {}
