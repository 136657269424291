@if (files$ | async; as files) {
  @if (files.length > 0) {
    <div class="card-table mb-2">
      <div class="row table-header">
        <div class="col-7">{{ 'name' | transloco }}</div>
        <div class="col-2">{{ 'size' | transloco }}</div>
        <div class="col-3">{{ 'created-at' | transloco }}</div>
      </div>
      @if (openInNewWindow) {
        @for (file of files; track file) {
          <a
            class="row hover"
            style="cursor: pointer"
            [routerLink]="['/download']"
            [queryParams]="downloadParams(file.id)"
            target="_blank"
            rel="noopener noreferrer">
            <ng-container *ngTemplateOutlet="rowContent; context: { $implicit: file }"></ng-container>
          </a>
        }
      } @else {
        @for (file of files; track file) {
          <div
            class="row hover"
            style="cursor: pointer"
            (click)="downloadFile(file.id, file.name)">
            <ng-container *ngTemplateOutlet="rowContent; context: { $implicit: file }"></ng-container>
          </div>
        }
      }
      <ng-template #rowContent let-file>
        <div class="cell col-7 mb-1 mb-sm-0">
          <span class="col-label d-none">{{ 'name' | transloco }}</span
            ><span class="text-break">{{ file.name }}</span>
          </div>
          <div class="cell col-2 d-none d-sm-flex">
            <span class="col-label">{{ 'size' | transloco }}</span
              >{{ file.contentLength | bytes }}
            </div>
            <div class="cell col-3 d-none d-sm-flex">
              <span class="col-label">{{ 'created-at' | transloco }}</span
                >{{ file.createdAt | translocoDate : { dateStyle: 'short', timeStyle: 'short' } : (locale$ | async) }}
              </div>
              <div class="d-sm-none secondary-line">
                <i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;{{
                file.createdAt | translocoDate : { dateStyle: 'short', timeStyle: 'short' } : (locale$ | async)
                }}
                &nbsp;&nbsp;<i class="fa fa-file-o" aria-hidden="true"></i>&nbsp;{{ file.contentLength | bytes }}
              </div>
            </ng-template>
          </div>
        }
        @if (files.length === 0) {
          <div class="card-body">
            <p class="text-muted">{{ 'none' | transloco }}</p>
          </div>
        }
      } @else {
        <app-loading-indicator></app-loading-indicator>
      }
