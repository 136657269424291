import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit, Signal, computed
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { Step } from '../../../../shared/component/dialog/dialog.component';
import { Option } from '../../../../shared/component/multiselect/multiselect.component';
import { ErrorType, MessageInfo } from '../../../../shared/model/errors';
import { hasInvalidUserInput } from '../../../../shared/model/forms';
import { Subscriptions } from '../../../../shared/util/Subscriptions';
import {
  createDialogCancelAction,
  createDialogOkAction,
  createItemPrimaryAction,
} from '../../../../shared/util/actions';
import {
  VirtualLaboratoryDeviceApiService,
  MoveDeviceToLaboratoryResult,
} from '../../api/virtual-laboratory-device-api.service';
import { NotificationService, NotificationType } from '../../../../core/service/notification.service';
import { ConfirmDialogType } from 'src/app/shared/component/confirm-dialog/model/confirmation-dialog-types';
import { ButtonType } from 'src/app/shared/component/button/button.component';
import { VirtualLaboratoryService } from 'src/app/virtual-laboratory/service/virtual-laboratory.service';
import { SharedDeviceService } from '../../service/shared-device.service';

export interface VirtualLaboratoryPreviewReference {
  id: string;
  name: string;
}

@Component({
  templateUrl: './device-move-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['../device-registration-dialog/device-registration-dialog.component.scss'],
})
export class DeviceMoveDialogComponent implements OnInit, OnDestroy {
  @Input() deviceId?: string;
  @Input() deviceTenantId?: string;
  @Input() selectedLaboratory?: VirtualLaboratoryPreviewReference;
  
  virtualLaboratories: Signal<VirtualLaboratoryPreviewReference[]> = this.computedVirtualLabs
  virtualLaboratorySelectOptions: Signal<Option[]> = this.computedVirtualLabOptions;

  currentPageIndex: number = 0;

  type: ConfirmDialogType = ConfirmDialogType.Info;

  form = this.fb.group({
    virtualLaboratory: this.fb.control('', {
      nonNullable: true,
      validators: [Validators.required],
      updateOn: 'change',
    }),
  });

  private subscriptions: Subscriptions = new Subscriptions();

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private translocoService: TranslocoService,
    private virtualLaboratoryService: VirtualLaboratoryService,
    private sharedDeviceService: SharedDeviceService,
    private changeDetection: ChangeDetectorRef,
    private laboratoryDeviceApiService: VirtualLaboratoryDeviceApiService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.put(this.virtualLaboratoryService.getAllByTenant(this.deviceTenantId || '').subscribe());
  }

  get steps(): Step[] {
    return [
      {
        actions: [
          createDialogCancelAction(this.activeModal, this.translocoService),
          createItemPrimaryAction(
            this.translocoService.translate('device.move-dialog.action'),
            (): boolean => {
              return (
                !!this.deviceTenantId &&
                !!this.deviceId &&
                !this.form.invalid &&
                !this.laboratoryDeviceApiService.isMovingLaboratory.getValue()
              );
            },
            (): boolean => this.laboratoryDeviceApiService.isMovingLaboratory.getValue(),
            (): Promise<void> => {
              return new Promise(resolve => {
                this.doMoveDevice();
                resolve();
              });
            }
          ),
        ],
      },
      {
        actions: [createDialogOkAction(this.activeModal, this.translocoService, ButtonType.MODAL_SUCCESS)],
      },
    ];
  }

  doMoveDevice() {
    const laboratoryId = this.virtualLaboratory.value;
    const deviceId = this.deviceId;
    if (deviceId) {
      this.subscriptions.put(
        this.laboratoryDeviceApiService.moveDeviceToLaboratory(deviceId, laboratoryId).subscribe(result => {
          switch (result) {
            case MoveDeviceToLaboratoryResult.Success:
              this.currentPageIndex = this.currentPageIndex + 1;
              this.type = ConfirmDialogType.Success;
              break;
            default:
              this.notificationService.pushNotification({
                type: NotificationType.Error,
                title: this.translocoService.translate('move-device.notification.unprocessable-request.title'),
                text: this.translocoService.translate('move-device.notification.unprocessable-request.text', {
                  deviceId,
                  laboratoryId,
                }),
              });
              this.activeModal.close(false);
              break;
          }
          this.changeDetection.detectChanges();
        })
      );
    }
  }

  get virtualLaboratory(): any {
    return this.form.get('virtualLaboratory');
  }

  get isVirtualLaboratoryValid(): boolean {
    return hasInvalidUserInput(this.virtualLaboratory);
  }

  get virtualLaboratoryInfoMessages(): MessageInfo | undefined {
    const errors = this.virtualLaboratory.errors;
    if (!errors) {
      return;
    }
    if (errors?.required) {
      return {
        description: 'error.no-item-selected',
        type: ErrorType.VALIDATION_ERROR,
      };
    }
  }
  
  private get computedVirtualLabs(): Signal<VirtualLaboratoryPreviewReference[]> {
    return computed(
      () => this.virtualLaboratoryService.virtualLaboratoryCollectionByTenant()?.collection.map(item => item.data) || []
    );
  }
  
  private get computedVirtualLabOptions(): Signal<Option[]> {
    return computed(
      () => this.virtualLaboratories().map(virtualLaboratory => {
        return {
          value: virtualLaboratory.id,
          label: virtualLaboratory.name,
        };
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
