<div class="card-header">
  <ng-container *ngIf="icon">
    <i *ngIf="icon.length > 0" class="{{iconClass}}"></i>
  </ng-container>
  <h5 class="card-title">{{title}}</h5>
  <div *ngIf="actions.length > 0" class="ml-auto btn-group">
    <ng-container *ngIf="actions.length < 3; else dropdowns">
      <ng-container *ngFor="let action of actions">
        <ng-container *ngIf="action.link as link">
          <a
            *ngIf="link.length > 0; else dynamicButton"
            class="ml-auto btn {{action.type?.valueOf()}}"
            [routerLink]="[action.link]">
            <ng-container *ngIf="action.icon as icon">
              <i *ngIf="icon.length > 0" class="{{action.iconClass()}}" aria-hidden="true"></i>
            </ng-container>
            {{action.title}}
          </a>
        </ng-container>
        <ng-template #dynamicButton>
          <app-button
            buttonClass="{{action.type?.valueOf()}} ml-auto"
            iconClass="{{action.icon}}"
            title="{{action.title}}"
            [handler]="action.handler ? action.handler : noopHandler"
            [disabled]="(action.isEnabled && !action.isEnabled())"
            [isWorkInProgress]="(action.isWorking && action.isWorking())">
            <ng-container *ngIf="action.icon as icon">
              <i *ngIf="icon.length > 0" class="{{action.iconClass()}}" aria-hidden="true"></i>
            </ng-container>
            {{action.title}}
          </app-button>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-template #dropdowns>
      <ng-container *ngIf="firstAction">
        <ng-container *ngIf="firstAction.link as link">
          <a
            *ngIf="link.length > 0; else dynamicButton"
            class="ml-auto btn btn-vendor"
            [routerLink]="[firstAction.link]">
            <ng-container *ngIf="firstAction.icon as icon">
              <i *ngIf="icon.length > 0" class="{{firstAction.iconClass()}}" aria-hidden="true"></i>
            </ng-container>
            {{firstAction.title}}
          </a>
        </ng-container>
        <ng-template #dynamicButton>
          <button
            class="ml-auto btn {{firstAction.type?.valueOf()}}"
            [disabled]="!firstAction.isEnabled"
            (click)="firstAction.handler()">
            <ng-container *ngIf="firstAction.icon as icon">
              <i *ngIf="icon.length > 0" class="{{firstAction.iconClass()}}" aria-hidden="true"></i>
            </ng-container>
            {{firstAction.title}}
          </button>
        </ng-template>
      </ng-container>
      <app-dropdown [actions]="restOfActions"></app-dropdown>
    </ng-template>
  </div>
</div>
