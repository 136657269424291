import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, first, map, mergeMap, retryWhen, takeWhile } from 'rxjs/operators';
import { NotificationService, NotificationType } from 'src/app/core/service/notification.service';
import { SessionKey, SessionManagerService } from 'src/app/shared/service/session-manager.service';
import { SharedDeviceApiService } from '../api/shared-device-api.service';
import {
  Device,
  DeviceToken,
  DeviceTokenRequest,
  DeviceVendor,
  PrepareDeviceRegistrationResult,
} from '../model/device.model';

export enum DeviceRegistrationPhase {
  WaitingForDevice,
  FinishedSuccessfully,
  Failed,
}

export interface DeviceRegistration {
  phase: DeviceRegistrationPhase;
  token?: DeviceToken;
  registeredDevice?: Device;
  device?: Device;
}

function isValid(token: DeviceToken): boolean {
  const now = new Date();
  const expireTime = new Date(token.tokenExpiresAt!);
  return expireTime.getHours()! * 60 + expireTime.getMinutes() - now.getHours() * 60 + now.getMinutes() > 0;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceRegistrationStateService {
  private registrationSubject: BehaviorSubject<DeviceRegistration | undefined> = new BehaviorSubject<
    DeviceRegistration | undefined
  >(undefined);
  registration$ = this.registrationSubject.asObservable();

  constructor(
    private deviceApiService: SharedDeviceApiService,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
    private storageService: SessionManagerService
  ) {}

  cancelRegistration() {
    this.storageService.clear(SessionKey.pairingDeviceToken);
    this.registrationSubject.next(undefined);
  }

  listenForDevice(): Observable<void> {
    const token = this.storageService.get(SessionKey.pairingDeviceToken);

    if (token) {
      this.registrationSubject.next({
        phase: DeviceRegistrationPhase.WaitingForDevice,
        token: JSON.parse(token),
      });
    }

    return this.registration$.pipe(
      mergeMap(registration => {
        if (registration?.phase === DeviceRegistrationPhase.WaitingForDevice) {
          return this.deviceApiService.startDeviceByIdPoller(registration!.token!.preparedDeviceId).pipe(
            map(device => {
              this.storageService.clear(SessionKey.pairingDeviceToken);
              this.registrationSubject.next(
                Object.assign(registration!, {
                  phase: DeviceRegistrationPhase.FinishedSuccessfully,
                  device: device?.data,
                })
              );
            })
          );
        } else {
          return of(undefined);
        }
      }),
      retryWhen(errors =>
        errors.pipe(
          delay(15000),
          takeWhile(
            () => (this.registrationSubject.value?.token || false) && isValid(this.registrationSubject.value?.token)
          )
        )
      )
      // * retry the call every 15000ms when there is an error, stops after 15 minutes
    );
  }

  initiateRegistration(virtualLaboratoryId: string, vendor: DeviceVendor, serialNumber: string): Observable<any> {
    this.cancelRegistration();
    const reqParam: DeviceTokenRequest = {
      virtualLaboratoryId: virtualLaboratoryId,
      deviceReference: {
        vendor: vendor,
        serialNumber: serialNumber,
      },
    };

    return this.deviceApiService.prepareDeviceRegistration(reqParam).pipe(
      first(),
      map(response => {
        switch (response.resultStatus) {
          case PrepareDeviceRegistrationResult.Success:
            this.registrationSubject.next({
              phase: DeviceRegistrationPhase.WaitingForDevice,
              token: response.token,
            });
            this.storageService.set(SessionKey.pairingDeviceToken, JSON.stringify(response.token));
            break;
          case PrepareDeviceRegistrationResult.NotAuthorized:
            this.notificationService.pushNotification({
              type: NotificationType.Error,
              title: this.translocoService.translate('device.registration.error.title'),
              text: this.translocoService.translate('device.registration.error.not-authorized'),
            });
            break;
          case PrepareDeviceRegistrationResult.UnprocessableRequest:
            this.notificationService.pushNotification({
              type: NotificationType.Error,
              title: this.translocoService.translate('device.registration.error.title'),
              text: this.translocoService.translate('device.registration.error.unprocessable-request'),
            });
            break;
        }
      })
    );
  }
}
