import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-device-file-list-card',
  templateUrl: './file-list-card.component.html',
})
export class FileListCardComponent {
  @Input() title!: string;
  @Input() deviceId!: string;
  @Input() filetype!: string;
  @Input() scope?: string;
}
