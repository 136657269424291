import { Pipe, PipeTransform } from '@angular/core';
import { CoreUtilityService } from 'src/app/core/service/core-utility.service';

@Pipe({
  name: 'ARGB2RGB',
})
export class Argb2RgbPipe implements PipeTransform {
  constructor(private utilityService: CoreUtilityService) {}

  transform(argb?: string): string {
    if (!argb) {
      return '';
    }
    return this.utilityService.convertColorFormat(argb);
  }
}
