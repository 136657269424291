import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ThemeService, ThemeVendor } from '../../service/theme.service';

@Component({
  selector: 'app-vendor-logo',
  templateUrl: './vendor-logo.component.html',
  styleUrls: ['./vendor-logo.component.scss'],
})
export class VendorLogoComponent {
  vendor$?: Observable<ThemeVendor> = undefined;
  ThemeVendor = ThemeVendor;

  constructor(themeService: ThemeService) {
    this.vendor$ = themeService.vendor$;
  }
}
