import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/model/api-response';
import { DeviceVendor } from 'src/app/device/device-shared/model/device.model';
import { handleError } from 'src/app/shared/util/http-utils';
import { environment } from 'src/environments/environment';
import { DeviceModelInfo } from '../model/device-model.model';

const ENDPOINT = `${environment.backendApi.shared.uri}/device-models`;

@Injectable({
  providedIn: 'root',
})
export class DeviceModelApiService {
  constructor(private http: HttpClient) {}

  getAllByVendor(vendor: DeviceVendor): Observable<DeviceModelInfo[]> {
    const url = `${ENDPOINT}?vendor=${vendor}`;
    return this.http.get<{ data: DeviceModelInfo[] }>(url).pipe(
      map(response => response.data),
      catchError(handleError)
    );
  }

  getAll(): Observable<DeviceModelInfo[]> {
    return this.http.get<ApiResponse<DeviceModelInfo[]>>(ENDPOINT).pipe(
      map(response => response.data),
      catchError(handleError)
    );
  }
}
