import { Injectable } from '@angular/core';

import { CoreNavigationService } from '../service/core-navigation.service';

import { TranslocoService } from '@ngneat/transloco';

interface MaybeAdaptedLegacyUrl {
  requestedUrl: string;
  redirectUrl?: string;
  requestedLanguage?: string;
}

export function adaptLegacyUrl(url: string): MaybeAdaptedLegacyUrl {
  function resolveRequestedLanguage(languageKeyFromPath: string): string {
    switch (languageKeyFromPath) {
      case 'de':
        return 'de';
      default:
        return 'en';
    }
  }

  const parsedUrl = new URL(url);

  if (!parsedUrl) {
    return { requestedUrl: url };
  }

  const pathElements = parsedUrl.pathname ? parsedUrl.pathname.substring(1).split('/') : [];
  const languagePathElement = pathElements.length > 0 ? pathElements[0] : undefined;

  if (languagePathElement === 'de' || languagePathElement === 'en-US') {
    pathElements.shift();
    Object.assign(parsedUrl, { pathname: pathElements.join('/') });

    return {
      requestedUrl: url,
      redirectUrl: parsedUrl.toString(),
      requestedLanguage: resolveRequestedLanguage(languagePathElement),
    };
  } else {
    return { requestedUrl: url };
  }
}

@Injectable({
  providedIn: 'root',
})
export class RedirectLocaleGuard {
  constructor(private navigationService: CoreNavigationService, private translocoService: TranslocoService) {}

  canActivate(): boolean {
    const url = window.location.href;
    const maybeAdaptedLegacyUrl = adaptLegacyUrl(url);
    if (maybeAdaptedLegacyUrl.redirectUrl) {
      window.location.replace(maybeAdaptedLegacyUrl.redirectUrl);
      if (maybeAdaptedLegacyUrl.requestedLanguage) {
        this.translocoService.setActiveLang(maybeAdaptedLegacyUrl.requestedLanguage);
      }
      return false;
    }
    return true;
  }
}
