import { Injectable } from '@angular/core';
import { NgbDate, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CoreUtilityService {
  constructor(private decimalPipe: DecimalPipe) {}

  percentToUnitStr(value: number, targetUnit: string, decPlaces: number, disableNegative: boolean): string {
    let ret: string = '';
    let temp: number;

    temp = this.percentToUnit(value, targetUnit, decPlaces, disableNegative);
    ret = this.number2UnitStr(temp, decPlaces, targetUnit);

    return ret;
  }

  percentToUnit(value: number, targetUnit: string, decPlaces: number, disableNegative: boolean): number {
    let ret: number = value;

    if (ret) {
      if (disableNegative === true && ret < 0) {
        return 0;
      }

      if (targetUnit) {
        targetUnit = targetUnit.toLocaleLowerCase();
      }

      switch (targetUnit) {
        case '%':
          ret = this.round(ret, decPlaces);
          break;
        case 'ppm':
          ret = ret * 10000;
          ret = this.round(ret, decPlaces);
          break;
        default:
          ret = this.round(ret, decPlaces);
          break;
      }
    }

    return ret;
  }

  milligramToUnitStr(value: number, targetUnit: string, decPlaces: number): string {
    let ret: string = '';
    let temp: number;

    temp = this.milligramToUnit(value, targetUnit, decPlaces);
    ret = this.number2UnitStr(temp, decPlaces, targetUnit);

    return ret;
  }

  milligramToUnit(value: number, targetUnit: string, decPlaces: number): number {
    let ret: number = value;

    if (ret) {
      if (targetUnit) {
        targetUnit = targetUnit.toLocaleLowerCase();
      }

      switch (targetUnit) {
        case 'mg':
          ret = this.round(ret, decPlaces);
          break;
        case 'g':
          ret = ret / 1000;
          ret = this.round(ret, decPlaces);
          break;
        default:
          ret = this.round(ret, decPlaces);
          break;
      }
    }

    return ret;
  }

  gramToMilligram(value: number, decPlaces: number): number {
    let ret: number = value;

    if (ret) {
      ret = ret * 1000;
      ret = this.round(ret, decPlaces);
    }

    return ret;
  }

  round(value: number, decPlaces: number): number {
    let ret: number;
    let decMultiplier: number = 1;

    if (!decPlaces) {
      decPlaces = 0;
    } else if (decPlaces < 0) {
      decPlaces = 8;
    } else if (decPlaces > 20) {
      decPlaces = 20;
    }

    decMultiplier = Math.pow(10, decPlaces);
    ret = Math.round((value + Number.EPSILON) * decMultiplier) / decMultiplier;

    if (ret === -0) {
      ret = 0;
    }

    return ret;
  }

  number2UnitStr(value: number, decPlaces: number, unit: string): string {
    let ret: string = '';

    if (!isNaN(value) && !isNaN(decPlaces)) {
      let decPlacesFmt: string = '1.2-2';

      if (!decPlaces) {
        decPlaces = 0;
      } else if (decPlaces > 20) {
        decPlaces = 20;
      } else if (decPlaces < 0) {
        decPlaces = 0;
      }

      decPlacesFmt = '1.' + decPlaces.toString() + '-' + decPlaces.toString();

      ret = value?.toString() ? this.decimalPipe.transform(value, decPlacesFmt) + ' ' + unit : '';
    }

    return ret;
  }

  convertColorFormat(argb: string): string {
    let ret: string = '';

    if (argb && argb.length && argb[0] === '#') {
      argb = argb.substr(1);

      if (argb.length === 8) {
        ret = '#' + argb.substr(2) + argb.substr(1, 2);
      }
    }

    return ret;
  }

  isDate(dateStr: string): boolean {
    if (Date.parse(dateStr)) {
      return true;
    } else {
      return false;
    }
  }

  str2Date(dateStr: string): Date {
    if (this.isDate(dateStr)) {
      return new Date(dateStr);
    } else {
      return new Date();
    }
  }

  date2IsoStr(date: Date): string | undefined {
    if (date) {
      return date.toISOString().split('T')[0];
    }
    return undefined;
  }

  date2TimeStr(date: Date): string | undefined {
    if (date) {
      const hours = date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours().toString();
      const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes().toString();
      const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds().toString() : date.getSeconds().toString();
      return hours + ':' + minutes + ':' + seconds;
    }
    return undefined;
  }

  ngbDateStructBefore(dateStructFrom: NgbDateStruct, dateStructTo: NgbDateStruct): boolean {
    let ret: boolean = false;
    let dateFrom: NgbDate;
    let dateTo: NgbDate;

    if (
      dateStructFrom &&
      dateStructTo &&
      this.ngbDateStructIsDate(dateStructFrom) &&
      this.ngbDateStructIsDate(dateStructTo)
    ) {
      dateFrom = new NgbDate(dateStructFrom.year, dateStructFrom.month, dateStructFrom.day);
      dateTo = new NgbDate(dateStructTo.year, dateStructTo.month, dateStructTo.day);

      if (dateFrom.before(dateTo)) {
        ret = true;
      }
    }

    return ret;
  }

  ngbDateStructIsDate(dateStruct: NgbDateStruct): boolean {
    let ret: boolean = false;

    if (dateStruct && dateStruct.day && dateStruct.month && dateStruct.year) {
      ret = true;
    }

    return ret;
  }

  ngbDateStructIsSameDay(dateStructFrom: NgbDateStruct, dateStructTo: NgbDateStruct): boolean {
    let ret: boolean = false;
    let dateFrom: NgbDate;
    let dateTo: NgbDate;

    if (
      dateStructFrom &&
      dateStructTo &&
      this.ngbDateStructIsDate(dateStructFrom) &&
      this.ngbDateStructIsDate(dateStructTo)
    ) {
      dateFrom = new NgbDate(dateStructFrom.year, dateStructFrom.month, dateStructFrom.day);
      dateTo = new NgbDate(dateStructTo.year, dateStructTo.month, dateStructTo.day);

      if (dateFrom.equals(dateTo)) {
        ret = true;
      }
    }

    return ret;
  }

  ngbTimeStructIsBefore(timeStructFrom: NgbTimeStruct, timeStructTo: NgbTimeStruct): boolean {
    let ret: boolean = false;

    if (
      timeStructFrom &&
      timeStructTo &&
      this.ngbTimeStructIsTime(timeStructFrom) &&
      this.ngbTimeStructIsTime(timeStructTo)
    ) {
      if (timeStructFrom.hour < timeStructTo.hour) {
        ret = true;
      } else if (timeStructFrom.hour === timeStructTo.hour && timeStructFrom.minute < timeStructTo.minute) {
        ret = true;
      }
    }

    return ret;
  }

  ngbTimeStructIsTime(timeStruct: NgbTimeStruct): boolean {
    let ret: boolean = false;

    if (timeStruct && this.isTime(timeStruct.hour, timeStruct.minute)) {
      ret = true;
    }

    return ret;
  }

  isTime(hours: number, minutes: number): boolean {
    let ret: boolean = false;

    if (!isNaN(hours) && !isNaN(minutes)) {
      if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
        ret = true;
      }
    }

    return ret;
  }

  ngbDateStructAndTimeStruct2Date(dateStruct: NgbDateStruct, timeStruct: NgbTimeStruct): Date {
    let ret: Date;

    ret = new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day, timeStruct.hour, timeStruct.minute);

    return ret;
  }

  copyToClipboard(strToCopy: string): void {
    navigator.clipboard.writeText(strToCopy);
  }
}
