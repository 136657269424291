import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './page/login/login.component';
import { AboutComponent } from './page/about/about.component';
import { VerderUIAssetLibraryComponent } from './page/verder-uiasset-library/verder-uiasset-library.component';
import { ThemeToggleDirective } from './directive/theme-toggle.directive';
import { VendorLogoComponent } from './component/vendor-logo/vendor-logo.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { LoginFailedComponent } from './page/login-failed/login-failed.component';
import { LoggedOutComponent } from './page/logged-out/logged-out.component';
import { ModalContainerDirective } from './directive/modal-container.directive';
import { ResetPasswordDialogComponent } from './component/reset-password-dialog/reset-password-dialog.component';
import { FooterComponent } from './component/footer/footer.component';
import { AppNotificationAreaComponent } from './component/notification-area/app-notification-area.component';
import { AppNotificationComponent } from './component/notification-area/app-notification.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateBreadcrumbPipe } from './pipe/translate-breadcrumb.pipe';
import { RouteLoadingIndicatorComponent } from './component/route-loading-indicator/route-loading-indicator.component';
import { UnauthorizedComponent } from './page/unauthorized/unauthorized.component';
import { HomeComponent } from './page/home/home.component';
import { NavigationComponent } from './component/navigation/navigation.component';
import { DesktopNavigationComponent } from './component/navigation/desktop/desktop-navigation.component';
import { MobileNavigationComponent } from './component/navigation/mobile/mobile-navigation.component';
import { BottomNavComponent } from './component/navigation/mobile/bottom-nav/bottom-nav.component';
import { QrScannerOverlayComponent } from './component/qr-scanner-overlay/qr-scanner-overlay.component';
import { NoAccessComponent } from './page/no-access/no-access.component';
// import {SilentSSOComponent} from "./page/silent-sso/silent-sso.component";

const declarations = [
  UnauthorizedComponent,
  LoginComponent,
  AboutComponent,
  VerderUIAssetLibraryComponent,
  ThemeToggleDirective,
  VendorLogoComponent,
  NotFoundComponent,
  NoAccessComponent,
  LoginFailedComponent,
  LoggedOutComponent,
  ModalContainerDirective,
  ResetPasswordDialogComponent,
  FooterComponent,
  AppNotificationAreaComponent,
  AppNotificationComponent,
  TranslateBreadcrumbPipe,
  RouteLoadingIndicatorComponent,
  HomeComponent,
  NavigationComponent,
  DesktopNavigationComponent,
  MobileNavigationComponent,
  BottomNavComponent,
  QrScannerOverlayComponent,
  // SilentSSOComponent
];

@NgModule({
  declarations: declarations,
  exports: [...declarations, SharedModule],
  imports: [CommonModule, SharedModule],
})
export class AppCoreModule {}
