import { Action } from '../component/card-header/card-header';
import { ButtonType } from '../component/button/button.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';

export function createDialogCancelAction<T>(
  activeModal: NgbActiveModal,
  translocoService: TranslocoService,
  result?: T,
  beforeCloseFn?: () => void
): Action {
  return new Action({
    title: translocoService.translate('cancel'),
    handler: async (): Promise<void> => {
      return new Promise(resolve => {
        if (beforeCloseFn) {
          beforeCloseFn();
        }
        if (result) {
          activeModal.close(result);
        } else {
          activeModal.close(false);
        }
        resolve();
      });
    },
    isEnabled: () => true,
    type: ButtonType.LINK,
  });
}

export function createDialogOkAction(
  activeModal: NgbActiveModal,
  translocoService: TranslocoService,
  type: ButtonType,
  beforeCloseFn?: () => void
): Action {
  return new Action({
    title: translocoService.translate('ok'),
    handler: async (): Promise<void> => {
      return new Promise(resolve => {
        if (beforeCloseFn) {
          beforeCloseFn();
        }
        activeModal.close(true);
        resolve();
      });
    },
    isEnabled: () => true,
    type: type,
  });
}

export function createItemSecondAction(
  name: string,
  isEnabled: () => boolean,
  isWorking: () => boolean,
  handler: () => Promise<void>
): Action {
  return new Action({
    title: name,
    isEnabled: (): boolean => {
      return isEnabled();
    },
    isWorking: (): boolean => {
      return isWorking();
    },
    handler: async (): Promise<void> => {
      return handler();
    },
    type: ButtonType.PRIMARY_OUTLINE,
  });
}

export function createItemPrimaryAction(
  name: string,
  isEnabled: () => boolean,
  isWorking: () => boolean,
  handler: () => Promise<void>
): Action {
  return new Action({
    title: name,
    isEnabled: (): boolean => {
      return isEnabled();
    },
    isWorking: (): boolean => {
      return isWorking();
    },
    handler: async (): Promise<void> => {
      return handler();
    },
    type: ButtonType.PRIMARY,
  });
}

export function createItemMenuAction(
  name: string,
  isEnabled: () => boolean,
  isWorking: () => boolean,
  handler: () => Promise<void>
): Action {
  return new Action({
    title: name,
    isEnabled: (): boolean => {
      return isEnabled();
    },
    isWorking: (): boolean => {
      return isWorking();
    },
    handler: async (): Promise<void> => {
      return handler();
    },
    type: ButtonType.LINK,
  });
}
