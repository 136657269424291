<div class="row large-gutter" data-cy="large-gutter">
  @if (items.length === 0) {
    <div class="col-md-12">
      <div class="no-items-card text-muted" data-cy="noItemsCard">
        <ng-container *ngTemplateOutlet="noResultsTemplate || defaultNoResultsTemplate"> </ng-container>
      </div>
    </div>
  }

  @if (items.length > 0) {
    @for (item of items; track item; let i = $index) {
      <div class="col-md-6" [style.z-index]="999 - i">
        <div
          class="item h-100"
          data-cy="cardListItem"
          [class.pointer]="clickable || item.clickable"
          (click)="cardClicked.emit(item)"
          (keydown.enter)="cardClicked.emit(item)"
          tabindex="0">
          @if (item.actions; as actions) {
            @if (actions.length > 0) {
              <div
                class="close-icon"
                data-cy="actionMenuButton"
                style="position: absolute; top: 0; right: 0">
                <app-dropdown buttonType="btn-link action-menu-btn" [actions]="item.actions" (click)="ignoreClick($event)">
                </app-dropdown>
              </div>
            }
          }
          <div class="item-body">
            @if (item.image) {
              <img [src]="item.image" class="image" />
            }
            <div class="content">
              <ng-container *ngTemplateOutlet="contentTemplate; context: { $implicit: item }"> </ng-container>
            </div>
          </div>
        </div>
      </div>
    }
  }
</div>

<ng-template #defaultNoResultsTemplate>
  <div>{{ 'card-grid-no-items' | transloco }}</div>
</ng-template>
