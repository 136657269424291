import { Component, Input } from '@angular/core';
import { Action } from '../card-header/card-header';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
})
export class DropdownButtonComponent {
  @Input() actions: Action[] = [];

  @Input() buttonType!: string;
}
