@if (visible) {
  <div class="progress" style="height: {{ height }};">
    @if (showRegular) {
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
      [style.width]="progressPercentage | percent"></div>
    }
    @if (showInfinite) {
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
      style="width: 100%"></div>
    }
  </div>
}
