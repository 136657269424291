@if (progressInformation) {
  @if (progressInformation.progressAbsolute) {
    <span class="item">
      <i class="fa fa-cubes"></i>
      {{ progressInformation.progressAbsolute.currentValue }} /
      {{ progressInformation.progressAbsolute.maximumValue }}
    </span>
  }
  @if (hasEstimatedSecondsRemaining && progressInformation.estimatedSecondsRemaining) {
    <span class="item">
      <i class="fa fa-flag-checkered"></i>
      {{ progressInformation.estimatedSecondsRemaining | seconds }}
    </span>
  }
  @if (progressInformation.secondsElapsed && !hasEstimatedSecondsRemaining) {
    <span class="item">
      <i class="fa fa-clock-o"></i>
      {{ progressInformation.secondsElapsed | seconds }}
    </span>
  }
}
