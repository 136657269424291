import { Component, OnDestroy } from '@angular/core';
import {
  faMicroscope,
  faCircleInfo,
  faUserGear,
  faServer,
  faLayerGroup,
  faPowerOff,
  faHome,
  faBuildingUser,
} from '@fortawesome/free-solid-svg-icons';
import { switchMap } from 'rxjs/operators';
import { BackendPermissionsService } from 'src/app/access-rights/backend-permissions.service';
import { SharedMediaQueryService } from 'src/app/shared/service/shared-media-query.service';
import { Subscriptions } from 'src/app/shared/util/Subscriptions';
import { CoreLoginService } from '../../../../service/core-login.service';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['../../navigation.component.scss'],
})
export class BottomNavComponent implements OnDestroy {
  tenantIcon = faBuildingUser;
  labsIcon = faMicroscope;
  infoHubIcon = faCircleInfo;
  userSettingsIcon = faUserGear;
  iotAdminIcon = faServer;
  deviceListIcon = faLayerGroup;
  logoutIcon = faPowerOff;
  homeIcon = faHome;

  permissions$ = this.permissionsService.index$;
  user$ = this.loginService.currentUser;

  isMobile?: boolean = false;

  private subscriptions: Subscriptions = new Subscriptions();

  constructor(
    private mediaService: SharedMediaQueryService,
    private permissionsService: BackendPermissionsService,
    private loginService: CoreLoginService
  ) {
    this.subscriptions.put(
      this.mediaService
        .checkScreenSize('575', 'portrait')
        .pipe(switchMap(_ => this.mediaService.screenSize$))
        .subscribe(boolean => {
          this.isMobile = boolean;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
