<ng-container>
  @if (label) {
    @if (label.length > 0) {
      <label class="form-label" for="{{ inputId }}">{{ label }}</label>
    }
  }
  <textarea
    id="{{ inputId }}"
    name="{{ inputId }}"
    type="text"
    class="form-control input-textarea"
    [class.is-invalid]="!isValid"
    [placeholder]="placeholder"
    autocomplete="none"
    [value]="value"
    (change)="writeValue($any($event.target).value)"
    (keyup)="onKeyup($event.target)"
  [disabled]="disabled"></textarea>
  @if (!isValid) {
    <app-input-info-message [show]="!isValid" [message]="messageInfo"></app-input-info-message>
  }
</ng-container>
