import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedDeviceService } from '../../service/shared-device.service';
import { DeviceResult } from '../../api/shared-device-api.service';

@Component({
  selector: 'app-device-product-information-side-navigation-item',
  templateUrl: './device-product-information-side-navigation-item.component.html',
  styleUrls: ['./device-product-information-side-navigation-item.component.scss'],
})
export class DeviceProductInformationSideNavigationItemComponent {
  device?: DeviceResult = {} as DeviceResult;

  constructor(route: ActivatedRoute, private sharedDeviceService: SharedDeviceService) {
    this.device = this.sharedDeviceService.device();
  }
}
