import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  DeviceConnectionStatusType,
  DeviceOperationalStatusType,
} from 'src/app/device/device-shared/model/device.model';

export interface ConnectionStatusComponentFlags {
  isActive: boolean;
  isIdle: boolean;
  isWaiting: boolean;
  isError: boolean;
  isNotActive: boolean;
  isInitializing: boolean;
  isOffline: boolean;
}

export interface DeviceStatusType {
  connectionStatusType?: DeviceConnectionStatusType;
  operationalStatusType?: DeviceOperationalStatusType;
}

export function createFlags(status?: DeviceStatusType): ConnectionStatusComponentFlags | undefined {
  if (!status) {
    return;
  }

  return {
    isActive:
      status.connectionStatusType === DeviceConnectionStatusType.Connected &&
      status.operationalStatusType === DeviceOperationalStatusType.Active,
    isIdle:
      status.connectionStatusType === DeviceConnectionStatusType.Connected &&
      status.operationalStatusType === DeviceOperationalStatusType.Idle,
    isWaiting:
      status.connectionStatusType === DeviceConnectionStatusType.Connected &&
      status.operationalStatusType === DeviceOperationalStatusType.WaitingForUser,
    isError:
      status.connectionStatusType === DeviceConnectionStatusType.Connected &&
      status.operationalStatusType === DeviceOperationalStatusType.Malfunctional,
    isNotActive:
      status.connectionStatusType === DeviceConnectionStatusType.Connected &&
      status.operationalStatusType === DeviceOperationalStatusType.NotOperational,
    isInitializing:
      status.connectionStatusType === DeviceConnectionStatusType.Connected &&
      status.operationalStatusType === DeviceOperationalStatusType.Initializing,
    isOffline: status.connectionStatusType === DeviceConnectionStatusType.Disconnected,
  };
}

@Component({
  selector: 'app-device-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectionStatusComponent {
  @Input() flags?: DeviceConnectionStatusType;
  @Input() fullWidth = false;
  @Input() autoWidth = false;
}
