// @ts-nocheck
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  DeviceConnectionStatusType,
  DeviceJournalItemLevel,
  DeviceJournalItemPriority,
  DeviceJournalItemType,
  DeviceModel,
  DeviceModelType,
  DeviceNotification,
  DeviceNotificationLevel,
  DeviceNotificationPriority,
  DeviceNotificationSummary,
  DeviceOperationalStatusType,
  DeviceStatus,
  DeviceVendor,
} from 'src/app/device/device-shared/model/device.model';
import {
  ApiDevice,
  ApiDeviceJournalItem,
  ApiDeviceNotification,
  ApiDeviceSummary,
} from 'src/app/device/device-shared/model/api-device.model';
import { v4 as uuidv4 } from 'uuid';

interface ApiDeviceDummy {
  device: ApiDevice;
  notifications?: ApiDeviceNotification[];
  journalItems?: ApiDeviceJournalItem[];
  files?: any;
}

const randomString = () => uuidv4();

const deviceModelRetschGm200: DeviceModel = {
  type: DeviceModelType.Product,
  identification: { id: 'GM200', version: randomString() },
};
const deviceModelRetschGm200WithoutVersion: DeviceModel = {
  type: DeviceModelType.Product,
  identification: { id: 'GM200' },
};
const deviceModelQatmEvo60: DeviceModel = {
  type: DeviceModelType.Product,
  identification: { id: 'Q60A-Evo', version: randomString() },
};
const deviceModelCamsizer3D: DeviceModel = {
  type: DeviceModelType.Product,
  identification: { id: 'CAMSIZER-3D', version: randomString() },
};

const deviceStatusDisconnected: DeviceStatus = {
  connection: { type: DeviceConnectionStatusType.Disconnected },
};
const deviceStatusDisconnectedNotOperational: DeviceStatus = {
  connection: { type: DeviceConnectionStatusType.Connected },
  operational: {
    type: DeviceOperationalStatusType.NotOperational,
    message: randomString(),
  },
};
const deviceStatusConnectedIdle: DeviceStatus = {
  connection: { type: DeviceConnectionStatusType.Connected },
  operational: {
    type: DeviceOperationalStatusType.Idle,
    message: randomString(),
  },
};
const deviceStatusConnectedActiveFull: DeviceStatus = {
  connection: { type: DeviceConnectionStatusType.Connected },
  operational: {
    type: DeviceOperationalStatusType.Active,
    message: randomString(),
    progressPercentage: 42,
    progressTemporal: {
      startedAt: new Date(new Date().getTime() - 1000 * 3600),
      estimatedSecondsRemaining: 4711,
    },
    progressAbsolute: { currentValue: 42, maximumValue: 4711 },
  },
};
const deviceStatusConnectedActiveProgressPercentageOnly: DeviceStatus = {
  connection: { type: DeviceConnectionStatusType.Connected },
  operational: {
    type: DeviceOperationalStatusType.Active,
    message: randomString(),
    progressPercentage: 42,
  },
};
const deviceStatusConnectedActiveProgressTemporalOnly: DeviceStatus = {
  connection: { type: DeviceConnectionStatusType.Connected },
  operational: {
    type: DeviceOperationalStatusType.Active,
    message: randomString(),
    progressTemporal: {
      startedAt: new Date(new Date().getTime() - 1000 * 3600),
      estimatedSecondsRemaining: 4711,
    },
  },
};
const deviceStatusConnectedActiveProgressAbsoluteOnly: DeviceStatus = {
  connection: { type: DeviceConnectionStatusType.Connected },
  operational: {
    type: DeviceOperationalStatusType.Active,
    message: randomString(),
    progressAbsolute: { currentValue: 471, maximumValue: 4711 },
  },
};
const deviceStatusConnectedActiveProgressWithStartTimeOnly: DeviceStatus = {
  connection: { type: DeviceConnectionStatusType.Connected },
  operational: {
    type: DeviceOperationalStatusType.Active,
    message: randomString(),
    progressTemporal: {
      startedAt: new Date(new Date().getTime() - 1000 * 3600),
    },
  },
};
const deviceStatusConnectedWaitingForUser: DeviceStatus = {
  connection: { type: DeviceConnectionStatusType.Connected },
  operational: {
    type: DeviceOperationalStatusType.WaitingForUser,
    message: randomString(),
  },
};
const deviceStatusConnectedMalfunctional: DeviceStatus = {
  connection: { type: DeviceConnectionStatusType.Connected },
  operational: {
    type: DeviceOperationalStatusType.Malfunctional,
    message: randomString(),
  },
};

const createBaseDeviceDummy = (
  id: string,
  name: string,
  vendor: DeviceVendor,
  type: string,
  model: DeviceModel
): ApiDevice => {
  return {
    id,
    virtualLaboratory: { id: randomString(), name: randomString() },
    name,
    vendor,
    type,
    model,
    serialNumber: randomString(),
    firmwares: [{ key: randomString(), version: randomString() }],
    status: deviceStatusDisconnected,
    statistics: { operationalHours: 0.0 },
    platformSettings: {
      features: {
        backups: {
          accessible: true,
        },
        logs: {
          accessible: true,
        },
        journal: {
          accessible: true,
        },
        generic: [],
      },
    },
  };
};

const createAvailableNotificationsSummary = (notifications: DeviceNotification[]): DeviceNotificationSummary => {
  return {
    available: {
      info: (notifications || []).filter(notification => notification.level === DeviceNotificationLevel.Info).length,
      warning: (notifications || []).filter(notification => notification.level === DeviceNotificationLevel.Warning)
        .length,
      error: (notifications || []).filter(notification => notification.level === DeviceNotificationLevel.Error).length,
      critical: (notifications || []).filter(notification => notification.level === DeviceNotificationLevel.Critical)
        .length,
    },
  };
};

const responses = {
  ok: (body: any) => of(new HttpResponse({ status: 200, body })),
};

@Injectable()
export class MockBackendApiInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method } = request;

    const dummyDevices: ApiDeviceDummy[] = [
      {
        device: createBaseDeviceDummy(
          'Retsch-GM200-DEMO1',
          'GM200 (Demo 1)',
          DeviceVendor.Retsch,
          'MILL',
          deviceModelRetschGm200
        ),
      },
      {
        device: Object.assign(
          createBaseDeviceDummy(
            'Retsch-GM200-DEMO2',
            'GM200 (Demo 2)',
            DeviceVendor.Retsch,
            'MILL',
            deviceModelRetschGm200
          ),
          { status: deviceStatusConnectedActiveFull }
        ),
        notifications: [
          {
            id: randomString(),
            level: DeviceNotificationLevel.Info,
            priority: DeviceNotificationPriority.Normal,
            summary: randomString(),
            text: randomString(),
            reportedAt: new Date(),
          },
        ],
      },
      {
        device: Object.assign(
          createBaseDeviceDummy(
            'Retsch-GM200-DEMO3',
            'GM200 (Demo 3)',
            DeviceVendor.Retsch,
            'MILL',
            deviceModelRetschGm200WithoutVersion
          ),
          { status: deviceStatusConnectedIdle }
        ),
      },
      {
        device: Object.assign(
          createBaseDeviceDummy(
            'QATM-HT-DEMO1',
            'QATM HT (Demo 1)',
            DeviceVendor.QATM,
            'HARDNESS_TESTER',
            deviceModelQatmEvo60
          ),
          { status: deviceStatusConnectedActiveFull }
        ),
        notifications: [
          {
            id: randomString(),
            level: DeviceNotificationLevel.Info,
            priority: DeviceNotificationPriority.Normal,
            summary: randomString(),
            text: randomString(),
            reportedAt: new Date(),
          },
          {
            id: randomString(),
            level: DeviceNotificationLevel.Warning,
            priority: DeviceNotificationPriority.Normal,
            summary: randomString(),
            text: randomString(),
            reportedAt: new Date(),
          },
          {
            id: randomString(),
            level: DeviceNotificationLevel.Error,
            priority: DeviceNotificationPriority.Normal,
            summary: randomString(),
            text: randomString(),
            reportedAt: new Date(),
          },
        ],
        journalItems: [
          {
            id: '1',
            type: DeviceJournalItemType.ProcessStatus,
            level: DeviceJournalItemLevel.Critical,
            priority: DeviceJournalItemPriority.High,
            code: 123,
            summary: 'Bananas are yellow',
            text: randomString(),
            reportedAt: new Date(),
          },
          {
            id: '2',
            type: DeviceJournalItemType.DeviceStatus,
            level: DeviceJournalItemLevel.Info,
            priority: DeviceJournalItemPriority.Normal,
            code: 123,
            summary: 'Bananas are yellow',
            text: randomString(),
            reportedAt: new Date(),
          },
          {
            id: '3',
            type: DeviceJournalItemType.ProcessStatus,
            level: DeviceJournalItemLevel.Warning,
            priority: DeviceJournalItemPriority.High,
            code: 123,
            summary: 'Bananas are yellow',
            text: randomString(),
            reportedAt: new Date(),
          },
        ],
      },
      {
        device: Object.assign(
          createBaseDeviceDummy(
            'QATM-HT-DEMO2',
            'QATM HT (Demo 2)',
            DeviceVendor.QATM,
            'HARDNESS_TESTER',
            deviceModelQatmEvo60
          ),
          { status: deviceStatusConnectedMalfunctional }
        ),
      },
      {
        device: Object.assign(
          createBaseDeviceDummy(
            'QATM-HT-DEMO3',
            'QATM HT (Demo 3)',
            DeviceVendor.QATM,
            'HARDNESS_TESTER',
            deviceModelQatmEvo60
          ),
          { status: deviceStatusConnectedActiveProgressAbsoluteOnly }
        ),
      },
      {
        device: Object.assign(
          createBaseDeviceDummy(
            'QATM-HT-DEMO4',
            'QATM HT (Demo 4)',
            DeviceVendor.QATM,
            'HARDNESS_TESTER',
            deviceModelQatmEvo60
          ),
          { status: deviceStatusConnectedActiveProgressPercentageOnly }
        ),
      },
      {
        device: Object.assign(
          createBaseDeviceDummy(
            'QATM-HT-DEMO5',
            'QATM HT (Demo 5)',
            DeviceVendor.QATM,
            'HARDNESS_TESTER',
            deviceModelQatmEvo60
          ),
          { status: deviceStatusConnectedActiveProgressTemporalOnly }
        ),
      },
      {
        device: Object.assign(
          createBaseDeviceDummy(
            'QATM-HT-DEMO6',
            'QATM HT (Demo 6)',
            DeviceVendor.QATM,
            'HARDNESS_TESTER',
            deviceModelQatmEvo60
          ),
          { status: deviceStatusConnectedWaitingForUser }
        ),
      },
      {
        device: Object.assign(
          createBaseDeviceDummy(
            'QATM-HT-DEMO7',
            'QATM HT (Not Operational)',
            DeviceVendor.QATM,
            'HARDNESS_TESTER',
            deviceModelQatmEvo60
          ),
          { status: deviceStatusDisconnectedNotOperational }
        ),
      },
      {
        device: Object.assign(
          createBaseDeviceDummy(
            'MICROTRAC-DEMO1',
            'Microtrac Camsizer',
            DeviceVendor.Microtrac,
            'ANALYZER',
            deviceModelCamsizer3D
          ),
          { status: deviceStatusConnectedActiveProgressWithStartTimeOnly }
        ),
      },
    ];

    const findDeviceById = (deviceId: string) => dummyDevices.find(dummy => dummy.device.id === deviceId);

    const logIntercept = () => console.log(`Request intercepted:\n\n${JSON.stringify(request, null, 2)}`);

    if (url.match(/^\/assets\/i18n\/[a-z]{2}(-[A-Z]{2})?.json$/)) {
      return next.handle(request);
    }
    const route = new URL(url).pathname;

    switch (true) {
      case route.endsWith('/Login') && method === 'POST':
        return responses.ok({
          Success: true,
          Data: {
            UserId: '42',
            IsAdmin: true,
            SessionId: randomString(),
            UserGroups: [],
          },
        });

      case route.endsWith('/GetUserGroups') && method === 'POST':
        logIntercept();

        return responses.ok({ Success: true, Data: [] });

      case route.endsWith('/devices') && method === 'GET':
        logIntercept();

        return responses.ok({
          data: dummyDevices.map<ApiDeviceSummary>(device =>
            Object.assign(device.device, {
              notificationSummary: createAvailableNotificationsSummary(device.notifications || []),
            })
          ),
        });

      case route.match(/^.+\/devices\/[\w\-]+$/) && method === 'GET':
        logIntercept();

        const getDevice = (pathname: string): ApiDeviceDummy | undefined => {
          const deviceId = pathname.match(/^.+\/devices\/([\w\-]+)$/)[1];
          return findDeviceById(deviceId);
        };

        return responses.ok({ data: getDevice(route).device });

      case route.match(/^.+\/devices\/[\w\-]+\/notifications\/current$/) && method === 'GET':
        logIntercept();

        const getDeviceNotifications = (pathname: string) => {
          const deviceId = pathname.match(/^.+\/devices\/([\w\-]+)\/notifications\/current$/)[1];
          return findDeviceById(deviceId)?.notifications || [];
        };

        return responses.ok({ data: getDeviceNotifications(route) });

      case route.match(/^.+\/devices\/[\w\-]+\/files\/[\w\-_]+$/) && method === 'GET':
        logIntercept();

        const getDeviceFiles = (pathname: string) => {
          const match = pathname.match(/^.+\/devices\/([\w\-]+)\/files\/([\w\-_]+)$/);
          if (!match) {
            return;
          }
          const files = findDeviceById(match[1])?.files;
          return !!files ? files[match[2]] : [];
        };

        return responses.ok({ data: getDeviceFiles(route) });

      case route.match(/^.+\/devices\/[\w\-]+\/journal\/items$/) && method === 'GET':
        logIntercept();

        const getJournalItems = (pathname: string) => {
          const deviceId = pathname.match(/^.+\/devices\/([\w\-]+)\/journal\/items$/)[1];
          return findDeviceById(deviceId)?.journalItems || [];
        };

        return responses.ok({ data: getJournalItems(route) });

      default:
        return next.handle(request);
    }
  }
}
