@if (value) {
  @if (value.length > 0) {
    <div class="card-table">
      <div class="row table-header">
        @for (column of columns; track column) {
          <div [ngClass]="column.sizing">
            {{ column.header }}
          </div>
        }
      </div>
      @for (item of value; track item) {
        <div class="row" (click)="rowClicked.emit(item)" [class.hover]="clickable">
          @for (columnInfo of columns; track columnInfo) {
            <div class="cell" [ngClass]="columnInfo.sizing">
              <span class="col-label">{{ columnInfo.header }}</span>
              <ng-container
                [ngTemplateOutlet]="columnInfo.template"
            [ngTemplateOutletContext]="{
              $implicit: columnInfo.column ? item[columnInfo.column] : item
            }">
              </ng-container>
            </div>
          }
        </div>
      }
    </div>
  }
  @if (value.length === 0 && !requestInProgress) {
    <div class="card-body">
      <span class="text-muted">{{ emptyText }}</span>
    </div>
  }
}
@if (requestInProgress) {
  <app-loading-indicator></app-loading-indicator>
}
