import { Component, OnInit } from '@angular/core';
import { CoreLoginService } from '../../service/core-login.service';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  circleUserIcon = faCircleUser;
  userEmail: string | null = null;
  userRole: string | null = null;
  lastLoginTime: string | null = null;
  constructor(private loginService: CoreLoginService) {}

  ngOnInit(): void {
    this.accessLocalStore('userEmail', 'userRole');
  }

  startLogin(): void {
    this.loginService.startLogin();
  }

  startAccountCreation(): void {
    this.loginService.startAccountCreation();
  }

  accessLocalStore(user: string, userRole: string): void {
    this.userEmail = localStorage.getItem(user);
    this.userRole = localStorage.getItem(userRole);
    this.lastLoginTime = localStorage.getItem('lastLoginTime');
  }
}
