import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of, throwError } from 'rxjs';
import { retryWhen, mergeMap, delay, catchError } from 'rxjs/operators';
import { NotificationService, NotificationType } from '../service/notification.service';

@Injectable()
export class HttpErrorResponseInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private translocoService: TranslocoService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen(error =>
        error.pipe(
          mergeMap((error, attempt) => {
            if (attempt < 1 && error.status == 500) {
              return of(error).pipe(delay(100));
            }

            throw error;
          })
        )
      ),
      catchError(error => {
        if (error.status == 503) {
          //handle Service Unavailable
          const notification = {
            type: NotificationType.Error,
            title: this.translocoService.translate('notification-error-service-unavailable-title'),
            text: this.translocoService.translate('notification-error-service-unavailable-text'),
            expiresAt: new Date(new Date().getTime() + 5000),
          };
          this.notificationService.pushNotification(notification);
        }
        return throwError(error);
      })
    );
  }
}
