<ng-container *transloco="let t">
  <div class="font-weight-bold">
    {{ t('common.assigned-users-with-role') }}
  </div>
  <ng-container>
    @for (role of roles; track role) {
      <div class="pt-2">
        <div class="small">
          <div class="pb-1">
            <ng-container
            *ngTemplateOutlet="
              roleLabelTemplate || defaultRoleLabelTemplate;
              context: { $implicit: role }
            "></ng-container>
            @if (getUsersWithRole(role); as userWithRole) {
              ({{ userWithRole.length }})
            }
          </div>
          @for (user of getUsersWithRole(role); track user) {
            <span class="badge badge-primary mr-1 text-left">
              <i class="fa fa-user-circle pr-1" aria-hidden="true"></i>{{ user.email }}
            </span>
          }
        </div>
      </div>
    }
  </ng-container>
</ng-container>

<ng-template #defaultRoleLabelTemplate let-assignmentKey>
  {{ assignmentKey }}
</ng-template>

<ng-template #noUsers *transloco="let t">
  {{ t('no-assignments') }}
</ng-template>
