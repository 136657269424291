export enum Unit {
  Bar = 'BAR',
  Bit = 'BIT',
  Byte = 'BYTE',
  DegreeCelsius = 'DEGREE_CELSIUS',
  LiterPerHour = 'LITER_PER_HOUR',
  LiterPerMinute = 'LITER_PER_MINUTE',
  LiterPerSecond = 'LITER_PER_SECOND',
  Milligram = 'MILLIGRAM',
  PartPerMillion = 'PART_PER_MILLION',
  Percent = 'PERCENT',
  Second = 'SECOND',
  Volt = 'VOLT',
  Watt = 'WATT',
}

export enum UnitType {
  Duration = 'DURATION',
  ElectricPotential = 'ELECTRIC_POTENTIAL',
  Information = 'INFORMATION',
  Mass = 'MASS',
  Power = 'POWER',
  Pressure = 'PRESSURE',
  Ratio = 'RATIO',
  Temperature = 'TEMPERATURE',
  VolumeFlow = 'VOLUME_FLOW',
}

export interface Unitary {
  unit: Unit;
  unitType: UnitType;
}

export interface UnitaryValue extends Unitary {
  value: number;
}

export interface UnitConfiguration extends Unitary {
  digits: number;
}

export const printUnitInformation = (unitInformation?: Unitary): string | undefined => {
  if (!unitInformation) {
    return;
  }
  switch (unitInformation.unitType) {
    case 'DURATION':
      switch (unitInformation.unit) {
        case 'SECOND':
          return 's';
        default:
          return undefined;
      }
    case 'ELECTRIC_POTENTIAL':
      switch (unitInformation.unit) {
        case 'VOLT':
          return 'V';
        default:
          return undefined;
      }
    case 'INFORMATION':
      switch (unitInformation.unit) {
        case 'BIT':
          return 'bit';
        case 'BYTE':
          return 'byte';
        default:
          return undefined;
      }
    case 'MASS':
      switch (unitInformation.unit) {
        case 'MILLIGRAM':
          return 'mg';
        default:
          return undefined;
      }
    case 'PRESSURE':
      switch (unitInformation.unit) {
        case 'BAR':
          return 'bar';
        default:
          return undefined;
      }
    case 'POWER':
      switch (unitInformation.unit) {
        case 'WATT':
          return 'W';
        default:
          return undefined;
      }
    case 'RATIO':
      switch (unitInformation.unit) {
        case 'PERCENT':
          return '%';
        case 'PART_PER_MILLION':
          return 'ppm';
        default:
          return undefined;
      }
    case 'TEMPERATURE':
      switch (unitInformation.unit) {
        case 'DEGREE_CELSIUS':
          return 'C°';
        default:
          return undefined;
      }
    case 'VOLUME_FLOW':
      switch (unitInformation.unit) {
        case 'LITER_PER_SECOND':
          return 'l/s';
        case 'LITER_PER_MINUTE':
          return 'l/m';
        case 'LITER_PER_HOUR':
          return 'l/h';
        default:
          return undefined;
      }
    default:
      return undefined;
  }
};
