import { DeviceConnectionStatusType, DeviceOperationalStatusType, DeviceStatus } from '../../model/device.model';
import ProgressPercentageCalculator from './progress-percentage.calculator';

export interface ProgressAbsolute {
  currentValue: number;
  maximumValue: number;
}

export interface ProgressInformation {
  status: DeviceOperationalStatusType;
  message: string;
  progressPercentage?: number;
  estimatedSecondsRemaining?: number;
  secondsElapsed?: number;
  progressAbsolute?: ProgressAbsolute;
}

export class ProgressInformationCalculator {
  public static calculateProgress(status: DeviceStatus): ProgressInformation | undefined {
    if (!ProgressInformationCalculator.isActiveProgress(status)) {
      return undefined;
    }

    const secondsElapsed = !!status.operational?.progressTemporal?.startedAt
      ? Math.floor(
          Math.max(0, new Date().getTime() - new Date(status.operational.progressTemporal.startedAt).getTime()) / 1000
        )
      : undefined;

    return {
      status: status.operational!.type,
      message: status.operational!.message,
      progressPercentage: ProgressPercentageCalculator.calc(status.operational),
      estimatedSecondsRemaining: status.operational?.progressTemporal?.estimatedSecondsRemaining,
      secondsElapsed: secondsElapsed,
      progressAbsolute: status.operational?.progressAbsolute,
    };
  }

  private static isActiveProgress(status: DeviceStatus): boolean {
    return (
      status.connection.type === DeviceConnectionStatusType.Connected &&
      !!status.operational &&
      (status.operational?.type === DeviceOperationalStatusType.Malfunctional ||
        status.operational?.type === DeviceOperationalStatusType.WaitingForUser ||
        status.operational?.type === DeviceOperationalStatusType.Active)
    );
  }
}
