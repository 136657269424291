<ng-container *transloco="let t">
  @if (device) {
    <p class="navigation-list-header pt-4">{{ t('product-information-side-navigation-header') }}</p>
    <div class="navigation-list">
      <app-navigation-item
        title="{{ t('product-information-files') }}"
        iconName="icon-files"
        [routerLink]="['..', device.data.id, 'files']"
        routerLinkActive="active"
        tabindex="-1">
      </app-navigation-item>
    </div>
  } @else {
    <app-loading-indicator></app-loading-indicator>
  }

</ng-container>
