import { Injectable, signal } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  DeviceNotificationApiService,
  DeviceNotificationsCollectionResult,
} from '../api/device-notifications-api.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceNotificationsService {
  constructor(private apiService: DeviceNotificationApiService) {}

  #deviceNotifications = signal<DeviceNotificationsCollectionResult | undefined>(undefined);
  deviceNotifications = this.#deviceNotifications.asReadonly();

  getAllByDeviceId(deviceId: string): Observable<void> {
    return this.apiService
      .getDeviceNotificationsWithMetadata(deviceId)
      .pipe(map(result => this.#deviceNotifications.set(result)));
  }

  reset() {
    this.#deviceNotifications.set(undefined);
  }
}
