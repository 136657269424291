import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  faAnglesLeft,
  faAnglesRight,
  faBuildingUser,
  faCircleInfo,
  faHome,
  faLayerGroup,
  faMicroscope,
  faPowerOff,
  faQrcode,
  faServer,
  faUserGear,
} from '@fortawesome/free-solid-svg-icons';
import { BackendPermissionsService } from 'src/app/access-rights/backend-permissions.service';
import { BarcodeScannerService } from 'src/app/core/service/bar-scanner.service';
import { CoreLoginService } from 'src/app/core/service/core-login.service';
import { Capacitor } from '@capacitor/core';
import { CoreNavigationService } from 'src/app/core/service/core-navigation.service';
import { ToggleSidebarService } from 'src/app/shared/service/toggle-sidebar.service';
import { Subscriptions } from 'src/app/shared/util/Subscriptions';

@Component({
  selector: 'app-desktop-navigation',
  templateUrl: './desktop-navigation.component.html',
  styleUrls: ['../navigation.component.scss'],
})
export class DesktopNavigationComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscriptions();

  permissions$ = this.permissionService.index$;
  user$ = this.loginService.currentUser;
  // *  icons
  qrCodeIcon = faQrcode;
  tenantIcon = faBuildingUser;
  labsIcon = faMicroscope;
  infoHubIcon = faCircleInfo;
  userSettingsIcon = faUserGear;
  iotAdminIcon = faServer;
  deviceListIcon = faLayerGroup;
  logoutIcon = faPowerOff;
  anglesRight = faAnglesRight;
  anglesLeft = faAnglesLeft;
  homeIcon = faHome;

  isOpen: boolean = false;
  isIframe: boolean = false;
  hasSideNavigation: boolean = false;

  scanActive = false;
  canScan = false;

  constructor(
    private loginService: CoreLoginService,
    private barcodeScannerService: BarcodeScannerService,
    private navigationService: CoreNavigationService,
    private toggleSidebarService: ToggleSidebarService,
    private permissionService: BackendPermissionsService
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.subscriptions.put(
      this.barcodeScannerService.isScanning$.subscribe((isScanning: boolean) => {
        this.scanActive = isScanning;
        const canScan = !isScanning && this.barcodeScannerService.supportsScanner();
        if (canScan) {
          this.barcodeScannerService.prepare();
        }
        this.canScan = canScan;
      })
    ),
      this.subscriptions.put(
        this.navigationService.urlAfterRedirects$.subscribe(url => {
          if (this.toggleSidebarService.hasSideNavigation(url)) {
            this.hasSideNavigation = false;
            this.isOpen = true;
          } else {
            this.hasSideNavigation = true;
            this.isOpen = false;
          }
        })
      );
  }

  async startScanner() {
    await this.barcodeScannerService.startScanner();
  }

  async stopScanner(): Promise<void> {
    await this.barcodeScannerService.stopScanner();
  }

  supportsScanner(): boolean {
    return Capacitor.isNativePlatform();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  onOpenSidebar() {
    this.isOpen = !this.isOpen;
  }

  login(): void {
    this.loginService.startLogin();
  }

  logout(): void {
    this.loginService.logout();
  }
}
