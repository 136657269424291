import { Component, Input, OnInit } from '@angular/core';
import { DeviceNotification } from 'src/app/device/device-shared/model/device.model';
import { DeviceNotificationsService } from '../../service/device-notifications.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-device-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  @Input() deviceId: string = '';

  notifications$?: Observable<DeviceNotification[] | undefined>;

  constructor(private deviceNotificationService: DeviceNotificationsService) {}

  ngOnInit(): void {
    this.notifications$ = this.deviceNotificationService.getAllByDeviceId(this.deviceId).pipe(
      map(() => {
        return this.deviceNotificationService.deviceNotifications()?.collection.map(notification => notification.data);
      })
    );
  }
}
