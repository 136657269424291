import { Injectable, NgZone } from '@angular/core';
import { App } from '@capacitor/app';
import { BarcodeScannerService } from 'src/app/core/service/bar-scanner.service';
import { CoreNavigationService } from './core-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class MobileEventsService {
  constructor(
    private navigationService: CoreNavigationService,
    private ngZone: NgZone,
    private barcodeScannerService: BarcodeScannerService
  ) {}

  initEventHandlers(): void {
    App.addListener('backButton', () => {
      this.ngZone.run(() => {
        if (this.barcodeScannerService.isScanning$.getValue()) {
          this.barcodeScannerService.stopScanner();
        } else {
          this.navigationService.previous();
        }
      });
    });
  }
}
