import { Injectable } from '@angular/core';
import { DeviceVendor, DeviceVendor2LabelMapping } from '../model/device.model';
import { Option } from '../../../shared/component/multiselect/multiselect.component';

@Injectable({
  providedIn: 'root',
})
export class DeviceVendorsService {
  vendors = Object.values(DeviceVendor);

  asOptions(): Option[] {
    return this.vendors.map(vendor => {
      return {
        value: vendor,
        label: DeviceVendor2LabelMapping[vendor],
      };
    });
  }
}
