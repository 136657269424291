<ng-container *transloco="let t">
  @if (scanActive) {
    <app-qr-scanner-overlay class="theme-context"></app-qr-scanner-overlay>
  }
  <div
    class="theme-context"
    appThemeToggle
    [style.--background]="scanActive ? '#00000000' : '#ffffff'"
    [style.display]="scanActive ? 'none' : 'block'">
    <app-check-visibility></app-check-visibility>
    <div class="app-content">
      <nav class="vendor-bar"></nav>

      @if (herf === '/login' || herf === '/logout-success') {
        <a class="sidebar-logo">
          <app-vendor-logo></app-vendor-logo>
        </a>
        <div class="navbar-left-logo">
          <div class="navbar-left-background"></div>
        </div>
      }

      @if (user) {
        <div class="navbar-right-controls">
          <div class="navbar-right-background"></div>
          <a class="navbar-toggle" (click)="changeSideBarOpen()">
            <div class="hamburger-icon" [class.active]="sidebarOpen"></div>
          </a>
        </div>
      }

      @if (user) {
        <aside
          class="sidebar"
          [ngClass]="{ 'sidebar-open': sidebarOpen }"
          [style.--background]="scanActive ? '#00000000' : '#ffffff'"
          >
          <app-navigation [user]="user"></app-navigation>
        </aside>
      }

      <div class="main-content-wrapper">
        <main class="main-content">
          <app-route-loading-indicator></app-route-loading-indicator>
          @if (!isIframe && (routingInProgress$ | async) === false) {
            <div class="container-fluid px-0 mx-0 my-9">
              <router-outlet></router-outlet>
            </div>
          }

          <app-notification-area></app-notification-area>
          @if (user) {
            <app-bottom-nav></app-bottom-nav>
          }

          @if ((routingInProgress$ | async) === false) {
            <app-footer class="mt-auto"></app-footer>
          }
        </main>
      </div>
    </div>
    <div appModalContainer></div>
  </div>
</ng-container>
