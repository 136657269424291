<ng-container *transloco="let t">
  <div class="d-flex input-wrapper flex-wrap" [class.disabled]="disabled">
    @for (tag of tags; track tag; let isLast = $last) {
      <span
        #badge
        class="input-badge badge badge-primary px-0"
        [class.is-last]="isLast"
        tabindex="0"
        (keydown.backspace)="removeTag(tag)">
        <div class="d-flex" [ngbTooltip]="tag" placement="top">
          <div class="badge-container-content text-truncate">{{ tag }}</div>
          <div class="badge-container-close" (click)="handleClose(tag)">
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>
      </span>
    }
    <input
      type="text"
      [size]="size"
      [placeholder]="t(placeholderKey)"
      autocomplete="none"
      class="d-inline w-auto flex-grow-1 input-field form-control"
      id="{{ id }}"
      (keydown.space)="handleKeyDown($event, 'space')"
      (keydown.enter)="handleKeyDown($event, 'enter')"
      (keydown.backspace)="handleKeyDown($event, 'backspace')"
      (blur)="handleBlur()"
      [disabled]="disabled"
      #inputField />
  </div>
</ng-container>
