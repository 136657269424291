import { DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { msalConfig, msalGuardConfig, msalInterceptorConfig, routerConfig } from './app.config';
import { appRoutes } from './app.routes';
import { AppCoreModule } from './core/core.module';
import { GlobalErrorHandlerService } from './core/error-handling/global-error-handler.service';
import { HttpErrorResponseInterceptor } from './core/error-handling/http-error-response.interceptor';
import { FunctionsKeyHeaderInterceptor } from './core/interceptor/functions-key-header.interceptor';
import { LanguageHeaderInterceptor } from './core/interceptor/language-header.interceptor';
import { MockBackendApiInterceptor } from './core/mock/mock-backend-api.interceptor';
import { DeviceFilesModule } from './device/device-files/device-files.module';
import { SharedModule } from './shared/shared.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    MsalModule.forRoot(msalConfig, msalGuardConfig, msalInterceptorConfig),
    SharedModule,
    AppCoreModule,
    DeviceFilesModule,
  ],
  providers: [
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DecimalPipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FunctionsKeyHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageHeaderInterceptor,
      multi: true,
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-MGXR489' },
  ].concat(
    environment.mockBackendApi
      ? {
          provide: HTTP_INTERCEPTORS,
          useClass: MockBackendApiInterceptor,
          multi: true,
        }
      : []
  ),
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
