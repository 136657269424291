<ng-container *transloco="let t">
  <app-dialog [title]="title" [contentTemplate]="content" [type]="type"> </app-dialog>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">
      {{ t('close') }}
    </button>
  </div>

  <ng-template #content>
    <p class="w-90">{{ t(body) }}</p>
  </ng-template>
</ng-container>
