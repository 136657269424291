import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '../shared/shared.module';
import { AccordionCardComponent } from './product-information-shared/component/product-information-files/accordion-card/accordion-card.component';
import { ProductInformationFilesFilterComponent } from './product-information-shared/component/product-information-files/product-information-files-filter.component';
import { ProductInformationFilesListingComponent } from './product-information-shared/component/product-information-files/product-information-files-listing.component';
import { ProductInformationFilesComponent } from './product-information-shared/component/product-information-files/product-information-files.component';
import {
  NgbAccordionBody,
  NgbAccordionCollapse,
  NgbAccordionDirective,
  NgbAccordionHeader,
  NgbAccordionItem, NgbAccordionToggle
} from '@ng-bootstrap/ng-bootstrap';
import { RadioDropdownComponent } from '../shared/component/radio-dropdown/radio-dropdown.component';

@NgModule({
  declarations: [
    AccordionCardComponent,
    ProductInformationFilesComponent,
    ProductInformationFilesFilterComponent,
    ProductInformationFilesListingComponent,
  ],
  exports: [ProductInformationFilesComponent],
  imports: [
    SharedModule,
    CommonModule,
    TranslocoModule,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionCollapse,
    NgbAccordionBody,
    NgbAccordionToggle,
    RadioDropdownComponent
  ]
})
export class ProductInformationSharedModule {}
