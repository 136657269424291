<ng-container *transloco="let t">
  <div class="card h-100">
    <div class="card-header">
      <i class="icon icon-versions-card"></i>
      <h5 class="card-title">{{ t('firmware') }}</h5>
    </div>
    @if (firmwareData(); as data) {
      <div class="tuple-list">
        @for (firmware of data.firmwares; track firmware) {
          <div class="tuple-list-item">
            <div class="tuple-list-key">
              {{ firmware.key }}
            </div>
            <div class="tuple-list-value">
              {{ firmware.version }}
              @if (areFirmwareUpdatesAvailable$(firmware.key) | async) {
                <i
                  class="fa fa-arrow-circle-up text-success ml-1"
                ></i>
              }
              @if (areFirmwareUpdatesAvailable$(firmware.key) | async) {
                <button class="btn btn-vendor ml-2" (click)="updateSoftware(firmware)">
                  @if (!actionExcecution) {
                    <i class="fa fa-download"></i>
                  }
                  @if (actionExcecution) {
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">{{ t('loading') }}</span>
                    </div>
                  }
                </button>
              }
            </div>
          </div>
        }
        @if (actionSuccessResponse) {
          <div class="text-success py-2 text-left pl-2 small">
            {{ t('action-sucessfully-sent') }}
          </div>
        }
        @if (actionErrorResponse) {
          <div class="text-danger py-2 text-left pl-2 small">
            {{ t('action-could-not-be-executed') }}
          </div>
        }
      </div>
    } @else {
      <div class="card-body">
        <app-loading-indicator></app-loading-indicator>
      </div>
    }
  </div>
</ng-container>

