import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Collection,
  CollectionItem,
  createResourceEmbeddedCollection,
  GetCollectionResponse,
  preparePermissions,
} from '../../access-rights/permissions.utils';
import { handleError } from '../../shared/util/http-utils';
import { VirtualLaboratory, VirtualLaboratoryResponse } from '../model/virtual-laboratory.model';
import { VirtualLaboratoryCollectionPermissions, VirtualLaboratoryPermissions } from './user-permissions.models';
import { httpSuccessResponseOperator } from '../../shared/operator/http-success-response.operator';
import { httpErrorResponseOperator } from '../../shared/operator/http-error-response.operator';

const BASE = `${environment.backendApi.shared.uri}/virtual-laboratories`;

const qatmMassBackupUrl = `${environment.backendApi.qatm.uri}/devices/mass-backup-requests`;
const qatmUpdateSoftwareUrl = `${environment.backendApi.qatm.uri}/devices/mass-update-requests`;

export type VirtualLaboratoryResult = CollectionItem<VirtualLaboratory, VirtualLaboratoryPermissions>;

export type VirtualLaboratoryCollectionResult = Collection<
  VirtualLaboratory,
  VirtualLaboratoryCollectionPermissions,
  VirtualLaboratoryPermissions
>;

export interface IVirtualLaboratoryMassActionsResponse {
  Success: boolean;
  Key: string;
  Param?: string;
}

export interface MassUpdateSoftwareRequest {
  VirtualLaboratoriesId: string[],
  Url?: string,
  FirmwareId?: string,
  FirmwareVersionId?: string,
}

@Injectable({
  providedIn: 'root',
})
export class VirtualLaboratoryApiService {
  constructor(private http: HttpClient) {}

  private findAll(): Observable<VirtualLaboratoryCollectionResult> {
    return this.http.get<GetCollectionResponse<VirtualLaboratory>>(`${BASE}`).pipe(
      map(response => {
        return {
          collection: createResourceEmbeddedCollection<VirtualLaboratory, VirtualLaboratoryPermissions>(
            response?._embedded
          ),
          permissions: preparePermissions<VirtualLaboratoryCollectionPermissions>(response._links),
        };
      }),
      catchError(handleError)
    );
  }

  getAll(): Observable<VirtualLaboratoryCollectionResult> {
    return this.findAll();
  }

  findAllByTenant(tenantId: string): Observable<VirtualLaboratoryCollectionResult> {
    return this.findAll().pipe(
      map(result => {
        result.collection = result.collection.filter(item => {
          return item.data.tenant.id === tenantId;
        });
        return result;
      })
    );
  }

  get(virtualLaboratoryId: string): Observable<VirtualLaboratoryResult | undefined> {
    return this.http.get<VirtualLaboratoryResponse>(`${BASE}/${virtualLaboratoryId}`).pipe(
      map(response => {
        return {
          data: response,
          permissions: preparePermissions<VirtualLaboratoryPermissions>(response?._links),
        };
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          const httpErrorResponse: HttpErrorResponse = error;
          switch (httpErrorResponse.status) {
            case 404:
              return of(undefined);
            default:
              throw error;
          }
        }
        throw error;
      })
    );
  }

  findByTenantId(tenantId: string): Observable<VirtualLaboratoryCollectionResult> {
    return this.http.get<GetCollectionResponse<VirtualLaboratory>>(`${BASE}?tenantId=${tenantId}`).pipe(
      map(response => {
        return {
          permissions: preparePermissions<VirtualLaboratoryCollectionPermissions>(response._links),
          collection: createResourceEmbeddedCollection<VirtualLaboratory, VirtualLaboratoryPermissions>(
            response?._embedded
          ),
        };
      }),
      catchError(handleError)
    );
  }

  create(tenantId: string, name: string, description: string): Observable<any> {
    const body = {
      tenantId: tenantId,
      name: name,
      description: description,
    };

    return this.http.post(BASE, body);
  }

  update(virtualLaboratoryId: string, name: string, description: string): Observable<any> {
    const body = {
      name,
      description,
    };

    return this.http.put(`${BASE}/${virtualLaboratoryId}`, body);
  }

  delete(virtualLaboratoryId: string): Observable<any> {
    return this.http.delete(`${BASE}/${virtualLaboratoryId}`);
  }

  fetchAccessKey(virtualLaboratoryId: string): Observable<{ accessKey: string }> {
    return this.http.get<{ accessKey: string }>(`${BASE}/${virtualLaboratoryId}/access-key`);
  }

  regenerateAccessKey(virtualLaboratoryId: string): Observable<{ accessKey: string }> {
    const body = {};
    return this.http.post<{ accessKey: string }>(`${BASE}/${virtualLaboratoryId}/access-key/regenerations`, body);
  }
  massBackup(virtualLaboratories: string[]): Observable<IVirtualLaboratoryMassActionsResponse> {
    return this.http.post<any>(qatmMassBackupUrl, {VirtualLaboratoriesId: virtualLaboratories})
      .pipe(httpSuccessResponseOperator, httpErrorResponseOperator);
  }

  updateSoftware(massUpdateSoftwareRequest: MassUpdateSoftwareRequest): Observable<IVirtualLaboratoryMassActionsResponse> {
    return this.http.post<any>(qatmUpdateSoftwareUrl, massUpdateSoftwareRequest)
      .pipe(httpSuccessResponseOperator, httpErrorResponseOperator);
  }
}
