<div class="container">
  <div class="row">
    <div class="mx-5 my-5">
      <h2 class="title">{{ 'file-download-starting' | transloco }}</h2>
      <p>{{ 'this-may-take-a-few-seconds' | transloco }}</p>
      @if (fileUri) {
        <a href="{{ fileUri }}" download>
          {{ 'click-here-if-download-doesnt-start-automatically' | transloco }}
        </a>
      }
      @if (linkExpired) {
        <p>
          {{
          'the-link-expired-please-go-back-to-the-application-and-select-the-file-to-download-again-you-can-close-the-window-now'
          | transloco
          }}
        </p>
      }
    </div>
  </div>
</div>
