import { AbstractControl } from '@angular/forms';

/**
 * Checks if input is invalid and if the user has touched or change the value pf the AbstractControl
 * @param input
 */
export function hasInvalidUserInput(input: AbstractControl | null): boolean {
  if (!input) {
    return true;
  }
  return !(input.invalid && (input.dirty || input.touched));
}
