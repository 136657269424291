<div class="card h-100">
  <div class="card-header">
    <i class="icon {{ iconName }}"></i>
    <h2 class="card-title">{{ title }}</h2>
  </div>
  @if (keyValuePairs) {
    @if (keyValuePairs.length > 0) {
      <div class="tuple-list">
        @for (pair of keyValuePairs; track pair) {
          <div class="tuple-list-item">
            <div class="tuple-list-key">{{ pair.name }}</div>
            @if (isString(pair.value)) {
              <div class="tuple-list-value">
                @if (!isColorCode(pair.value)) {
                  <span>{{ pair.value }}</span>
                }
                @if (isColorCode(pair.value)) {
                  <span
                    class="color-box"
                  [style.background-color]="pair.value | ARGB2RGB"></span>
                }
              </div>
            }
            @if (isFormattedNumber(pair.value)) {
              <div class="tuple-list-value">
                {{ pair.value.value | translocoDecimal : pair.value.numberFormatOptions : (locale$ | async) || '' }}
              </div>
            }
            @if (isNumber(pair.value)) {
              <div class="tuple-list-value">
                {{ pair.value }}
              </div>
            }
            @if (isBoolean(pair.value)) {
              <div class="tuple-list-value">
                <app-checkbox-display [value]="pair.value"></app-checkbox-display>
              </div>
            }
            @if (isFormattedNumericUnitaryValue(pair.value)) {
              <div class="tuple-list-value">
                <app-unitary-value [value]="pair.value"></app-unitary-value>
              </div>
            }
            @if (!isValidValue(pair.value)) {
              <div class="tuple-list-value">
                {{ 'remove-symbol' | transloco }}
              </div>
            }
          </div>
        }
      </div>
    } @else {
      <div class="text-center p-5 m-0">N/A</div>
    }
  }
</div>

