import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export function mapIfDefined<T, R>(
  project: (value: NonNullable<T>, index: number) => R,
  thisArg?: any
): OperatorFunction<T, R | undefined> {
  return function (source: Observable<T>): Observable<R | undefined> {
    return source.pipe(
      map((val: T, index: number) => {
        if (val) {
          return project(val as NonNullable<T>, index);
        }
      }, thisArg)
    );
  };
}
