import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class LanguageHeaderInterceptor implements HttpInterceptor {
  constructor(private translationSrv: TranslocoService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newRequest = request;
    if (this.isBackendApiCall(request.url)) {
      newRequest = request.clone({
        headers: request.headers.set('Accept-Language', this.translationSrv.getActiveLang()),
      });
    }

    return next.handle(newRequest);
  }

  isBackendApiCall(url: string): boolean {
    return (
      url.startsWith(environment.backendApi.shared.uri) ||
      url.startsWith(environment.backendApi.carbolite.uri) ||
      url.startsWith(environment.backendApi.eltra.uri) ||
      url.startsWith(environment.backendApi.microtrac.uri) ||
      url.startsWith(environment.backendApi.qatm.uri) ||
      url.startsWith(environment.backendApi.retsch.uri)
    );
  }
}
