<div class="stacked-bar">
  <div class="stacked-bar-data w-100 d-flex">
    @for (data of filteredStackedBarData; track data.label) {
      @if (data.value > 0) {
        <div
          class="stacked-bar-data__chunk h-100 d-flex justify-content-end align-items-center pr-2 "
          [ngbTooltip]="data.label"
          [ngStyle]="{
          'width': data.percentage + '%',
          'background-color': data.backgroundColor,
          'color': data.color || '#000',
          'z-index': data.zIndex
        }">{{ data.label }}</div>
      }
    }
  </div>

  <div class="mt-4 d-flex flex-wrap">
    @for (data of stackedBarData; track data.label) {
      <div class="legend d-flex justify-content-left align-items-center" (click)="toggleDisabledState(data)">
        <span
          class="legend__color d-inline-block mr-4"
          [ngStyle]="{ 'background-color': data.backgroundColor }">
        </span>
        <span
          class="legend__title"
          [ngStyle]="{ 'text-decoration': excludedData.includes(data.legendTitle) ? 'line-through' : null }"
        >{{ data.legendTitle }}</span>
      </div>
    }
  </div>
</div>
