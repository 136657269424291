<div class="d-flex align-items-center">
  <label class="custom-radio-container">
    <input
      type="radio"
      [name]="name"
      [value]="value"
      [checked]="checked"
      [disabled]="disabled"
      (change)="onInputChange()"
    />
    <span class="custom-radio-button"></span>
    {{ label }}
  </label>
</div>
