import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { NumberFormatOptions } from '@ngneat/transloco-locale';
import { Observable } from 'rxjs';
import { FormattedUnitaryNumericValue as FormattedUnitaryValue } from '../../model/formatted-unitary-value.model';
import { Unitary, UnitaryValue } from '../../model/unitary-value';

@Component({
  selector: 'app-unitary-value',
  templateUrl: './unitary-value.component.html',
  styleUrls: ['./unitary-value.component.scss'],
})
export class UnitaryValueComponent implements OnInit {
  @Input() value?: UnitaryValue | FormattedUnitaryValue;

  numberValue?: number;
  numberFormatOptions?: NumberFormatOptions;
  unitInformation?: Unitary;

  locale$?: Observable<string>;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    const unitaryValue = this.value;

    if (typeof unitaryValue?.value === 'object') {
      this.numberValue = unitaryValue?.value.value;
      this.numberFormatOptions = unitaryValue?.value.numberFormatOptions;
    } else if (typeof unitaryValue?.value === 'number') {
      this.numberValue = unitaryValue?.value;
    }

    this.unitInformation = unitaryValue;

    this.locale$ = this.translocoService.langChanges$;
  }
}
