<ng-container *transloco="let t">
  @if (isMobile) {
    @if (permissions$ | async; as menuPermissions) {
      <div class="global-scoped-nav__mobile">
        <a [routerLink]="['/']" class="global-menu-item d-flex align-items-center">
          <fa-icon [icon]="homeIcon" class="global-menu-item-icon"></fa-icon>
          <p class="global-menu-item-label">
            {{ t('home.nav-title') }}
          </p>
        </a>
        @if (!(user$ | async)?.deleted) {
          @if (menuPermissions.tenants) {
            <a
              [routerLink]="['/tenants']"
              routerLinkActive="active"
              class="global-menu-item d-flex align-items-center"
            >
              <fa-icon [icon]="tenantIcon" class="global-menu-item-icon"></fa-icon>
              <p class="global-menu-item-label">
                {{ t('tenant.overview.nav-title') }}
              </p>
            </a>
          }
          @if (menuPermissions.virtual_laboratories) {
            <a
              [routerLink]="['/virtual-laboratories']"
              routerLinkActive="active"
              class="d-flex global-menu-item align-items-center">
              <fa-icon [icon]="labsIcon" class="global-menu-item-icon"></fa-icon>
              <p class="global-menu-item-label">
                {{ t('virtual-laboratory.overview.nav-title') }}
              </p>
            </a>
          }
          @if (menuPermissions.devices) {
            <a
              [routerLink]="['/devices']"
              routerLinkActive="active"
              class="d-flex global-menu-item align-items-center"
            >
              <fa-icon [icon]="deviceListIcon" class="global-menu-item-icon"></fa-icon>
              <p class="global-menu-item-label">
                {{ t('device.overview.nav-title') }}
              </p>
            </a>
          }
          @if (menuPermissions.device_firmwares) {
            <a
              [routerLink]="['/administration']"
              routerLinkActive="active"
              class="d-flex global-menu-item align-items-center">
              <fa-icon [icon]="iotAdminIcon" class="global-menu-item-icon"></fa-icon>
              <p class="global-menu-item-label">
                {{ t('iot-administration') }}
              </p>
            </a>
          }
        }
      </div>
    }
  }
</ng-container>
