<ng-container *transloco="let t">
  @if (flags) {
    <div>
      @if (flags.isActive) {
        <span class="status-badge online" [class.full-width]="fullWidth" [class.w-auto]="autoWidth">{{ t('online') }}</span>
      }
      @if (flags.isIdle) {
        <span class="status-badge idle" [class.full-width]="fullWidth" [class.w-auto]="autoWidth">{{ t('idle') }}</span>
      }
      @if (flags.isInitializing) {
        <span class="status-badge initializing" [class.full-width]="fullWidth" [class.w-auto]="autoWidth">{{
          t('initializing')
        }}</span>
      }
      @if (flags.isWaiting) {
        <span class="status-badge info" [class.full-width]="fullWidth" [class.w-auto]="autoWidth">{{ t('waiting') }}</span>
      }
      @if (flags.isError) {
        <span class="status-badge malfunctional" [class.full-width]="fullWidth" [class.w-auto]="autoWidth">{{
          t('error')
        }}</span>
      }
      @if (flags.isNotActive) {
        <span class="status-badge info" [class.full-width]="fullWidth" [class.w-auto]="autoWidth">{{ t('inactive') }}</span>
      }
      @if (flags.isOffline) {
        <span class="status-badge offline" [class.full-width]="fullWidth" [class.w-auto]="autoWidth">{{ t('offline') }}</span>
      }
    </div>
  } @else {
    <span class="unkown">{{ t('unknown') }}</span>
  }
</ng-container>
