import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/model/core-api-response';
import { environment } from 'src/environments/environment';
import { DeviceNotification } from '../model/device.model';
import { ApiDeviceNotification } from '../model/api-device.model';
import { CanReadSelf } from '../../../access-rights/permissions/permission-models';
import {
  Collection,
  CollectionItem,
  createResourceEmbeddedCollection,
  emptyPermissions,
  GetCollectionResponse,
} from '../../../access-rights/permissions.utils';

export interface DeviceNotificationsCollectionPermissions extends CanReadSelf {}
export interface DeviceNotificationPermission extends CanReadSelf {}
export type DeviceNotificationsCollectionResult = Collection<
  DeviceNotification,
  DeviceNotificationsCollectionPermissions,
  DeviceNotificationPermission
>;
export type DeviceNotificationResult = CollectionItem<DeviceNotification, DeviceNotificationPermission>;

/*
function mapNotification(notification: ApiDeviceNotification) {
    return {
      id: notification.id,
      level: notification.level,
      priority: notification.priority,
      summary: notification.summary,
      text: notification.text,
      reportedAt: notification.reportedAt,
    } as DeviceNotification
}
 */

function hateoasCompatibilityLayer(response: ApiResponse<ApiDeviceNotification[]>): GetCollectionResponse<any> {
  const result = {
    _embedded: {
      collection: response.data?.map(notification => {
        return {
          ...notification,
          _links: {},
        };
      }),
    },
    _links: {},
  };
  return result;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceNotificationApiService {
  constructor(private http: HttpClient) {}

  getDeviceNotificationsWithMetadata(deviceId: string): Observable<DeviceNotificationsCollectionResult> {
    return timer(0, 5000).pipe(
      switchMap(() =>
        this.http
          .get<ApiResponse<ApiDeviceNotification[]>>(
            `${environment.backendApi.shared.uri}/devices/${deviceId}/notifications/current`
          )
          .pipe(map(hateoasCompatibilityLayer))
          .pipe(
            map(mapped => {
              return {
                collection: createResourceEmbeddedCollection<DeviceNotification, DeviceNotificationPermission>(
                  mapped?._embedded
                ),
                permissions: emptyPermissions<DeviceNotificationsCollectionPermissions>(),
              };
            })
            // TODO: [as] catchError(handleError)
          )
      )
    );
  }

  getDeviceNotificationsArray(deviceId: string): Observable<DeviceNotification[]> {
    return this.http
      .get<ApiResponse<ApiDeviceNotification[]>>(
        `${environment.backendApi.shared.uri}/devices/${deviceId}/notifications/current`
      )
      .pipe(
        map(response =>
          response.data.map(notification => {
            return {
              id: notification.id,
              level: notification.level,
              priority: notification.priority,
              summary: notification.summary,
              text: notification.text,
              reportedAt: notification.reportedAt,
            } as DeviceNotification;
          })
        )
      );
  }
}
