import { Component, Input, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Observable, of } from 'rxjs';
import { ButtonHandler } from '../card-header/card-header';

export type LongRunningTask = () => Promise<void>;

export enum ButtonType {
  VENDOR = 'btn-vendor',
  VENDOR_OUTLINE = 'btn-outline-vendor',
  PRIMARY = 'btn-primary',
  PRIMARY_LIGHT = 'btn-primary-light',
  PRIMARY_OUTLINE = 'btn-outline-primary',
  SECONDARY = 'btn-secondary',
  SUCCESS = 'btn-success',
  MODAL_SUCCESS = 'btn-modal-success',
  DANGER = 'btn-danger',
  WARNING = 'btn-warning',
  INFO = 'btn-info',
  LIGHT = 'btn-light',
  DARK = 'btn-dark',
  LINK = 'btn-link',
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
  @Input() isWorkInProgress?: boolean;
  @Input() buttonClass: string = '';
  @Input() title?: string = '';
  @Input() icon?: IconDefinition;
  @Input() handler: LongRunningTask | ButtonHandler = async () => {};
  @Input() disabled!: boolean;

  ngOnInit(): void {
    if (!this.icon && !this.title) {
      this.title = 'Cancel';
    }
  }

  get isDisabled(): Observable<boolean> {
    return of(this.disabled || this.isWorkInProgress || false);
  }

  async mousedown(event: Event): Promise<void> {
    event.stopPropagation();
    event.preventDefault();
    await this.handler();
  }

  stopIt(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }
}
