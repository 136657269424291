import { Component, Input, OnInit } from '@angular/core';
import { DeviceNotificationLevel } from 'src/app/device/device-shared/model/device.model';

@Component({
  selector: 'app-device-notification-badge',
  templateUrl: './notification-badge.component.html',
  styleUrls: ['./notification-badge.component.scss'],
})
export class NotificationBadgeComponent implements OnInit {
  @Input() level?: DeviceNotificationLevel;
  @Input() count?: number;

  className!: string;

  ngOnInit(): void {
    this.className = this.level?.toLowerCase() ?? 'disabled';
  }
}
