import { BehaviorSubject, Observable } from 'rxjs';

export class StateBehaviour<T> {
  private updates$: BehaviorSubject<T>;

  constructor(defaultVal: T) {
    this.updates$ = new BehaviorSubject<T>(defaultVal);
  }

  set value(val: T) {
    this.updates$.next(val);
  }

  get value(): T {
    return this.updates$.getValue();
  }

  public getUpdates(): Observable<T> {
    return this.updates$;
  }

  unsubscribe(): void {
    this.updates$.unsubscribe();
  }
}
