import { Pipe, PipeTransform } from '@angular/core';
import { DeviceModelReference } from '../../device/device-shared/model/device.model';

const deviceImagesById: { [key: string]: string } = {
  //Eltra
  CSd: 'eltra_CSd',
  CSi: 'eltra_CSi',
  CSr: 'eltra_CSr',
  ONHp1: 'eltra_ONHp2',
  ONHp2: 'eltra_ONHp2',

  //QATM
  Qness_250_M: 'qatm_qness_250_M',
  Qness_250_E: 'qatm_qness_250_E',
  Qness_250_MS: 'qatm_qness_250_MS',
  Qness_750_M: 'qatm_qness_750_M',
  Qness_750_E: 'qatm_qness_750_E',
  Qness_750_MS: 'qatm_qness_750_MS',
  Qness_3000_M: 'qatm_qness_3000_M',
  Qness_3000_E: 'qatm_qness_3000_E',
  Qness_3000_MS: 'qatm_qness_3000_MS',
  Qness_250_E_EVO: 'qatm_qness_250_E_EVO',
  Qness_250_M_EVO: 'qatm_qness_250_M_EVO',
  Qness_750_E_EVO: 'qatm_qness_750_E_EVO',
  Qness_750_M_EVO: 'qatm_qness_750_E_EVO',
  Qness_3000_E_EVO: 'qatm_qness_3000_E_EVO',
  Qness_3000_M_EVO: 'qatm_qness_3000_M_EVO',
  Qness_250_C_EVO: 'qatm_qness_250_C_EVO',
  Qness_250_CS_EVO: 'qatm_qness_250_CS_EVO',
  Qness_750_C_EVO: 'qatm_qness_750_C_EVO',
  Qness_750_CS_EVO: 'qatm_qness_750_CS_EVO',
  Qness_3000_C_EVO: 'qatm_qness_3000_C_EVO',
  Qness_3000_CS_EVO: 'qatm_qness_3000_CS_EVO',
  Qness_250_C: 'qatm_qness_250_C',
  Qness_250_CA: 'qatm_qness_250_CA',
  Qness_250_CAPLUS: 'qatm_qness_250_CAPLUS',
  Qness_250_CS: 'qatm_qness_250_CS',
  Qness_750_C: 'qatm_qness_750_C',
  Qness_750_CA: 'qatm_qness_750_CA',
  Qness_750_CAPLUS: 'qatm_qness_750_CAPLUS',
  Qness_750_CS: 'qatm_qness_750_CS',
  Qness_3000_C: 'qatm_qness_3000_C',
  Qness_3000_CA: 'qatm_qness_3000_CA',
  Qness_3000_CAPLUS: 'qatm_qness_3000_CAPLUS',
  Qness_3000_CS: 'qatm_qness_3000_CS',
  Qness_250_CA_EVO: 'qatm_qness_250_CA_EVO',
  Qness_250_CAPLUS_EVO: 'qatm_qness_250_CAPLUS_EVO',
  Qness_750_CA_EVO: 'qatm_qness_750_CA_EVO',
  Qness_750_CAPLUS_EVO: 'qatm_qness_750_CAPLUS_EVO',
  Qness_3000_CA_EVO: 'qatm_qness_3000_CA_EVO',
  Qness_3000_CAPLUS_EVO: 'qatm_qness_3000_CAPLUS_EVO',
  Qness_250_A_EVO: 'qatm_qness_250_A_EVO',
  Qness_250_APLUS_EVO: 'qatm_qness_250_APLUS_EVO',
  Qness_750_A_EVO: 'qatm_qness_750_A_EVO',
  Qness_750_APLUS_EVO: 'qatm_qness_750_APLUS_EVO',
  Qness_3000_A_EVO: 'qatm_qness_3000_A_EVO',
  Qness_3000_APLUS_EVO: 'qatm_qness_3000_APLUS_EVO',
  Qness_250_A: 'qatm_qness_250_A',
  Qness_250_APLUS: 'qatm_qness_250_APLUS',
  Qness_750_A: 'qatm_qness_750_A',
  Qness_750_APLUS: 'qatm_qness_750_APLUS',
  Qness_3000_A: 'qatm_qness_3000_A',
  Qness_3000_APLUS: 'qatm_qness_3000_APLUS',
  Qness_150_CS: 'qatm_qness_150_CS_ECO',
  Qness_150_CSA: 'qatm_qness_150_CS_ECO',
  Qness_150_CSAPLUS: 'qatm_qness_150_CSAPLUS_ECO',
  Qness_200_CS: 'qatm_qness_200_CS_ECO',
  Qness_200_CSA: 'qatm_qness_200_CS_ECO',
  Qness_200_CSAPLUS: 'qatm_qness_200_CSAPLUS_ECO',
  Qness_150_A: 'qatm_qness_150_A',
  Qness_150_APLUS: 'qatm_qness_150_APLUS',
  Qness_150_M: 'qatm_qness_150_M',
  Qness_150_R: 'qatm_qness_150_R',
  Qness_10_CHD_Master: 'qatm_qness_10_CHD_Master',
  Qness_30_CHD_Master: 'qatm_qness_30_CHD_Master',
  Qness_60_CHD_Master: 'qatm_qness_60_CHD_Master',
  Qness_10_CHD_MasterPLUS: 'qatm_qness_10_CHD_MasterPLUS',
  Qness_30_CHD_MasterPLUS: 'qatm_qness_30_CHD_MasterPLUS',
  Qness_60_CHD_MasterPLUS: 'qatm_qness_60_CHD_MasterPLUS',
  Qness_10_A: 'qatm_qness_10_A',
  Qness_30_A: 'qatm_qness_30_A',
  Qness_60_A: 'qatm_qness_60_A',
  Qness_10_APLUS: 'qatm_qness_10_APLUS',
  Qness_30_APLUS: 'qatm_qness_30_APLUS',
  Qness_60_APLUS: 'qatm_qness_60_APLUS',
  Qness_10_M: 'qatm_qness_10_M',
  Qness_30_M: 'qatm_qness_30_M',
  Qness_60_M: 'qatm_qness_60_M',
  QMobile: 'qatm_QMobile',
  Qness_250_Base: 'qatm_qness_250_Base',
  Qness_750_Base: 'qatm_qness_750_Base',
  Qness_3000_Base: 'qatm_qness_3000_Base',
  Qness_250_Base_EVO: 'qatm_qness_250_Base_EVO',
  Qness_750_Base_EVO: 'qatm_qness_750_Base_EVO',
  Qness_3000_Base_EVO: 'qatm_qness_3000_Base_EVO',
  Qness_60_A_EVO: 'qatm_qness_60_A_EVO',
  Qness_60_APLUS_EVO: 'qatm_qness_60_APLUS_EVO',
  Qness_60_M_EVO: 'qatm_qness_60_M_EVO',
  Qeye_800: 'qatm_Qeye_800',
  Standard_Microscope: 'qatm_Standard_Microscope',
  Standard_Analog_Hardness_Tester: 'qatm_Standard_Analog_Hardness_Tester',
  QgrindXL: 'qatm_QgrindXL',
  QMobile_EVO: 'qatm_qmoblie_EVO',
  Qness_10_APLUS_2: 'qatm_qness_10_APLUS_2',
  Qness_10_M_2: 'qatm_qness_10_M_2',
  Qness_60_APLUS_EVO_2: 'qatm_qness_60_APLUS_EVO_2',
  Qness_1000_DCM_C_EVO: 'qatm_qness_1000_DCM_C_EVO',
  Qness_1000_DCM_CS_EVO: 'qatm_qness_1000_DCM_CS_EVO',
  Qness_1000_DCM_E_EVO: 'qatm_qness_1000_DCM_E_EVO',
  Qness_1000_DCM_M_EVO: 'qatm_qness_1000_DCM_M_EVO',

  //Retsch
  BB50: 'retsch_bb50',
  BB100: 'retsch_bb100',
  BB200: 'retsch_bb200',
  BB250: 'retsch_bb250',
  BB300: 'retsch_bb300',
  BB400: 'retsch_bb400',
  BB500: 'retsch_bb500',
  BB600: 'retsch_bb600',
  ZM300: 'retsch_zm300',
  Twister: 'retsch_twister',
  SR300: 'retsch_sr300',
  SK300: 'retsch_sk300',
  HM200: 'retsch_hm200',
  SM100: 'retsch_sm100',
  SM200: 'retsch_sm200',
  SM300: 'retsch_sm300',
  SM400: 'retsch_sm400',
  GM200: 'retsch_gm200',
  GM300: 'retsch_gm300',
  RM200: 'retsch_rm200',
  RS200: 'retsch_rs200',
  RS300: 'retsch_rs300',
  DM200: 'retsch_dm200',
  DM400: 'retsch_dm400',
  PM100: 'retsch_pm100',
  PM100CM: 'retsch_pm100cm',
  PM200: 'retsch_pm200',
  PM400: 'retsch_pm400',
  MM200: 'retsch_mm200',
  MM400: 'retsch_mm400',
  Cryomill: 'retsch_cryomill',
  MM500nano: 'retsch_mm500nano',
  MM500vario: 'retsch_mm500vario',
  MM500control: 'retsch_mm500control',
  Emax: 'retsch_emax',
  XRDMillMcCrone: 'retsch_xrd-millmccrone',
  TM300: 'retsch_tm300',
  TM500: 'retsch_tm500',
  AS200basic: 'retsch_as200basic',
  AS200digitcA: 'retsch_as200digitca',
  AS200control: 'retsch_as200control',
  AS300control: 'retsch_as300control',
  AS400control: 'retsch_as400control',
  AS450control: 'retsch_as450control',
  AS450basic: 'retsch_as200basic',
  AS200jet: 'retsch_as200jet',
  AS200tap: 'retsch_as200pharma',
  PT100: 'retsch_pt100',
  PT200: 'retsch_pt200',
  PT300: 'retsch_pt300',
  PT600: 'retsch_pt300',
  PP25: 'retsch_pp25',
  PP35: 'retsch_pp35',
  PP40: 'retsch_pp40',
  BT100: 'retsch_bt100',
  TG200: 'retsch_tg200',
  DR100: 'retsch_dr100',
  UR1: 'retsch_ur1',
  UR3: 'retsch_ur3',
  DustMonRD100: 'retsch_dustmonrd100',

  // Microtrac
  'CAMSIZER-3D': 'mrb_camsizer-3d',
  'CAMSIZER-M1': 'mrb_camsizer-m1',
  'CAMSIZER-P4': 'mrb_camsizer-p4',
  'CAMSIZER-X2': 'mrb_camsizer-x2',
  'NANOTRAC-FLEX': 'mrb_nanotrac-flex',
  'NANOTRAC-WAVE': 'mrb_nanotrac-wave',

  // Carbolite
  TF1_11_32_150_WITH_CC_T1: 'Carbolite_TF1_11_32_150_with_CC_T1',
};

export enum ImageSize {
  Thumbnail = 'Thumbnail',
  Full = 'Full',
}

@Pipe({
  name: 'deviceModelImage',
})
export class DeviceModelImage implements PipeTransform {
  transform(deviceModelReferenced?: DeviceModelReference, imageSize: ImageSize = ImageSize.Full): string {
    return this.resolveImagePath(deviceModelReferenced?.id, imageSize);
  }

  private resolveImagePath(name: string | undefined, imageSize: ImageSize): string {
    const size = imageSize === 'Thumbnail' ? '240x240' : '1500x1500';
    return `assets/devices/${(name ? deviceImagesById[name] : undefined) ?? 'placeholder'}_${size}.png`;
  }
}
