import { Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationService, NotificationType } from 'src/app/core/service/notification.service';
import { Subscriptions } from 'src/app/shared/util/Subscriptions';
import {
  AvailableDeviceModelFirmwareUpdate,
  LatestDeviceModelFirmwareVersion,
} from '../../../device-firmware/model/device-firmware-available-update.model';
import { DeviceModelReference } from '../model/device.model';
import { DeviceAvailableFirmwareUpdatesService } from './device-firmware-available-update.service';

export interface AcknowledgedDeviceModelFirmwareUpdateNotification {
  deviceModel: DeviceModelReference;
  key: string;
  version: string;
  latestVersion: LatestDeviceModelFirmwareVersion;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceAvailableFirmwareUpdatesNotificationService implements OnDestroy {
  private subscriptions = new Subscriptions();

  constructor(
    private deviceAvailableFirmwareUpdatesService: DeviceAvailableFirmwareUpdatesService,
    private notificationService: NotificationService,
    private translocoService: TranslocoService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }

  private resolveRelevantUpdates(
    availableUpdates: AvailableDeviceModelFirmwareUpdate[],
    acknowledgedUpdateNotifications: AcknowledgedDeviceModelFirmwareUpdateNotification[]
  ): AvailableDeviceModelFirmwareUpdate[] {
    const notAcknowledgedUpdates = availableUpdates.filter(
      availableUpdate =>
        !acknowledgedUpdateNotifications.find(
          acknowledgedUpdate =>
            acknowledgedUpdate.key === availableUpdate.key &&
            acknowledgedUpdate.deviceModel.id === availableUpdate.deviceModel.id &&
            acknowledgedUpdate.latestVersion.version === availableUpdate.latestVersion.version &&
            acknowledgedUpdate.deviceModel.vendor === availableUpdate.deviceModel.vendor
        )
    );

    return [
      ...new Map(
        notAcknowledgedUpdates.map(item => [
          `${item.deviceModel['vendor']}_${item['key']}_${item.latestVersion['version']}`,
          item,
        ])
      ).values(),
    ];
  }

  private getAcknowledgedUpdateNotifications(): AcknowledgedDeviceModelFirmwareUpdateNotification[] {
    const result = JSON.parse(localStorage.getItem('device-firmwares/acknowledged-update-notifications') || '[]');
    return Array.isArray(result) ? result : [];
  }

  private acknowledgeUpdates(updates: AvailableDeviceModelFirmwareUpdate[]): () => void {
    return () => {
      const acknowledgeableUpdates = this.getAcknowledgedUpdateNotifications();
      updates.forEach(update => {
        acknowledgeableUpdates.push({
          deviceModel: {
            vendor: update.deviceModel.vendor,
            id: update.deviceModel.id,
          },
          key: update.key,
          version: update.version,
          latestVersion: {
            version: update.latestVersion.version,
            releasedAt: update.latestVersion.releasedAt,
          },
        });
      });
      localStorage.setItem(
        'device-firmwares/acknowledged-update-notifications',
        JSON.stringify(acknowledgeableUpdates)
      );
    };
  }

  initialize() {
    const availableFirmwareUpdates = this.deviceAvailableFirmwareUpdatesService.availableFirmwareUpdates();
    const relevantUpdates = this.resolveRelevantUpdates(
      availableFirmwareUpdates,
      this.getAcknowledgedUpdateNotifications()
    );

    if (relevantUpdates.length > 0) {
      this.pushNotifications(relevantUpdates);
    }
  }

  private pushNotifications(updates: AvailableDeviceModelFirmwareUpdate[]): void {
    this.notificationService.pushNotification(
      {
        type: NotificationType.Information,
        title: this.translocoService.translate('device-firmware-update-available-title'),
        text: this.translocoService.translate('device-firmware-update-available-text', {
          updates: updates
            .map(update => {
              return `${update.key} (${update.latestVersion.version})`;
            })
            .join(', '),
        }),
      },
      this.acknowledgeUpdates(updates)
    );
  }
}
