import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DeviceLike } from '../../device/device-shared/model/device.model';

@Injectable({
  providedIn: 'root',
})
export class CoreNavigationService {
  currentRoute: BehaviorSubject<string> = new BehaviorSubject<string>('');
  urlAfterRedirects$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // history is only needed as workaround for window.history.back() which
  // causes the angular application to run without NgZone on the android platform
  // Issue: https://github.com/ionic-team/capacitor/issues/5933
  private history: string[] = [];
  private ignoredUris = ['/login', '/404', '/unauthorized', '/login-failed', '/no-access'];

  constructor(
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => event as NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.urlAfterRedirects$.next(event.urlAfterRedirects);
        this.currentRoute.next(event.url);

        if (Capacitor.getPlatform() === 'android') {
          if (
            this.ignoredUris.includes(event.urlAfterRedirects) ||
            (this.history.length > 0 && this.history[this.history.length - 1] === event.urlAfterRedirects)
          ) {
            return;
          }
          this.history.push(event.urlAfterRedirects);
        }
      });
  }

  previous(): void {
    if (Capacitor.getPlatform() === 'android') {
      this.history.pop();
      if (this.history.length > 0) {
        this.router.navigateByUrl(this.history[this.history.length - 1], {
          replaceUrl: true,
        });
      } else {
        App.exitApp();
      }
    }
    this.location.back();
  }

  navigateRoot(): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate(['/']);
    });
  }

  navigateToPageNotFound(): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate(['404']);
    });
  }

  navigateToNoAccess(): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate(['no-access']);
    });
  }

  navigateAfterError(): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate(['404']);
    });
  }

  navigateToDevices(): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate(['devices']);
    });
  }

  navigateTo(routeStr: string): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate([routeStr]);
    });
  }

  navigateToLogin(): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate(['login']);
    });
  }

  navigateToDeviceDetailsBy(device: DeviceLike): void {
    this.navigateToDeviceDetails(
      device.vendor,
      device.type,
      device.model?.identification.id,
      device.model?.identification?.version || '',
      device.id
    );
  }

  navigateToDeviceDetails(
    vendor: string,
    type: string,
    model: string,
    modelVersion: string,
    deviceId: string
  ): Promise<void> {
    if (!vendor && !type && !model && !modelVersion && !deviceId) {
      return this.navigateToPageNotFound();
    }
    vendor = vendor.toUpperCase();
    type = type !== 'UNDEFINED' ? type.toLocaleLowerCase() : 'device';
    model = model;
    modelVersion =
      typeof modelVersion !== 'undefined' && modelVersion !== '' ? modelVersion.toLocaleLowerCase() : 'latest';

    return this.ngZone.run(async () => {
      await this.router.navigate(['devices', vendor, type, model, modelVersion, deviceId]);
    });
  }

  navigateToManageLaboratoriesOverview(): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate(['virtual-laboratories']);
    });
  }

  navigateToServiceGroupDetails(groupId: string): Promise<void> {
    return this.ngZone.run(async () => {
      if (groupId) {
        await this.router.navigate(['administration', 'service-groups', groupId]);
      }
    });
  }

  goto(url: string): void {
    if (url.indexOf('://localhost') > -1) {
      alert('Invalid Url.');
    } else if (url.startsWith('/')) {
      this.ngZone.run(async () => {
        await this.router.navigateByUrl(url);
      });
    } else {
      window.location.href = url;
    }
  }

  navigateToDetailsRelative(resourceId: string): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate([this.currentRoute.getValue(), resourceId]);
    });
  }

  navigateToLaboratoryDetails(virtualLaboratoryId: string): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate(['virtual-laboratories', virtualLaboratoryId]);
    });
  }

  navigateToManageDeviceFirmwares(): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate(['administration', 'device-firmwares']);
    });
  }

  navigateToManageTenantsOverview(): Promise<void> {
    return this.ngZone.run(async () => {
      await this.router.navigate(['tenants']);
    });
  }
}
