import { Directive, HostBinding, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeService, ThemeVendor } from '../service/theme.service';

@Directive({
  selector: '[appThemeToggle]',
})
export class ThemeToggleDirective implements OnDestroy {
  @HostBinding('class.theme-verder') themeVerder = false;
  @HostBinding('class.verder') verder = false;
  @HostBinding('class.eltra') eltra = false;
  @HostBinding('class.carbolite') carbolite = false;
  @HostBinding('class.microtrac') microtrac = false;
  @HostBinding('class.qatm') qatm = false;
  @HostBinding('class.retsch') retsch = false;

  private themeSubscription: Subscription;

  constructor(themeSerivce: ThemeService) {
    this.themeSubscription = themeSerivce.vendor$.subscribe(theme => {
      switch (theme) {
        case ThemeVendor.VERDER:
          this.setAllFlagToFalse();
          this.verder = true;
          break;
        case ThemeVendor.CARBOLITE:
          this.setAllFlagToFalse();
          this.carbolite = true;
          break;
        case ThemeVendor.ELTRA:
          this.setAllFlagToFalse();
          this.eltra = true;
          break;
        case ThemeVendor.MICROTRAC:
          this.setAllFlagToFalse();
          this.microtrac = true;
          break;
        case ThemeVendor.QATM:
          this.setAllFlagToFalse();
          this.qatm = true;
          break;
        case ThemeVendor.RETSCH:
          this.setAllFlagToFalse();
          this.retsch = true;
          break;
        default:
          throw new Error('Unknown Theme Vendor');
      }
    });
  }

  ngOnDestroy(): void {
    this.themeSubscription.unsubscribe();
  }

  private setAllFlagToFalse() {
    this.verder = false;
    this.carbolite = false;
    this.eltra = false;
    this.microtrac = false;
    this.qatm = false;
    this.retsch = false;
  }
}
