import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OptGroup } from './combobox.types';

@Component({
  selector: 'app-combobox-with-group',
  templateUrl: './combobox-with-group.component.html',
  styleUrls: ['./combobox-with-group.component.scss'],
})
export class ComboboxWithGroupComponent {
  @Input() options: OptGroup[] = [];
  @Input() isDisabled: boolean = false;

  @Output() selected: EventEmitter<string> = new EventEmitter();

  constructor() {}

  handleSelectionChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.selected.emit(value);
  }
}
