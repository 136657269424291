import { Component, Input } from '@angular/core';
import { KeyValueListComponent } from '../key-value-list/key-value-list.component';
import { ButtonType, LongRunningTask } from '../button/button.component';

export type ButtonHandler = () => Promise<any> | void;

export type IsEnabledChecker = () => boolean;

export interface IAction {
  title: string;
  icon?: string;
  type?: ButtonType;
  isEnabled: IsEnabledChecker;
  handler?: ButtonHandler;
  link?: string;
  isWorking?: IsEnabledChecker;
}

export class Action implements IAction {
  title: string = '';
  icon?: string;

  isEnabled: IsEnabledChecker;
  isWorking?: IsEnabledChecker;

  handler: ButtonHandler | LongRunningTask = () => {};
  link?: string;

  type: ButtonType;

  constructor({ title, icon, isEnabled, handler, link, type, isWorking }: IAction) {
    this.title = title;
    this.icon = icon;
    this.isEnabled = isEnabled;
    this.handler = handler ? handler : () => {};
    this.link = link;
    this.type = type ? type : ButtonType.SECONDARY;
    this.isWorking = isWorking;
  }

  iconClass(): string {
    if (!this.icon) return '';
    return calcIconClass(this.icon);
  }
}

export function calcIconClass(icon: string): string {
  if (icon.startsWith('icon-')) {
    return `icon ${icon}`;
  } else if (icon.startsWith('fa-')) {
    return `fa ${icon}`;
  }
  return icon;
}

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.html',
  styleUrls: ['card-header.scss'],
})
export class CardHeaderComponent extends KeyValueListComponent {
  @Input() icon: string = '';
  @Input() title: string = '';

  @Input() actions: Action[] = [];

  get iconClass(): string {
    return calcIconClass(this.icon);
  }

  get firstAction(): Action | undefined {
    if (this.actions.length > 0) {
      return this.actions[0];
    }
  }

  get restOfActions(): Action[] {
    return this.actions.slice(1);
  }

  noopHandler: LongRunningTask = async () => {};
}
