import { Pipe, PipeTransform } from '@angular/core';
import { printUnitInformation as formatUnitaryUnit, Unitary } from '../model/unitary-value';

@Pipe({
  name: 'formatUnitaryUnit',
})
export class FormatUnitaryUnitPipe implements PipeTransform {
  transform(unitary?: Unitary): any {
    return formatUnitaryUnit(unitary);
  }
}
