import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seconds',
})
export class SecondsFormatterPipe implements PipeTransform {
  transform(seconds: number): any {
    var secondsPart = seconds % 60;
    var minutesPart = Math.floor(seconds / 60) % 60;
    var hoursPart = Math.floor(seconds / 3600);
    return `${hoursPart.toString().padStart(2, '0')}:${minutesPart.toString().padStart(2, '0')}:${secondsPart
      .toString()
      .padStart(2, '0')}`;
  }
}
