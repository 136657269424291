import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';

const TRANSLATABLE_BREADCRUMB_ITEMS = [
  'login',
  'devices',
  'management',
  'servicegroups',
  'laboratories',
  'logs',
  'journal',
  'backups',
  'download',
  'device-events',
  'measurement-results',
  'edit',
  'logout-success',
  'virtual-laboratories',
  'user-assignments',
  'device-event-triggers',
  'device-firmwares',
  'versions',
  'device-assignments',
];

@Pipe({
  name: 'translateBreadcrumb',
})
export class TranslateBreadcrumbPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(value: string): Observable<string> {
    if (value === 'iotadmin') {
      return this.translocoService.selectTranslate('iot-administration');
    } else if (value === 'result-reports') {
      return this.translocoService.selectTranslate('analysis-report');
    } else if (value === 'create-trigger') {
      return this.translocoService.selectTranslate('create-event-trigger');
    } else if (value === 'product-information') {
      return this.translocoService.selectTranslate('product-information');
    } else if (value === 'virtual-laboratory-access-key') {
      return this.translocoService.selectTranslate('virtual-laboratory.access-key');
    } else {
      if (TRANSLATABLE_BREADCRUMB_ITEMS.find(item => item === value)) {
        return this.translocoService.selectTranslate(value);
      }
      return of(value);
    }
  }
}
