@if (categories && categories.length > 0) {
  @for (category of categories | sortBy : 'asc' : 'label'; track category) {
    @if (findItemsByCategory(category); as items) {
      <div class="pb-10">
        @if (categoryHeaderVisible) {
          <h5 class="pb-6">{{ category.label }}</h5>
        }
        <app-card-grid
          [items]="items"
          [contentTemplate]="contentTemplate"
        (cardClicked)="onItemClicked($event)"></app-card-grid>
      </div>
    }
  }
} @else {
  <app-card-grid [items]="[]"></app-card-grid>
}

