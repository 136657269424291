export enum ErrorType {
  INFO,
  WARNING,
  VALIDATION_ERROR,
}

interface MessageParams {
  [key: string]: any;
}

export interface MessageInfo {
  description: string;
  /**
   * Ein Objekt mit Parameter-Werten.
   * Die Werte wernden an Transloco zur Übersetzung der Description
   * verwendet.
   */
  params?: MessageParams;
  type: ErrorType;
}
