import { PipeTransform } from '@angular/core';
import { Comparator } from './Comparator';

export abstract class AbstractSortingPipe<T> implements PipeTransform {
  private comparators: Comparator<T>[];

  protected constructor(comparators: Comparator<T>[]) {
    this.comparators = comparators;
  }

  transform(items: T[]): T[] {
    return items.sort((itemA, itemB) => {
      for (const comparator of this.comparators) {
        const result = comparator.compare(itemA, itemB);
        if (result !== 0) {
          return result;
        }
      }
      return 0;
    });
  }
}
