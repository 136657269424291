import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, first } from 'rxjs/operators';
import { SharedDeviceFileService } from '../../service/shared-device-files.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';

interface QueryParams {
  deviceId: string;
  filetype: string;
  fileId: string;
  scope: string;
}

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
})
export class DownloadFileComponent implements OnInit {
  fileUri?: string;

  linkExpired = false;
  authenticated = false;

  constructor(
    private route: ActivatedRoute,
    private msalBroadcastService: MsalBroadcastService,
    private service: SharedDeviceFileService
  ) {}

  ngOnInit(): void {
    // wait for msal auth before requesting data from the API
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        first()
      )
      .subscribe(() => {
        this.requestFileUriAndRedirect(this.route.snapshot.queryParams as QueryParams);
      });
  }

  requestFileUriAndRedirect(params: QueryParams): void {
    lastValueFrom(this.service.getFileDownloadUrl(params.deviceId, params.filetype, params.fileId, params.scope))
      .then((url: string): void => {
        this.fileUri = url;
        if (!url) {
          return;
        }
        setTimeout(() => window.location.replace(url), 1500);
      })
      .catch(() => {
        this.linkExpired = true;
      });
  }
}
