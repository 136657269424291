<a
  class="navigation-list-item"
  data-cy="navigationListItem"
  [routerLink]="routerLink"
  [routerLinkActive]="routerLinkActive || ''"
  [routerLinkActiveOptions]="routerLinkActiveOptions"
  (click)="navigateClick()">
  @if (isFontAwesomeIcon) {
    <i class="fa {{ iconName }} pr-4 navi-icon-fa-size"></i>
  } @else {
    <app-svg-icon class="list-icon" [name]="iconName"></app-svg-icon>
  }
  <div>{{ title }}</div>
</a>
