import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  RegistrationError,
  Token,
} from '@capacitor/push-notifications';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoreLoginService } from './core-login.service';
import { NotificationService, NotificationType } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private loginService: CoreLoginService,
    private translocoService: TranslocoService,
    private ngZone: NgZone
  ) {}

  registerDevice(): void {
    this.ngZone.runOutsideAngular(() => {
      if (!Capacitor.isNativePlatform()) {
        return; // Push Notifications are only registered in mobile apps
      }
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // current behaviour: do nothing if user declines permission on iOS
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        const notificationServiceName = Capacitor.getPlatform() == 'android' ? 'FCM' : 'APNS';
        this.ngZone.run(() => {
          if (this.loginService.currentUserLegacyValue) {
            this.requestRegisterDevice(token.value, notificationServiceName).subscribe();
          } else {
            const subscription = this.loginService.currentUserLegacy.subscribe(user => {
              if (user) {
                this.requestRegisterDevice(token.value, notificationServiceName).subscribe();
                subscription.unsubscribe();
              }
            });
          }
        });
      });

      PushNotifications.addListener('registrationError', (error: RegistrationError) => {
        throw new Error(`Push Notification Registration Error with message: ${error.error}`);
      });

      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
        this.ngZone.run(() => {
          this.notificationService.pushNotification({
            title: notification.title ?? this.translocoService.translate('new-message'),
            type: NotificationType.Information,
            text: notification.body ?? '',
          });
        });
      });

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
      });
    });
  }

  private requestRegisterDevice(token: string, platform: 'APNS' | 'FCM'): Observable<HttpResponse<string>> {
    return this.httpClient.post<string>(
      `${environment.backendApi.shared.uri}/push-notifications/registrations`,
      {
        pnsRegistrationToken: token,
        pnsPlatform: platform,
      },
      { observe: 'response' }
    );
  }
}
