<div class="notification p-2 mb-3 mr-3">
  @if (notification) {
    <div class="pb-2 d-flex justify-content-between small">
      <div class="font-weight-bold text-uppercase">
        <i class="fa fa-square pr-1 {{ toastIconCssClass }}"></i>
        {{ 'notification-' + notification.level | lowercase | transloco }}
      </div>
      <div class="ml-auto text-muted">
        {{
        notification.reportedAt | translocoDate : { dateStyle: 'long', timeStyle: 'medium' } : (locale$ | async) || ''
        }}
      </div>
    </div>
    <div>
      {{ notification.text || notification.summary }}
    </div>
  }
</div>
