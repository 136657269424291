import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, UrlTree } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import {  Observable } from 'rxjs';
import { concatMap, filter, first, map } from 'rxjs/operators';
import { CoreUserRole } from '../model/core-user-role.model';
import { CoreLoginService } from '../service/core-login.service';
import { UserPreferencesService } from 'src/app/user-settings/service/user-preferences.service';

@Injectable({
  providedIn: 'root',
})
export class CoreLoginGuard {
  constructor(
    private loginService: CoreLoginService,
    private userPreferencesService: UserPreferencesService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
  ) {}

  private checkProgress(route: ActivatedRouteSnapshot | Route): Observable<boolean | UrlTree> {
    let path: string | undefined = undefined;
    if (route instanceof  ActivatedRouteSnapshot) {
      path = route.url[0]?.path;
    }
    
    return this.msalBroadcastService.inProgress$.pipe(
      filter(status => status === InteractionStatus.None),
      first(),
      concatMap(() => this.checkUser(route?.data?.roles, path))
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.checkProgress(route);
  }

  canLoad(route: Route): Observable<boolean | UrlTree> {
    return this.checkProgress(route);
  }

  checkUser(roles: CoreUserRole[] = [], path?: string): Observable<boolean | UrlTree> {
    const UNAUTHORIZED_PAGE = this.router.parseUrl('/unauthorized');
    return this.loginService.loadUser().pipe(
      first(),
      map(user => {
        if (!user) {
          // no session: send to login
          return this.router.parseUrl('/login');
        }
        
        const excludedPaths = ['/', 'user-settings', 'login', 'logout-success']
        if (path && excludedPaths.indexOf(path) === -1 && this.loginService.currentUserSubject?.value?.deleted) {
          return this.router.parseUrl('/user-settings');
        }
        
        if (!this.loginService.currentUserSubject?.value?.deleted) {
          this.userPreferencesService.getUserPreferences(user.UserId).subscribe();
        }
        if (roles?.length) {
          for (const role of roles) {
            const minAccessLevel = user.existsMinAccessLevel(role);
            if (minAccessLevel) {
              return true;
            }
          }
          //wrong role: unautorised
          return UNAUTHORIZED_PAGE;
        }
        return true;
      })
    );
  }
}
