import { Component, OnDestroy, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { SharedMediaQueryService } from 'src/app/shared/service/shared-media-query.service';
import { Subscriptions } from 'src/app/shared/util/Subscriptions';
import { Notification, NotificationService } from '../../service/notification.service';

@Component({
  selector: 'app-notification-area',
  templateUrl: './app-notification-area.component.html',
  styleUrls: ['./app-notification-area.component.scss'],
})
export class AppNotificationAreaComponent implements OnInit, OnDestroy {
  notifications: Notification[] = [];

  private subscriptions = new Subscriptions();
  isMobile: boolean | undefined;

  constructor(private notificationService: NotificationService, private mediaService: SharedMediaQueryService) {}

  ngOnInit(): void {
    this.subscriptions.put(
      this.mediaService
        .checkScreenSize('575', 'portrait')
        .pipe(switchMap(_ => this.mediaService.screenSize$))
        .subscribe(boolean => (this.isMobile = boolean))
    );

    const subscription = this.notificationService.notifications$.subscribe(
      notifications => (this.notifications = notifications)
    );

    this.subscriptions.put(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
