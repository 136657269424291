import { ExtraOptions } from '@angular/router';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  BrowserUtils,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { TranslocoConfig } from '@ngneat/transloco';
import { environment } from '../environments/environment';

export const routerConfig: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  useHash: false,
  paramsInheritanceStrategy: 'always',
  enableTracing: false,
  // Don't perform initial navigation in iframes or popups
  initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled',
};

export const b2cPolicies = {
  names: {
    signIn: environment.b2cAuth.signInName,
    signUp: environment.b2cAuth.signUpName,
  },
  authorities: {
    signIn: {
      authority: environment.b2cAuth.signInAuthority,
    },
    signUp: {
      authority: environment.b2cAuth.signUpAuthority,
    },
  },
};

export const loginScopes = ['openid', 'profile', 'offline_access', environment.b2cAuth.clientId];

export function loggerCallback(logLevel: LogLevel, message: string): void {
  switch (logLevel) {
    case LogLevel.Error:
      console.error(message);
      break;
    case LogLevel.Info:
      console.info(message);
      break;
    case LogLevel.Verbose:
      console.debug(message);
      break;
    case LogLevel.Warning:
      console.warn(message);
      break;
  }
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const msalConfig = new PublicClientApplication({
  auth: {
    clientId: environment.b2cAuth.clientId,
    authority: b2cPolicies.authorities.signIn.authority,
    redirectUri: environment.b2cAuth.redirectUri,
    postLogoutRedirectUri: environment.b2cAuth.redirectUri + '/logout-success',
    // navigateToLoginRequestUrl: true,
    navigateToLoginRequestUrl: false,
    knownAuthorities: environment.b2cAuth.knownAuthorities,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE, // set to true for IE 11
  },
  system: {
    loggerOptions: {
      loggerCallback,
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false,
    },
  },
});

export const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map<string, Array<string>>([
    [environment.backendApi.shared.uri, [environment.b2cAuth.clientId]],
    [environment.backendApi.carbolite.uri, [environment.b2cAuth.clientId]],
    [environment.backendApi.eltra.uri, [environment.b2cAuth.clientId]],
    [environment.backendApi.microtrac.uri, [environment.b2cAuth.clientId]],
    [environment.backendApi.qatm.uri, [environment.b2cAuth.clientId]],
    [environment.backendApi.retsch.uri, [environment.b2cAuth.clientId]],
    [environment.backendApi.retschMockDevice.uri, [environment.b2cAuth.clientId]],
  ]),
};

export const msalGuardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: loginScopes,
  },
  loginFailedRoute: '/login-failed',
};

export const translocoAppConfig: Partial<TranslocoConfig> = {
  availableLangs: ['en', 'de'],
  defaultLang: 'en',
  fallbackLang: 'en',
  reRenderOnLangChange: true,
  prodMode: environment.production,
  missingHandler: {
    logMissingKey: true,
    useFallbackTranslation: true,
  },
};
