import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CoreLoginGuard } from './core/guard/core-login.guard';
import { HandleAuthGuard } from './core/guard/handle-auth.guard';
import { InitialRedirectGuard } from './core/guard/initial-redirect-guard';
import { NgbModalDismissAllGuard } from './core/guard/modal.guard';
import { RedirectIfLoggedInGuard } from './core/guard/redirect-if-loggedin.guard';
import { CoreUserRole } from './core/model/core-user-role.model';
import { AboutComponent } from './core/page/about/about.component';
import { HomeComponent } from './core/page/home/home.component';
import { LoggedOutComponent } from './core/page/logged-out/logged-out.component';
import { LoginFailedComponent } from './core/page/login-failed/login-failed.component';
import { LoginComponent } from './core/page/login/login.component';
import { NotFoundComponent } from './core/page/not-found/not-found.component';
import { UnauthorizedComponent } from './core/page/unauthorized/unauthorized.component';
import { VerderUIAssetLibraryComponent } from './core/page/verder-uiasset-library/verder-uiasset-library.component';
import { DownloadFileComponent } from './device/device-files/page/download-file/download-file.component';
// import { SilentSSOComponent } from './core/page/silent-sso/silent-sso.component';
import { NoAccessComponent } from './core/page/no-access/no-access.component';
import { RedirectLocaleGuard } from './core/guard/redirect-locale-guard';

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [RedirectLocaleGuard, HandleAuthGuard],
    canActivateChild: [NgbModalDismissAllGuard],
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [RedirectIfLoggedInGuard],
        data: { redirectToIfLoggedIn: '/home' },
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [MsalGuard, CoreLoginGuard, InitialRedirectGuard],
      },
      // {
      //   path: 'silent',
      //   component: SilentSSOComponent,
      //   canActivate: [MsalGuard],
      // },
      { path: 'login-failed', component: LoginFailedComponent },
      {
        path: 'logout-success',
        component: LoggedOutComponent,
        canActivate: [RedirectIfLoggedInGuard],
        data: { redirectToIfLoggedIn: '/' },
      },
      { path: 'about', component: AboutComponent },
      { path: 'download', component: DownloadFileComponent },
      { path: 'vsiot/ui', component: VerderUIAssetLibraryComponent },
      { path: '404', component: NotFoundComponent },
      { path: 'no-access', component: NoAccessComponent, canActivate: [MsalGuard, CoreLoginGuard] },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent,
        canActivate: [RedirectIfLoggedInGuard],
        data: { redirectToIfLoggedIn: '/' },
      },
      {
        path: 'devices',
        loadChildren: () => import('./device/device-list/device-list.module').then(m => m.DeviceListModule),
        canActivate: [MsalGuard, CoreLoginGuard],
      },
      {
        path: 'devices',
        loadChildren: () => import('./device/device.module').then(m => m.DeviceModule),
        canActivate: [MsalGuard, CoreLoginGuard],
      },
      {
        path: 'device-events',
        loadChildren: () => import('./virtual-laboratory/device-events.module').then(m => m.DeviceEventsModule),
        canActivate: [MsalGuard, CoreLoginGuard],
      },
      {
        path: 'tenants',
        loadChildren: () => import('./tenant/tenant.module').then(m => m.TenantModule),
        canActivate: [MsalGuard, CoreLoginGuard],
      },
      {
        path: 'product-information',
        loadChildren: () =>
          import('./product-information/product-information.module').then(m => m.ProductInformationModule),
        canActivate: [MsalGuard, CoreLoginGuard],
      },
      {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
        canActivate: [MsalGuard, CoreLoginGuard],
        data: { roles: [CoreUserRole.Admin] },
      },
      {
        path: 'virtual-laboratories',
        loadChildren: () =>
          import('./virtual-laboratory/virtual-laboratory.module').then(m => m.VirtualLaboratoryModule),
        canActivate: [MsalGuard, CoreLoginGuard],
      },
      {
        path: 'user-settings',
        loadChildren: () => import('./user-settings/user-settings.module').then(m => m.UserSettingsModule),
        canActivate: [MsalGuard, CoreLoginGuard],
      },
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: '**', pathMatch: 'full', component: NotFoundComponent, canActivate: [MsalGuard, CoreLoginGuard] },
    ],
  },
];
