<ng-container *transloco="let t" class="container">
  <div class="container">
    <div class="white-container">
      <div class="honeycomb-col">
        <img src="../../../../assets/images/VS_6-Honeycombs_horizontal.svg" alt="" class="honeycomb" />
        <h2 class="login-title pb-5">{{ t('login.title') }}</h2>
        <div class="text-content">
          <h4 class="pb-2 pt-6">ENHANCING OPERATIONAL EFFICIENCY</h4>
          <p>
            IoT’s real-time data capabilities highlighted as a catalyst for operational efficiency. The ability to
            access up-to-the-minute information empowers decision-makers to respond promptly, resulting in time savings
            and operational streamlining.
          </p>
          <h4 class="pb-2 pt-6">MITIGATING GLOBAL TIME ZONE CHALLENGES</h4>
          <p>
            IoT’s real-time functionality presented as a viable solution to circumvent the challenges posed by global
            time zone variations. By leveraging IoT, businesses and teams can transcend geographical constraints and
            access critical resources when needed, irrespective of the time zone.
          </p>
          <h4 class="pb-2 pt-6">LEVERAGING MICROSOFT’S ROBUST CLOUD INFRASTRUCTURE</h4>
          <p>
            The advantages of deploying IoT solutions on Microsoft’s cloud infrastructure. This choice not only ensures
            rapid data processing and analytics but also guarantees the highest levels of data security.

            <span><a href="https://www.verder-scientific.com/products/iot">Learn More</a></span>
          </p>
        </div>
      </div>
    </div>
    <div class="login-col">
      <div class="user-info">
        <fa-icon [icon]="circleUserIcon" class="global-menu-item-icon"></fa-icon>
        <div class="last-user">
          <p>
            {{ t('login.previous-user') }} <strong>{{ userEmail }}</strong>
          </p>
          <p>
            {{ t('login.previous-user-role') }} <strong>{{ userRole }}</strong>
          </p>
          <p class="last-login">{{ t('login.previous-login') }} {{ lastLoginTime }}</p>
        </div>
      </div>
      <div class="btns">
        <button type="button" class="btn btn-primary" (click)="startAccountCreation()">
          {{ t('create-account') }}
        </button>
        <button id="login-btn" data-cy="loginButton" type="button" class="btn btn-lg btn-link" (click)="startLogin()">
          {{ 'log-in' | transloco }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
