import { Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

export interface KeyValuePair {
  name: string;
  value: any;
}

@Component({
  selector: 'app-device-key-values',
  styleUrls: ['./key-value-list.component.scss'],
  templateUrl: './key-value-list.component.html',
})
export class KeyValueListComponent {
  @Input() title!: string;
  @Input() iconName = 'icon-gears';
  @Input() keyValuePairs: KeyValuePair[] = [];

  locale$?: Observable<string>;

  constructor(translocoService: TranslocoService) {
    this.locale$ = translocoService.langChanges$;
  }

  isColorCode(value: any): boolean {
    return this.isString(value) && value.match(/#[0-9A-F]{6}/g);
  }

  isString(value: any): boolean {
    return typeof value === 'string' || value instanceof String;
  }

  isBoolean(value: any): boolean {
    return typeof value === 'boolean';
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  isFormattedNumber(value: any): boolean {
    return value && typeof value.value === 'number' && typeof value.numberFormatOptions === 'object';
  }

  isFormattedNumericUnitaryValue(value: any): boolean {
    return value && typeof value.value === 'number' && !!value.unit && !!value.unitType;
  }

  isValidValue(value: any): boolean {
    return (
      this.isString(value) ||
      this.isBoolean(value) ||
      this.isNumber(value) ||
      this.isFormattedNumber(value) ||
      this.isFormattedNumericUnitaryValue(value)
    );
  }
}
