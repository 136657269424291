<ng-container *transloco="let t">
  <div class="device-summary">
    <div class="bd-highlight">
      <img
        class="img-fluid"
        srcset="{{ device | deviceImage: imageMap.Thumbnail }} 240w, {{ device | deviceImage: imageMap.Full }} 1500w" />
    </div>
    <div class="block">
      <div class="device-name">{{ name }}</div>
      <span style="font-size: 13px; color: #a9a9a9" class="pt-5">
        {{ lastUpdateInformation }}
      </span>
    </div>
    <div class="block">
      <div class="device-info" data-cy="deviceInfo">
        {{ virtualLaboratoryName }}<br />
        {{ modelString }}
      </div>
    </div>
    <div>
      @if (device) {
        <app-summary-connection-status [deviceId]="device.id" autoWidth="true"></app-summary-connection-status>
      }
    </div>
  </div>
</ng-container>
