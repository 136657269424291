import { Component, OnDestroy, OnInit, Signal, computed, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { AvailableDeviceModelFirmwareUpdate } from 'src/app/device-firmware/model/device-firmware-available-update.model';
import {
  createDeviceModelReference,
  Device,
  DeviceFirmware,
  DeviceVendor,
} from 'src/app/device/device-shared/model/device.model';
import { SharedDeviceService } from '../../service/shared-device.service';
import { DeviceAvailableFirmwareUpdatesService } from '../../service/device-firmware-available-update.service';
import { TranslocoService } from '@ngneat/transloco';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { ProductInformationService } from '../../../../product-information/service/product-information.service';

export interface FirmwaresComponentData {
  firmwares: DeviceFirmware[];
  availableFirmwareUpdates: Map<string, AvailableDeviceModelFirmwareUpdate>;
}

@Component({
  selector: 'app-device-firmwares',
  templateUrl: './firmwares.component.html',
})
export class FirmwaresComponent implements OnInit, OnDestroy {
  firmwareData: Signal<FirmwaresComponentData | undefined> = signal<FirmwaresComponentData | undefined>(undefined);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  subscription: Subscription = new Subscription();

  downloadIcon = faDownload;
  device!: Device | undefined;
  isUpdateLoading$ = new BehaviorSubject<boolean>(false);
  deviceVendor = DeviceVendor;

  actionExcecution = false;
  actionSuccessResponse = false;
  actionErrorResponse = false;

  constructor(
    private deviceAvailableFirmwareUpdatesService: DeviceAvailableFirmwareUpdatesService,
    route: ActivatedRoute,
    private sharedDeviceService: SharedDeviceService,
    private translocoService: TranslocoService,
    private productInformationService: ProductInformationService
  ) {
    const device = this.sharedDeviceService.device()?.data;
    if (!device) this.firmwareData = signal<FirmwaresComponentData | undefined>(undefined);

    this.firmwareData = computed(() => {
      const device = this.sharedDeviceService.device()?.data;
      if (device) {
        let availableFirmwareUpdates;

        const availableUpdates = this.deviceAvailableFirmwareUpdatesService.availableFirmwareUpdates();

        if (availableUpdates) {
          const reference = createDeviceModelReference(device);
          availableFirmwareUpdates = availableUpdates.reduce((result, current) => {
            if (_.isEqual(current.deviceModel, reference)) {
              result.set(current.key, current);
            }

            return result;
          }, new Map<string, AvailableDeviceModelFirmwareUpdate>());
        } else {
          availableFirmwareUpdates = new Map<string, AvailableDeviceModelFirmwareUpdate>();
        }
        this.isLoading$.next(false);

        return {
          firmwares: device.firmwares,
          availableFirmwareUpdates,
        };
      }
    });
  }

  ngOnInit(): void {
    this.device = this.sharedDeviceService.device()?.data;
    if (!this.device) return;
    this.subscription.add(
      this.deviceAvailableFirmwareUpdatesService.getAvailableFirmwareUpdatesForDevices(this.device.id).subscribe()
    );
  }

  areFirmwareUpdatesAvailable$(key: string): Observable<boolean> {
    const currentFirmwareVersion = this.firmwareData()?.firmwares.find(firmware => firmware.key === key)?.version;
    const availableFirmwareVersion = this.firmwareData()?.availableFirmwareUpdates.get(key)?.latestVersion.version;

    if (!currentFirmwareVersion || !availableFirmwareVersion) return of(false);

    return currentFirmwareVersion < availableFirmwareVersion &&
      this.firmwareData()?.availableFirmwareUpdates.has(key) &&
      this.device?.vendor === this.deviceVendor.QATM
      ? of(true)
      : of(false);
  }

  updateSoftware(firmware: AvailableDeviceModelFirmwareUpdate): void {
    if (!this.device) return;
    this.actionExcecution = true;
    const params = {
      DeviceModelId: this.device.model.identification.id,
      Key: firmware.key,
      Version: firmware.version,
    };
    this.productInformationService.updateSoftware(this.device.id, params).subscribe(
      () => {
        this.actionExcecution = false;
        this.actionSuccessResponse = true;
        setTimeout(() => (this.actionSuccessResponse = false), 5000);
      },
      () => {
        this.actionExcecution = false;
        this.actionErrorResponse = true;
        setTimeout(() => (this.actionErrorResponse = false), 5000);
      }
    );
  }

  ngOnDestroy(): void {
    this.deviceAvailableFirmwareUpdatesService.emptyAvailableFirmwareUpdates();
    this.subscription.unsubscribe();
  }
}
