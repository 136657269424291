import { CoreUserRole } from './core-user-role.model';
import { ICoreUserGroupRole } from './core-user-group-role.model';
import { ICoreUserGroup } from './core-user-group.model';

export interface ICoreUser {
  UserId: string;
  IsAdmin: boolean;
  SessionId: string;
  UserGroups: ICoreUserGroupRole[];
  MaxRole: CoreUserRole | null;
}

export class CoreUser implements ICoreUser {
  UserId: string;
  IsAdmin: boolean;
  SessionId: string;
  UserGroups: ICoreUserGroupRole[];
  MaxRole: CoreUserRole | null;

  constructor(userContract: ICoreUser, public username: string) {
    this.UserId = userContract.UserId;
    this.IsAdmin = userContract.IsAdmin;
    this.SessionId = userContract.SessionId;
    this.UserGroups = userContract.UserGroups;
    this.MaxRole = null;

    this.initMaxRole();
  }

  private initMaxRole(): void {
    let role: CoreUserRole | null = null;
    let finished = false;

    if (this?.IsAdmin) {
      role = CoreUserRole.Admin;
    } else if (this?.UserGroups?.length) {
      for (let i = 0; i < this.UserGroups?.length; i++) {
        const tmpRole = this.UserGroups[i].Role;

        switch (tmpRole) {
          case CoreUserRole.Admin:
            role = tmpRole;
            finished = true;
            break;
          case CoreUserRole.VSTechnician:
            if (
              !role ||
              role === CoreUserRole.LabTechnician ||
              role === CoreUserRole.LabManager ||
              role === CoreUserRole.ServiceTechnician
            ) {
              role = tmpRole;
            }
            break;
          case CoreUserRole.ServiceTechnician:
            if (!role || role === CoreUserRole.LabTechnician || role === CoreUserRole.LabManager) {
              role = tmpRole;
            }
            break;
          case CoreUserRole.LabManager:
            if (!role || role === CoreUserRole.LabTechnician) {
              role = tmpRole;
            }
            break;
          case CoreUserRole.LabTechnician:
            if (!role) {
              role = tmpRole;
            }
            break;
          default:
            break;
        }

        if (finished) {
          break;
        }
      }
    }
    this.MaxRole = role;
  }

  existsMinAccessLevel(requiredRole: CoreUserRole): boolean {
    let ret: boolean = false;

    if (requiredRole) {
      switch (requiredRole) {
        case CoreUserRole.Admin:
          ret = this?.IsAdmin ? true : false;
          break;
        case CoreUserRole.VSTechnician:
          ret = this?.MaxRole === CoreUserRole.Admin || this?.MaxRole === CoreUserRole.VSTechnician;
          break;
        case CoreUserRole.ServiceTechnician:
          ret =
            this?.MaxRole === CoreUserRole.Admin ||
            this?.MaxRole === CoreUserRole.VSTechnician ||
            this?.MaxRole === CoreUserRole.ServiceTechnician;
          break;
        case CoreUserRole.LabManager:
          ret =
            this?.MaxRole === CoreUserRole.Admin ||
            this?.MaxRole === CoreUserRole.VSTechnician ||
            this?.MaxRole === CoreUserRole.ServiceTechnician ||
            this?.MaxRole === CoreUserRole.LabManager;
          break;
        case CoreUserRole.LabTechnician:
          ret =
            this?.MaxRole === CoreUserRole.Admin ||
            this?.MaxRole === CoreUserRole.VSTechnician ||
            this?.MaxRole === CoreUserRole.ServiceTechnician ||
            this?.MaxRole === CoreUserRole.LabManager ||
            this?.MaxRole === CoreUserRole.LabTechnician;
          break;
        default:
          break;
      }
    }

    return ret;
  }

  hasMinAccessLevelForUserGroup(requiredRole: CoreUserRole, userGroupId?: string): boolean {
    let ret = false;

    if (userGroupId && requiredRole && (this?.IsAdmin || this?.UserGroups?.length)) {
      if (this.IsAdmin === true) {
        ret = true;
      } else {
        const findUserGroupRole: ICoreUserGroupRole | undefined = this?.UserGroups?.find(
          userGroupRole => userGroupRole.UserGroupId === userGroupId
        );

        if (findUserGroupRole && findUserGroupRole.UserGroupId && findUserGroupRole.Role) {
          switch (requiredRole) {
            case CoreUserRole.Admin:
              ret = this.IsAdmin ? true : false;
              break;
            case CoreUserRole.VSTechnician:
              ret =
                findUserGroupRole?.Role === CoreUserRole.Admin || findUserGroupRole?.Role === CoreUserRole.VSTechnician;
              break;
            case CoreUserRole.ServiceTechnician:
              ret =
                findUserGroupRole?.Role === CoreUserRole.Admin ||
                findUserGroupRole?.Role === CoreUserRole.VSTechnician ||
                findUserGroupRole?.Role === CoreUserRole.ServiceTechnician;
              break;
            case CoreUserRole.LabManager:
              ret =
                findUserGroupRole?.Role === CoreUserRole.Admin ||
                findUserGroupRole?.Role === CoreUserRole.VSTechnician ||
                findUserGroupRole?.Role === CoreUserRole.ServiceTechnician ||
                findUserGroupRole?.Role === CoreUserRole.LabManager;
              break;
            case CoreUserRole.LabTechnician:
              ret =
                findUserGroupRole?.Role === CoreUserRole.Admin ||
                findUserGroupRole?.Role === CoreUserRole.VSTechnician ||
                findUserGroupRole?.Role === CoreUserRole.ServiceTechnician ||
                findUserGroupRole?.Role === CoreUserRole.LabManager ||
                findUserGroupRole?.Role === CoreUserRole.LabTechnician;
              break;
            default:
              break;
          }
        }
      }
    }

    return ret;
  }

  hasLabTechnicianAccessLevel(): boolean {
    return this.existsMinAccessLevel(CoreUserRole.LabTechnician);
  }

  hasLabTechnicianAccessLevelForUserGroup(userGroupId?: string): boolean {
    return this.hasMinAccessLevelForUserGroup(CoreUserRole.LabTechnician, userGroupId);
  }

  hasLabManagerAccessLevel(): boolean {
    return this.existsMinAccessLevel(CoreUserRole.LabManager);
  }

  hasLabManagerAccessLevelForUserGroup(userGroupId?: string): boolean {
    return this.hasMinAccessLevelForUserGroup(CoreUserRole.LabManager, userGroupId);
  }

  hasServiceTechnicianAccessLevel(): boolean {
    return this.existsMinAccessLevel(CoreUserRole.ServiceTechnician);
  }

  hasServiceTechnicianAccessLevelForUserGroup(userGroupId?: string): boolean {
    return this.hasMinAccessLevelForUserGroup(CoreUserRole.ServiceTechnician, userGroupId);
  }

  hasVsTechnicianAccessLevel(): boolean {
    return this.existsMinAccessLevel(CoreUserRole.VSTechnician);
  }

  hasVsTechnicianAccessLevelForUserGroup(userGroupId?: string): boolean {
    return this.hasMinAccessLevelForUserGroup(CoreUserRole.VSTechnician, userGroupId);
  }

  hasAdminAccessLevel(): boolean {
    return this.IsAdmin;
  }

  /* Return unique virtual Laboratories regardless of the users role.
   */
  getVirtualLaboratories(): ICoreUserGroup[] {
    const groups = this.UserGroups;
    return groups.filter(
      (group, i) => groups.findIndex(otherGroup => otherGroup.UserGroupId === group.UserGroupId) === i
    );
  }
}
