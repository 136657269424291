import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { MsalCustomNavigationClient, MsalService } from '@azure/msal-angular';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';

// Custom navigation set for client-side navigation. See performance doc for details:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular/docs/v2-docs/performance.md
export class CustomNavigationClient extends MsalCustomNavigationClient {
  constructor(msalService: MsalService, router: Router, location: Location) {
    super(msalService, router, location);
  }

  async navigateExternal(url: string, options: any) {
    if (Capacitor.isNativePlatform()) {
      let successfulRedirect = false;

      const browser = InAppBrowser.create(url, '_blank', {
        location: 'no',
        hidenavigationbuttons: 'yes',
        zoom: 'no',
        usewkwebview: 'yes',
        toolbartranslucent: 'no',
        toolbarcolor: '#ffffff',
        disallowoverscroll: 'yes',
        toolbarposition: 'top',
      });
      browser.on('loadstart').subscribe(event => {
        if (event.url.includes(environment.b2cAuth.redirectUri)) {
          // Close the in app browser and redirect to localhost + the state parameter
          browser.close();

          let host = Capacitor.getPlatform() === 'android' ? 'http://localhost/' : 'capacitor://localhost/';
          if (environment.capacitorLiveReload?.url) {
            console.log('Using host on liveReload Server:', environment.capacitorLiveReload.url);
            host = environment.capacitorLiveReload.url;
          }
          const domain = event.url.split('#')[0];
          const url = event.url.replace(domain, host);
          successfulRedirect = true;
          window.location.replace(url);
        }
      });
      browser.on('exit').subscribe(() => {
        if (!successfulRedirect) {
          window.location.reload();
        }
      });
    } else {
      if (options.noHistory) {
        window.location.replace(url);
      } else {
        window.location.assign(url);
      }
    }
    return true;
  }
}
