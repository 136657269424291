import { ChangeDetectorRef, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { StringMatcher } from '../../model/matcher-types';
import { matchEmail } from './matcher/email.matcher';
import { matchNumbers } from './matcher/numbers.matcher';

type OnChange = (tags: string[]) => void;

@Component({
  selector: 'app-input-with-badges',
  templateUrl: './input-with-badges.component.html',
  styleUrls: ['./input-with-badges.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputWithBadgesComponent,
    },
  ],
})
export class InputWithBadgesComponent implements ControlValueAccessor {
  tags: string[] = [];

  @Input() id: string = '';
  @Input() placeholderKey = 'input-with-badge-placeholder';

  @ViewChild('inputField') inputField: ElementRef;
  @ViewChildren('badge') lastBadge?: QueryList<ElementRef>;

  @Input() disabled = false;
  @Input() size = 20;

  @Input() matchValidTag: StringMatcher | 'number' | 'email' = () => false;

  onChange: OnChange = () => {};

  onTouched = () => {};

  private validateInput(input: string) {
    if (this.matchValidTag === 'email') {
      return matchEmail(input);
    } else if (this.matchValidTag === 'number') {
      return matchNumbers(input);
    }
    return this.matchValidTag(input);
  }

  constructor(inputField: ElementRef, private changeDetector: ChangeDetectorRef) {
    this.inputField = inputField;
    this.tags = [];
  }

  addTag(untrimmed: string): void {
    const input = untrimmed?.trim() || '';
    if (!input) {
      return;
    }
    const found = this.tags.find(t => t === input);
    if (found) {
      return;
    }
    const newVal = [...this.tags, input];
    this.tags = newVal;
    this.inputField.nativeElement.value = '';
    this.onChange(this.tags);
  }

  removeTag(input: string): void {
    const trimmed = input?.trim() || '';
    this.tags = this.tags.filter(value => value !== trimmed.trim());
    this.onChange(this.tags);
    //change detection required to focus properly after removal
    this.changeDetector.detectChanges();
    this.focusLastBadge();
  }

  handleKeyDown(event: Event, key: 'space' | 'enter' | 'backspace'): void {
    const input = this.inputField.nativeElement.value;
    if (input) {
      event.preventDefault();
    }
    if (key === 'space' || key === 'enter') {
      if (this.validateInput(input)) {
        this.addTag(input);
      }
    } else if (key === 'backspace') {
      if (input) {
        this.inputField.nativeElement.value = input.slice(0, -1);
      } else if (this.lastBadge) {
        this.focusLastBadge();
      }
    }
  }

  handleBlur() {
    const input = this.inputField.nativeElement.value;
    if (this.validateInput(input)) {
      this.addTag(input);
    }
  }

  handleClose(input: string): void {
    this.removeTag(input);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(tags: string[]): void {
    this.tags = tags ? tags : [];
  }

  private focusLastBadge() {
    this.lastBadge?.forEach(el => {
      if (el.nativeElement.classList.contains('is-last')) {
        el.nativeElement.focus();
      }
    });
  }
}
