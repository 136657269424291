import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, retry, switchMap } from 'rxjs/operators';
import { b2cPolicies, loginScopes } from 'src/app/app.config';
import { SimpleModalComponent } from 'src/app/shared/component/simple-modal/simple-modal.component';
import { Subscriptions } from 'src/app/shared/util/Subscriptions';
import { environment } from 'src/environments/environment';
import { CurrentUserApi } from '../api/current-user-api.service';
import { CustomNavigationClient } from '../custom-navigation-client';
import { ICoreApiResult } from '../model/core-api-result.model';
import { CoreUser, ICoreUser } from '../model/core-user.model';
import { CurrentUser } from '../model/current-user.model';
import { CoreNavigationService } from './core-navigation.service';

export const MAX_NUMBER_OF_RETRIES = 3;

interface IdTokenClaims {
  tfp?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CoreLoginService implements OnDestroy {
  private getUserUrl: string =
    environment.backendApi.shared.uri +
    '/Login?code=' +
    environment.backendApi.shared.key +
    '&clientId=' +
    environment.backendApi.shared.id;
  
  private userBaseUrl: string = environment.backendApi.shared.uri + '/Users';

  private subscriptions = new Subscriptions();

  public currentUserSubject = new BehaviorSubject<CurrentUser | undefined>(undefined);
  private currentUserLegacySubject = new BehaviorSubject<CoreUser | undefined>(undefined);

  authInProgress$: Observable<unknown>;
  activeAccount: AccountInfo | null = null;

  constructor(
    private http: HttpClient,
    private msalService: MsalService,
    private navigationService: CoreNavigationService,
    private router: Router,
    private location: Location,
    private msalBroadcastService: MsalBroadcastService,
    private modalService: NgbModal,
    private transloco: TranslocoService,
    private currentUserApi: CurrentUserApi
  ) {
    this.authInProgress$ = msalBroadcastService.inProgress$.pipe(filter(status => status === InteractionStatus.None));
  }

  public get currentUser(): Observable<CurrentUser | undefined> {
    return this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): CurrentUser | undefined {
    return this.currentUserSubject.value;
  }

  public get currentUserLegacy(): Observable<CoreUser | undefined> {
    return this.currentUserLegacySubject.asObservable();
  }

  public get currentUserLegacyValue(): CoreUser | undefined {
    return this.currentUserLegacySubject.value;
  }

  handleAppStart(): void {
    if (this.subscriptions.count > 0) {
      //make sure we wire up the subscriptions just once
      return;
    }
    //required for mobile and for increasing msal performance
    this.msalService.instance.setNavigationClient(
      new CustomNavigationClient(this.msalService, this.router, this.location)
    );

    this.subscriptions.put(
      this.msalBroadcastService.inProgress$
        .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
        .subscribe(() => this.checkAndSetActiveAccount())
    );

    this.subscriptions.put(
      this.msalBroadcastService.msalSubject$
        .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.handleFinishPasswordResetFlow(payload);
          this.handleLoginSuccess(payload);
        })
    );

    this.subscriptions.put(
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) =>
              msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
          )
        )
        .subscribe((result: EventMessage) => this.handleForgotPasswordRedirect(result))
    );
  }

  logout(postLogoutRedirectUri?: string): void {
    localStorage.clear();
    if (postLogoutRedirectUri) {
      this.msalService.logout({ postLogoutRedirectUri: postLogoutRedirectUri });
    } else {
      this.msalService.logout();
    }
  }

  handleLoginSuccess(result: AuthenticationResult) {
    const claims = result.idTokenClaims as IdTokenClaims;
    if (claims.tfp === b2cPolicies.names.signIn) {
      this.loadUser();
    }
  }

  loadUser(): Observable<CoreUser | null> {
    const activeAccount = this.checkAndSetActiveAccount();
    if (this.currentUserLegacyValue) {
      return of(this.currentUserLegacyValue);
    } else {
      if (!activeAccount?.idTokenClaims?.oid) {
        return of(null);
      }
      return this.getUser(activeAccount.idTokenClaims.oid).pipe(
        switchMap(result => {
          if (!result.Success) {
            return of(null);
          }
          const getUser: ICoreUser = result.Data as ICoreUser;
          const username = activeAccount.username ?? '';
          this.currentUserLegacySubject.next(new CoreUser(getUser, username));
          return this.currentUserApi.getCurrentUser().pipe(
            map(user => {
              this.currentUserSubject.next(user);
              return this.currentUserLegacySubject.getValue()!;
            })
          );
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse && (error.status >= 500 || error.status < 600)) {
            const modalRef = this.modalService.open(SimpleModalComponent, {
              backdrop: 'static',
              keyboard: false,
            });
            Object.assign(modalRef.componentInstance, {
              title: this.transloco.translate('login-shared-server-error-title'),
              body: this.transloco.translate('login-shared-server-error-body', {
                status: error.status,
                statusText: error.statusText,
              }),
            });
          }

          return EMPTY;
        })
      );
    }
  }
  
  deleteAccount(): Observable<void> {
    return this.http.delete<void>(this.userBaseUrl);
  }
  
  restoreAccount(): Observable<void> {
    return this.http.get<void>(this.userBaseUrl + '/restore');
  }

  private getUser(oid: string): Observable<ICoreApiResult> {
    return this.http.post<ICoreApiResult>(this.getUserUrl, { ObjectId: oid }).pipe(retry(MAX_NUMBER_OF_RETRIES));
  }

  private checkAndSetActiveAccount(): AccountInfo | null {
    this.activeAccount = this.msalService.instance.getActiveAccount();
    if (this.activeAccount && CoreLoginService.isAccountExpired(this.activeAccount)) {
      this.silentLogin();
    }
    if (!this.activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const validAccounts = this.msalService.instance
        .getAllAccounts()
        .filter(account => !CoreLoginService.isAccountExpired(account))
        .filter(account => account?.idTokenClaims?.tfp == b2cPolicies.names.signIn || account?.idTokenClaims?.tfp == b2cPolicies.names.signUp);
      if (validAccounts.length > 0) {
        //assume we can just pick the first one
        const newAccount = validAccounts[0];
        this.msalService.instance.setActiveAccount(newAccount);
      } else {
        this.silentLogin();
      }
    }
    return this.msalService.instance.getActiveAccount();
  }

  private silentLogin(): void {
    this.msalService
      .ssoSilent({
        scopes: loginScopes,
      })
      .pipe(retry(MAX_NUMBER_OF_RETRIES))
      .subscribe({
        next: (result: AuthenticationResult) => {
          this.handleLoginSuccess(result);
        },
        error: (error: any) => {
          console.error('Silent login failed:', error);
          this.activeAccount = null;
          this.msalService.instance.setActiveAccount(null);
          this.currentUserLegacySubject.next(undefined);
          this.startLogin();
        },
      });
  }

  isLoggedIn(): boolean {
    this.checkAndSetActiveAccount();
    return !!this.msalService.instance.getActiveAccount() && this.currentUserLegacySubject.getValue !== null;
  }

  startLogin(): void {
    this.msalService
      .loginRedirect({
        scopes: [],
      })
      .pipe(retry(MAX_NUMBER_OF_RETRIES))
      .subscribe({
        error: () => {
          this.navigationService?.navigateAfterError();
        },
      });
  }

  startAccountCreation(): void {
    const signUpRequest: RedirectRequest = {
      scopes: [],
      authority: b2cPolicies.authorities.signUp.authority,
      redirectUri: environment.b2cAuth.redirectUri + '/home'
    };
    this.msalService
      .loginRedirect(signUpRequest)
      .pipe(retry(MAX_NUMBER_OF_RETRIES))
      .subscribe({
        error: e => {
          console.log(e);
          this.navigationService?.navigateAfterError();
        },
      });
  }

  private handleFinishPasswordResetFlow(result: AuthenticationResult): void {
    const claims = result.idTokenClaims as IdTokenClaims;
    if (claims.tfp === b2cPolicies.names.signUp) {
      this.checkPasswordResetFlow();
    }
  }

  checkPasswordResetFlow() {
    // const resetPwAccounts = this.msalService.instance
    //   .getAllAccounts()
    //   .filter(info => info?.idTokenClaims?.tfp === b2cPolicies.names.signUp);
    // if (resetPwAccounts.length > 0) {
    //   if (this.router.getCurrentNavigation()) {
    //     this.router.events
    //       .pipe(
    //         filter(event => event instanceof NavigationEnd),
    //         first()
    //       )
    //       .subscribe(() => {
    //         this.modalService
    //           .open(signUpDialogComponent, {
    //             backdrop: 'static',
    //             keyboard: false,
    //           })
    //           .result.then(() => this.logout('/login'));
    //       });
    //   } else {
    //     this.modalService
    //       .open(signUpDialogComponent, {
    //         backdrop: 'static',
    //         keyboard: false,
    //       })
    //       .result.then(() => this.logout('/login'));
    //   }
    // }
  }
  
  resetPassword(): Observable<void> {
    const passwordResetRequest: RedirectRequest = {
      authority: environment.b2cAuth.resetPasswordAuthority,
      scopes: ['openid'],
      redirectUri: environment.b2cAuth.redirectUri,
    };
    
    return this.msalService.loginRedirect(passwordResetRequest);
  }
  

  handleForgotPasswordRedirect(result: EventMessage) {
    // using legacy password reset
    // see https://docs.microsoft.com/en-us/azure/active-directory-b2c/add-password-reset-policy?pivots=b2c-user-flow#self-service-password-reset-recommended
    const error = result.error?.message || '';
    if (error.indexOf('AADB2C90118') > -1) {
      this.startAccountCreation();
    }
  }

  private static isAccountExpired(account: AccountInfo): boolean {
    const expires = account?.idTokenClaims?.exp;
    return !!expires && expires < Math.floor(new Date().getTime() / 1000);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
