import { Component, Input } from '@angular/core';

export interface StackedBarDataInput {
  label: string,
  value: number,
  legendTitle: string,
  backgroundColor: string;
  color?: string,
}

export interface StackedBarData extends StackedBarDataInput {
  zIndex: number,
  percentage: number,
}

@Component({
  selector: 'app-stacked-bar',
  templateUrl: './stacked-bar.component.html',
  styleUrls: ['./stacked-bar.component.scss']
})
export class StackedBarComponent {

  private _stackedBarData: Array<StackedBarDataInput> = [];
  private _filteredStackedBarData: Array<StackedBarData> = [];
  private _excludedData: Array<string> = [];
  
  @Input() set stackedBarData(data: Array<StackedBarDataInput>) {
    this._stackedBarData = data;
    this.calculateStackedBarData();
  };
  
  get stackedBarData(): Array<StackedBarDataInput> {
    return this._stackedBarData;
  }
  
  get filteredStackedBarData(): Array<StackedBarData> {
    return this._filteredStackedBarData;
  }
  
  get excludedData(): Array<string> {
    return this._excludedData;
  }
  
  calculateStackedBarData(): Array<StackedBarData> {
    const filteredInput: Array<StackedBarDataInput> =
      this._stackedBarData.filter(data => !this._excludedData.find(_data => _data === data.legendTitle));
    
    const valSum = filteredInput.reduce((accumulator, currentData) => accumulator + currentData.value, 0)
    let maxZIndex = filteredInput.length;
    
    return this._filteredStackedBarData = filteredInput.map(_data => ({
      ..._data,
      zIndex: maxZIndex--,
      percentage: _data.value * 100 / valSum,
    }));
  }
  
  toggleDisabledState(data: StackedBarDataInput): void {
    const dataIndex = this._excludedData.indexOf(data.legendTitle);
    if (dataIndex !== -1) {
      this._excludedData.splice(dataIndex, 1);
    } else {
      this._excludedData.push(data.legendTitle);
    }
    
    this.calculateStackedBarData();
  }
}
