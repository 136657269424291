export enum CoreUserRole {
  Admin = 'Admin',
  VSTechnician = 'VSTechnician',
  ServiceTechnician = 'ServiceTechnician',
  LabManager = 'LabManager',
  LabTechnician = 'LabTechnician',
}

export const CoreUserRoleUser2LableMapping: Record<CoreUserRole, string> = {
  [CoreUserRole.Admin]: 'administrator',
  [CoreUserRole.VSTechnician]: 'vs-technician',
  [CoreUserRole.ServiceTechnician]: 'service-technician',
  [CoreUserRole.LabManager]: 'virtual-laboratory.user-assignments.role.enum.LAB_MANAGER',
  [CoreUserRole.LabTechnician]: 'virtual-laboratory.user-assignments.role.enum.LAB_TECHNICIAN',
};
