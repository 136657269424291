import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CoreLoginService } from './core-login.service';

declare let gtag: (a: string, b: string, c: object) => void;

@Injectable({
  providedIn: 'root',
})
export class UserTrackingService {
  constructor(
    private router: Router,
    private loginService: CoreLoginService,
    @Inject('googleTagManagerId') private googleTagManagerId: string
  ) {}

  initializeTracking() {
    this.inititalizeGoogleAnalytics();
    if (environment.appInsights?.enabled || false) {
      this.inititalizeApplicationInsights();
    }
  }

  private inititalizeApplicationInsights() {
    var angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsights?.instrumentationKey || '',
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
      },
    });
    appInsights.loadAppInsights();

    this.loginService.currentUser.subscribe(user => {
      if (user) {
        appInsights.setAuthenticatedUserContext(user.id, undefined, true);
      } else {
        appInsights.clearAuthenticatedUserContext();
      }
    });
  }

  private inititalizeGoogleAnalytics() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => event as NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        const tenantAssignments = this.loginService.currentUserValue?.tenantAssignments;
        gtag('config', this.googleTagManagerId, {
          page_path: event.urlAfterRedirects,
          user_id: (tenantAssignments?.length || 0) > 0 ? tenantAssignments![0].tenantId : null,
        });
      });
  }
}
