import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgTemplateOutlet } from '@angular/common';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '../../shared.module';

export interface Option {
  value: any;
  label: string;
}

@Component({
  selector: 'app-radio-dropdown',
  standalone: true,
  imports: [
    FormsModule,
    NgTemplateOutlet,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    TranslocoModule,
    SharedModule
  ],
  templateUrl: './radio-dropdown.component.html',
  styleUrl: './radio-dropdown.component.scss'
})
export class RadioDropdownComponent {
  
  @Input() placeholderKey = 'select-a-value';
  @Input() name = '';
  @Input() options: Option[] = [];
  @Input() selected: string | undefined;
  @Output() selectedChange = new EventEmitter();
  
  get selectedOption(): Option | undefined {
    return this.options.find(option => option.value === this.selected);
  }
  
  disabled = false;
  
  onSelectedChange(event: any) {
    this.selectedChange.emit(event.target.value);
  }
}
