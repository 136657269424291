<select class="custom-select pl-0" id="filterGroup" (change)="handleSelectionChange($event)" [disabled]="isDisabled">
  @for (group of options; track group) {
    @if (group.options.length) {
      <optgroup label="{{ group.title.name }}">
        @for (opt of group.options; track opt) {
          <option
            value="{{ opt.value }}"
            [selected]="opt.checked"
            class="{{ opt.checked ? 'selected' : '' }}">
            {{ opt.name }}
          </option>
        }
      </optgroup>
    }
  }
</select>
