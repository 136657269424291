import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { ButtonType } from '../button/button.component';

@Component({
  selector: 'app-pagination-new',
  templateUrl: './pagination-new.component.html',
  styleUrl: './pagination-new.component.scss'
})
export class PaginationNewComponent implements OnInit {

  @Input() totalPages: number = 1;
  @Input() currentPage: number = 1;
  
  @Output() pageChange: EventEmitter<number> = new EventEmitter();
  
  protected readonly anglesLeft = faAnglesLeft;
  protected readonly anglesRight = faAnglesRight;
  protected readonly iconType = ButtonType.VENDOR;
  
  pageNumbers: Array<number> = [];
  
  ngOnInit() {
    this.pageNumbers = this.getPageNumbers();
  }
  
  getPageNumbers(): number[] {
    if (this.totalPages <= 3) {
      // If there are 3 or fewer pages, display all pages
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    } else if (this.currentPage === 1) {
      // If on the first page, display [1, 2, 3]
      return [1, 2, 3];
    } else if (this.currentPage === this.totalPages) {
      // If on the last page, display the last three pages
      return [this.totalPages - 2, this.totalPages - 1, this.totalPages];
    } else {
      // Otherwise, display [currentPage-1, currentPage, currentPage+1]
      return [this.currentPage - 1, this.currentPage, this.currentPage + 1];
    }
  }
  
  previousHandler = async (): Promise<void> => {
    return new Promise(resolve => {
      this.goToPage(this.currentPage - 1);
      resolve();
    });
  }
  
  nextHandler = async (): Promise<void> => {
    return new Promise(resolve => {
      this.goToPage(this.currentPage + 1);
      resolve();
    });
  }
  
  goToPage(page: number): void {
    if (page < 1) {
      page = 1;
    } else if (page > this.totalPages) {
      page = this.totalPages;
    }
    this.pageChange.emit(page);
    this.currentPage = page;
    this.pageNumbers = this.getPageNumbers();
  }
  
}
