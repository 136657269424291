import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { CoreUser } from './core/model/core-user.model';
import { AppStateService } from './core/service/app-state.service';
import { BarcodeScannerService } from './core/service/bar-scanner.service';
import { CoreLoginService } from './core/service/core-login.service';
import { CoreNavigationService } from './core/service/core-navigation.service';
import { MobileEventsService } from './core/service/mobile-events.service';
import { PushNotificationService } from './core/service/push-notification.service';
import { RoutingStateService } from './core/service/routing-state.service';
import { UserTrackingService } from './core/service/user-tracking.service';
import { DeviceAvailableFirmwareUpdatesNotificationService } from './device/device-shared/service/device-available-firmware-updates-notification.service';
import { Subscriptions } from './shared/util/Subscriptions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscriptions = new Subscriptions();

  scanActive = false;

  herf: string = '';

  isIframe = false;
  appVersion = 'v' + environment.appVersion;
  @ViewChild('dropdownButton') dropdownButton?: ElementRef;
  sidebarOpen = false;
  user: CoreUser | undefined;
  routingInProgress$: Observable<boolean>;

  constructor(
    private router: Router,
    private loginService: CoreLoginService,
    private navigationService: CoreNavigationService,
    private appStateService: AppStateService,
    mobileEventsService: MobileEventsService,
    pushNotificationService: PushNotificationService,
    routingState: RoutingStateService,
    deviceAvailableFirmwareUpdateNotificationService: DeviceAvailableFirmwareUpdatesNotificationService,
    userTrackingService: UserTrackingService,
    private barcodeScanner: BarcodeScannerService
  ) {
    this.routingInProgress$ = routingState.loading$;
    mobileEventsService.initEventHandlers();
    pushNotificationService.registerDevice();
    deviceAvailableFirmwareUpdateNotificationService.initialize();
    userTrackingService.initializeTracking();

    this.subscriptions.put(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(_ => {
        this.appStateService.isSideBarOpen.value = false;
        this.herf = location.pathname;
      })
    );

    this.subscriptions.put(
      this.appStateService.isSideBarOpen.getUpdates().subscribe(val => {
        this.sidebarOpen = val;
      })
    );

    this.subscriptions.put(this.barcodeScanner.isScanning$.subscribe(isScanning => (this.scanActive = isScanning)));
  }

  changeSideBarOpen(): void {
    const oldVal = this.appStateService.isSideBarOpen.value;
    this.appStateService.isSideBarOpen.value = !oldVal;
  }

  async ngOnInit(): Promise<void> {
    this.isIframe = window !== window.parent && !window.opener;
    this.subscriptions.put(
      this.loginService.currentUserLegacy.subscribe(user => {
        this.user = user;
        this.subscriptions.put(
          this.loginService.currentUser.subscribe(user => {
            if (
              user?.tenantAssignments.length === 0 &&
              user?.virtualLaboratoryAssignments.length === 0 &&
              !this.user?.IsAdmin
            ) {
              this.router.navigate(['/no-access']);
            }
          })
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.appStateService?.unsubscribe();
  }

  onPrevious(): void {
    this.navigationService.previous();
  }
}
