<ng-container *transloco="let t">
  <div class="card h-100">
    <div class="card-header">
      <h2 class="card-title d-flex justify-content-start align-items-center">
        <i class="icon icon-gears mr-6"></i>
        <span>{{ t('counters') }}</span>
      </h2>
<!--      Hidden until page is implemented -->
<!--      <button class="btn btn-vendor">{{ t('learn-more') }}</button>-->
    </div>
    <div class="card-body">
      <div class="mb-3">{{ t('total-operating-hours') }} <b class="ml-2 d-inline-block">{{ device.statistics.operationalHours }}h</b></div>
      @if (stackedBarData?.length > 0) {
        <app-stacked-bar [stackedBarData]="stackedBarData"></app-stacked-bar>
      } @else {
        <div class="empty-state">
          <span class="text-center">{{ t('operating-hours.missing') }}</span>
        </div>
      }
      @if (measurementsByType) {
        <div class="d-flex flex-column mt-10">
          @for(stat of measurementsByType; track stat) {
            <div class="d-flex align-items-center mb-3">
              <ng-container>
                <div class="stat-label">{{ stat.type }}</div>
                <div class="dots w-100 mx-4"></div>
                <b class="stat-points">{{ stat.value }}</b>
              </ng-container>
            </div>
          }
        </div>
      }
      <div class="d-flex flex-column justify-content-center align-items-start mt-3">
        <b class="text-nowrap mt-5">{{ t('last-backup') }}: {{ (lastBackup | date: "dd.MM.YYYY") || 'N/A' }}</b>
        <small class="mb-3 text-muted">{{ t('total-operating-hours.disclaimer') }}</small>
      </div>
    </div>
  </div>
</ng-container>
