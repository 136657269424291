import {
  DeviceConnectionStatusType,
  DeviceNotificationSummaryAvailableNotificationsSummary,
  DeviceOperationalStatusType,
  DeviceSummary,
} from 'src/app/device/device-shared/model/device.model';
import { Comparator, Compared } from '../../../shared/pipe/helper/Comparator';

const STATUS_SORT_ORDER: { [key in DeviceConnectionStatusType]: number } = {
  CONNECTED: 1,
  DISCONNECTED: 2,
};

export class SortByStatus implements Comparator<DeviceSummary> {
  compare(itemA: DeviceSummary, itemB: DeviceSummary): Compared {
    const typeA = itemA.status.connection.type;
    const typeB = itemB.status.connection.type;
    return Math.sign(STATUS_SORT_ORDER[typeA] - STATUS_SORT_ORDER[typeB]) as Compared;
  }
}

export class SortByNotification implements Comparator<DeviceSummary> {
  constructor(private typeOfNotification: keyof DeviceNotificationSummaryAvailableNotificationsSummary) {}

  compare(itemA: DeviceSummary, itemB: DeviceSummary): Compared {
    return Math.sign(
      itemB.notificationSummary.available[this.typeOfNotification] -
        itemA.notificationSummary.available[this.typeOfNotification]
    ) as Compared;
  }
}

// malfunctional > waiting for user > active > initializing > idle
const OPERATIONAL_STATUS_SORT_ORDER: {
  [key in DeviceOperationalStatusType]: number;
} = {
  MALFUNCTIONAL: 1,
  WAITING_FOR_USER: 2,
  ACTIVE: 3,
  INITIALIZING: 4,
  IDLE: 5,
  NOT_OPERATIONAL: 6,
};

export class SortByDeviceOperationalStatus implements Comparator<DeviceSummary> {
  compare(itemA: DeviceSummary, itemB: DeviceSummary): Compared {
    const typeA = itemA.status.operational?.type;
    const typeB = itemB.status.operational?.type;

    if (typeA === undefined && typeB === undefined) {
      return 0;
    } else if (typeA === undefined) {
      return 1;
    } else if (typeB === undefined) {
      return -1;
    }

    return Math.sign(OPERATIONAL_STATUS_SORT_ORDER[typeA] - OPERATIONAL_STATUS_SORT_ORDER[typeB]) as Compared;
  }
}

export class SortByDeviceId {
  compare(itemA: DeviceSummary, itemB: DeviceSummary) {
    const typeA = itemA.id;
    const typeB = itemB.id;

    return typeA.localeCompare(typeB) as Compared;
  }
}
