import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { CardGridItem } from '../card-grid/card-grid.component';

export interface Category {
  id: string;
  label: string;
}

export interface Item extends CardGridItem {}

@Component({
  selector: 'app-categorized-card-grid',
  templateUrl: './categorized-card-grid.component.html',
  styleUrls: ['./categorized-card-grid.component.scss'],
})
export class CategorizedCardGridComponent<T extends Item> implements OnInit, OnChanges {
  @Input() contentTemplate: TemplateRef<any> | null = null;

  @Input() categories?: Category[];
  @Input() itemsByCategory?: Map<string, T[]>;

  @Output() itemClickedEvent: EventEmitter<T> = new EventEmitter();

  categoryHeaderVisible: boolean = false;

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categories && !changes.categories.isFirstChange()) {
      this.refresh();
    }
  }

  private refresh() {
    this.categoryHeaderVisible = (this.categories?.length || 0) > 1;
  }

  onItemClicked(item: T) {
    this.itemClickedEvent.emit(item);
  }

  findItemsByCategory(category: Category): T[] {
    return this.itemsByCategory?.get(category.id) || [];
  }
}
