import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Subscriptions } from '../../util/Subscriptions';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

export interface Option {
  category?: string;
  value: any;
  label: string;
}

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiselectComponent,
    },
  ],
})
export class MultiselectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private subscriptions: Subscriptions = new Subscriptions();

  @Input() placement: PlacementArray = 'bottom-right'
  @Input() placeholderKey = 'select-a-value';
  @Input() id: string | undefined;
  @Input() label: string | undefined;
  
  placeholder = '';

  labelPlacholder = '';

  @Input() name = '';
  @Input()
  set options(value: Option[]) {
    this._options = value;
    this.updateAllSelected();
    this.setDisabledState(value.length === 0);
  }

  get uncategorizedOptions(): Option[] {
    return [...this._options.filter(option => !option.category)];
  }

  optionCategories(): string[] {
    return Array.from(this.getCategorizedOptions().keys()).sort((a, b) => a.localeCompare(b));
  }

  categorizedOptions(category: string): Option[] {
    return [...(this.getCategorizedOptions().get(category) || [])];
  }

  private getCategorizedOptions(): Map<string, Option[]> {
    return this._options.reduce((result, option) => {
      if (!option.category) {
        return result;
      }

      const category = option.category!;

      if (result.has(category)) {
        result.get(category)!.push(option);
      } else {
        result.set(category, [option]);
      }

      return result;
    }, new Map<string, Option[]>());
  }

  get selected(): Option[] {
    return this._options.filter(option => this._selected.includes(option.value));
  }

  _options: Option[] = [];
  _selected: any[] = [];
  allSelected = false;
  touched = false;
  disabled = false;

  constructor(private translocoService: TranslocoService) {}

  onChange: (selected: any[]) => void = () => {};
  onTouched: () => void = () => {};

  ngOnInit(): void {
    this.subscriptions.put(
      this.translocoService.langChanges$.subscribe(() => {
        this.placeholder = this.translocoService.translate(this.placeholderKey);
        this.labelPlacholder = this.translocoService.translate('unkown-value');
      })
    );
  }

  toggleSelectAll(allSelected: boolean): void {
    this.allSelected = allSelected;
    this.markAsTouched();
    this._selected = [];
    if (this.allSelected) {
      this._selected.push(...this._options.map(option => option.value));
    }
    this.onChange(this._selected);
  }

  selectOption(option: Option): void {
    this.markAsTouched();
    const index = this._selected.indexOf(option.value);
    if (index > -1) {
      this._selected.splice(index, 1);
    } else {
      this._selected.push(option.value);
    }
    this.onChange(this._selected);
    this.updateAllSelected();
  }

  updateAllSelected() {
    this.allSelected = this.selected.length === this._options.length;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(value: any[]): void {
    this._selected = value ?? [];
    this.updateAllSelected();
  }

  registerOnChange(onChange: (value: any) => any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
