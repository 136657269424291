import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToggleSidebarService {
  private urls: string[] = ['/devices', '/tenants', '/virtual-laboratories', '/404', '/user-settings'];
  constructor() {}

  hasSideNavigation(url: string): boolean {
    return this.urls.includes(url);
  }
}
