import { Component, Input } from '@angular/core';
import { ErrorType, MessageInfo } from '../../model/errors';

@Component({
  selector: 'app-input-info-message',
  templateUrl: './input-info-message.component.html',
})
export class InputInfoMessageComponent {
  @Input() show: boolean = false;
  @Input() message!: MessageInfo;

  get isValidFeedback() {
    return this.message.type === ErrorType.INFO;
  }
}
