import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStateService } from '../../../core/service/app-state.service';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
})
export class NavigationItemComponent implements OnInit {
  @Input() routerLink?: string;
  @Input() routerLinkActive?: string | string[];
  @Input() routerLinkActiveOptions: { exact: boolean } = { exact: false };
  @Input() iconName!: string;
  @Input() title!: string;
  @Input() clickable: boolean = false;
  @Output() handleClick: EventEmitter<void> = new EventEmitter();

  isFontAwesomeIcon: boolean = false;

  constructor(private appStateService: AppStateService) {}

  navigateClick(): void {
    if (this.clickable) {
      this.handleClick.emit();
    } else {
      const isSideBarOpen = this.appStateService.isSideBarOpen;
      isSideBarOpen.value = false;
    }
  }

  ngOnInit(): void {
    this.isFontAwesomeIcon = this.iconName?.startsWith('fa-');
  }
}
