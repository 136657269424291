<div class="pagination d-flex justify-content-center align-items-center">
  <div>
    <app-button
      [icon]="anglesLeft"
      [buttonClass]="iconType"
      [handler]="previousHandler">
    </app-button>
  </div>

  @for (page of getPageNumbers(); track page) {
    <div
      class="page-counter"
      (click)="goToPage(page)"
      [class.active]="page === currentPage">
      {{ page }}
    </div>
  }

  <div>
    <app-button
      [icon]="anglesRight"
      [buttonClass]="iconType"
      [handler]="nextHandler">
    </app-button>
  </div>
</div>
