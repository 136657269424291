import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DeviceAction } from '../../model/device.model';
import { first } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-async-button',
  templateUrl: './async-button.component.html',
  styleUrls: ['./async-button.component.scss'],
})
export class AsyncButtonComponent implements OnInit {
  @Input() deviceAction!: DeviceAction;
  @Input() customClass: string = '';

  @ViewChild('successModal') successModal!: TemplateRef<any>;
  @ViewChild('errorModal') errorModal!: TemplateRef<any>;

  isTriggered = false;
  inProgress = false;
  isSuccess = false;
  isError = false;
  label: string = '';
  errorMessage?: string;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
    this.label = this.deviceAction.label;
  }

  click(): void {
    if (!this.isTriggered) {
      this.triggerAction();
    } else if (this.inProgress) {
      // do nothing
    } else {
      this.openDialog();
    }
  }

  openDialog() {
    const dialog = this.isSuccess && !this.isError ? this.successModal : this.errorModal;
    this.modalService.open(dialog, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  triggerAction() {
    this.inProgress = true;
    this.isTriggered = true;
    this.deviceAction
      .action()
      .pipe(first())
      .subscribe(
        () => {
          this.isSuccess = true;
          this.inProgress = false;
        },
        err => {
          this.isError = true;
          this.inProgress = false;
          this.errorMessage = err.error;
        }
      );
  }
}
