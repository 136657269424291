import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Option } from '../multiselect/multiselect.component';
import { TranslocoService } from '@ngneat/transloco';
import { Subscriptions } from '../../util/Subscriptions';
import { MessageInfo } from '../../model/errors';

@Component({
  selector: 'app-select',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectFiledComponent),
    },
  ],
})
export class SelectFiledComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private subscriptions: Subscriptions = new Subscriptions();

  @Input() inputId: string = '';

  @Input() label: string = '';
  labelKey: string = '';

  @Input() placeholder: string = '';
  placeholderKey: string = '';

  @Input() emptyOption: string = 'select-a-value';

  @Input() selectClass: string = 'select-field';

  @Input() isValid: boolean = true;
  @Input() messageInfo?: MessageInfo;

  @Input() options: Option[] = [];
  optionKeys: Option[] = [];

  disabled?: boolean;

  @Input() selected: Option | undefined;

  @Input() translateOptions: boolean = false;

  @Output() changeInternal = new EventEmitter<Option>();

  constructor(private translocoService: TranslocoService) {}

  onChangeFormHandler = (_: any) => {};
  onTouch = (_: any) => {};

  registerOnChange(fn: any): void {
    if (fn) this.onChangeFormHandler = fn;
  }

  registerOnTouched(fn: any): void {
    if (fn) this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.placeholderKey = this.placeholder;
    this.labelKey = this.label;
    this.optionKeys = this.options;
    this.subscriptions.put(
      this.translocoService.langChanges$.subscribe(() => {
        if (this.placeholderKey) {
          this.placeholder = this.translateOptions
            ? this.translocoService.translate(this.placeholderKey)
            : this.placeholderKey;
        }
        if (this.labelKey) {
          this.label = this.translocoService.translate(this.labelKey);
        }
        if (this.optionKeys?.length > 0) {
          this.options = this.optionKeys.map(option => {
            return {
              value: option.value,
              label: this.translateOptions ? this.translocoService.translate(option.label) : option.label,
            } as Option;
          });
        }
      })
    );
  }

  get hasLabel(): boolean {
    return this.label?.length > 0;
  }

  isSelectedOption(option: Option): boolean {
    return this.selected?.value && this.selected?.value === option?.value;
  }

  writeValue(value?: string): void {
    const isEmptySelection = !value || value === this.emptyOption;
    this.selected = isEmptySelection
      ? undefined
      : {
          value: value,
          label: '',
        };
    this.onChangeFormHandler(value);
    this.changeInternal.emit(isEmptySelection ? undefined : this.selected);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
