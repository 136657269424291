import { Component } from '@angular/core';
import { CoreLoginService } from '../../service/core-login.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  constructor(private loginService: CoreLoginService) {}

  startLogin(): void {
    this.loginService.startLogin();
  }

  startAccountCreation(): void {
    this.loginService.startAccountCreation();
  }
}
