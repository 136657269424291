<ng-container *transloco="let t">
  <div class="card h-100">
    <div class="card-header">
      @if (!data?.active) {
        <i class="icon icon-status"></i>
      } @else {
        <i class="icon icon-status anim-spin"></i>
      }
      <h5 class="card-title">{{ t('status') }}</h5>
    </div>

    <div class="card-body">
      @if (data) {
        @if (data.operationalStatusKnown) {
          <div>
            @if (!data.active) {
              <div class="container">
                @if (!data.message) {
                  <span class="text-muted">{{ t('idle') }}</span>
                }
                @if (!!data.message) {
                  <span class="text-muted">{{ data.message }}</span>
                }
              </div>
            }
            @if (data.active) {
              <div>
                @if (!!data.message) {
                  <div class="progress-message">
                    {{ data.message }}
                  </div>
                }
                <app-device-operational-status-progress-bar
                  [height]="'25px'"
                  [progressInformation]="data.progressInformation">
                </app-device-operational-status-progress-bar>
                <div class="progress-info-text text-muted">
                  <app-operational-status-progress-info-text [progressInformation]="data.progressInformation">
                  </app-operational-status-progress-info-text>
                </div>
              </div>
            }
          </div>
        } @else {
          <span class="text-muted">{{ t('unknown') }}</span>
        }
      } @else {
        <span class="text-muted">{{ t('unknown') }}</span>
      }

    </div>
  </div>
</ng-container>
