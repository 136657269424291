import { Platform } from '@angular/cdk/platform';
import { Observable, lastValueFrom } from 'rxjs';
import { DeviceFile } from '../../../device-shared/model/device.model';
import { SharedDeviceFileService } from '../../service/shared-device-files.service';
import { Component, OnInit, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-device-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent implements OnInit {
  @Input() deviceId!: string;
  @Input() filetype!: string;
  @Input() scope?: string;

  files$!: Observable<DeviceFile[]>;
  openInNewWindow = false;
  locale$?: Observable<string>;

  constructor(
    private service: SharedDeviceFileService,
    private platform: Platform,
    private translocoService: TranslocoService
  ) {
    this.locale$ = translocoService.langChanges$;
  }

  ngOnInit(): void {
    this.files$ = this.service.getFiles(this.deviceId, this.filetype, this.scope);
    this.openInNewWindow = this.platform.FIREFOX || this.platform.SAFARI;
  }

  async downloadFile(fileId: string): Promise<void> {
    const fileUrl = await lastValueFrom(
      this.service.getFileDownloadUrl(this.deviceId, this.filetype, fileId, this.scope)
    );
    window.open(fileUrl, '_blank');
  }

  downloadParams(fileId: string): {
    deviceId: string;
    filetype: string;
    fileId: string;
    scope: string | undefined;
  } {
    return {
      deviceId: this.deviceId,
      filetype: this.filetype,
      fileId,
      scope: this.scope,
    };
  }
}
