import { Injectable } from '@angular/core';

export enum SessionKey {
  adminUserTextListFilter = 'adminUserTextListFilter',
  deviceFirmwareOverviewSelectedVendors = 'device-firmware-overview.selected-vendors',
  tenantDetailVirtualLaboratoryOverviewSelectedVirtualLaboratories = 'tenant-detail.virtual-laboratory-overview.selected-virtual-laboratories',
  virtualLaboratoryOverviewSelectedTenants = 'virtual-laboratory-overview.selected-tenants',
  virtualLaboratoryDetailDeviceOverviewSelectedDevices = 'virtual-laboratory-detail.device-overview.selected-devices',
  pairingDeviceToken = 'pairing-device-token',
  defaultDashboard = 'default-dashboard',
  defaultListSetting = 'default-list-setting',
  pinnedDevice = 'pinned-device',
}

@Injectable({
  providedIn: 'root',
})
export class SessionManagerService {
  get(key: SessionKey): string | undefined {
    const item = sessionStorage.getItem(key.valueOf());
    return item ? item : undefined;
  }

  set(key: SessionKey, value: string): void {
    sessionStorage.setItem(key.valueOf(), value);
  }

  clear(key: SessionKey): void {
    sessionStorage.removeItem(key.valueOf());
  }
}
