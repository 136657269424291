import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Subscriptions } from 'src/app/shared/util/Subscriptions';

export enum ThemeVendor {
  VERDER,
  RETSCH,
  MICROTRAC,
  QATM,
  ELTRA,
  CARBOLITE,
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService implements OnDestroy {
  private _vendor = new BehaviorSubject(ThemeVendor.VERDER);

  private subscriptions = new Subscriptions();

  public readonly vendor$ = this._vendor.asObservable();

  constructor(router: Router) {
    const routerEvents$: Observable<NavigationEnd> = router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(val => val as NavigationEnd)
    );
    this.subscriptions.put(
      routerEvents$.subscribe((event: NavigationEnd) => {
        this.setVendorForUrl(event.url);
      })
    );
  }

  private setVendorForUrl(url: string): void {
    const mapVendor = (value: string) => {
      switch (value) {
        case 'CARBOLITE':
          return ThemeVendor.CARBOLITE;
        case 'ELTRA':
          return ThemeVendor.ELTRA;
        case 'MICROTRAC':
          return ThemeVendor.MICROTRAC;
        case 'QATM':
          return ThemeVendor.QATM;
        case 'RETSCH':
          return ThemeVendor.RETSCH;
        default:
          return ThemeVendor.VERDER;
      }
    };

    const vendorScopePrefixPattern = /^\/(devices|product\-information)\/(CARBOLITE|ELTRA|MICROTRAC|QATM|RETSCH)/;

    const matchResult = vendorScopePrefixPattern.exec(url);

    if (Array.isArray(matchResult) && matchResult.length === 3) {
      this._vendor.next(mapVendor(matchResult[2]));
    } else {
      this._vendor.next(ThemeVendor.VERDER);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribeAll();
  }
}
