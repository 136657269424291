import { Injectable, signal } from '@angular/core';
import { DeviceResult, DeviceSummaryCollectionResult } from '../api/shared-device-api.service';

@Injectable({
  providedIn: 'root',
})
export class SharedDeviceStateService {
  device = signal<DeviceResult | undefined>(undefined);
  allDevices = signal<DeviceSummaryCollectionResult | undefined>(undefined);
}
