import { Directive, ElementRef } from '@angular/core';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appModalContainer]',
})
export class ModalContainerDirective {
  constructor(element: ElementRef, modalConfig: NgbModalConfig) {
    modalConfig.container = element.nativeElement;
    modalConfig.centered = true;
  }
}
