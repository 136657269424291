<ng-container *transloco="let t">
  <div class="modal-body">
    <div class="h-50">
      <app-loading-indicator></app-loading-indicator>
      <div class="d-flex justify-content-center">
        <p>{{ t(loadingHintKey) }}</p>
      </div>
    </div>
  </div>
</ng-container>
