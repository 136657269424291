<button
  type="button"
  class="btn {{ buttonClass }} "
  [disabled]="isDisabled | async"
  (mousedown)="mousedown($event)"
  (touchstart)="mousedown($event)"
  (click)="stopIt($event)">
  @if (isWorkInProgress) {
    <span class="pr-1">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </span>
  }
  @if (icon) {
    <fa-icon [icon]="icon" class="standalone-icon"></fa-icon>
  }
  @if (title) {
    <span>{{ title }}</span>
  }
</button>
