import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ButtonType, LongRunningTask } from '../button/button.component';
import { Step } from '../dialog/dialog.component';
import { Action } from '../card-header/card-header';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['../basic-dialog/basic-dialog.component.scss'],
})
export class NotificationDialogComponent {
  @Input() title: string;
  @Input() description: string;

  @Input() handler?: LongRunningTask;
  isAddingInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private activeModal: NgbActiveModal, private translocoService: TranslocoService) {
    this.title = translocoService.translate('notification');
    this.description = translocoService.translate('notification-received');
  }

  private confirmDialog(): void {
    if (this.handler) {
      this.isAddingInProgress.next(true);
      this.handler()
        .then(() => {
          this.activeModal.close(true);
          this.isAddingInProgress.next(false);
        })
        .catch(() => {
          this.activeModal.close(false);
        });
    } else {
      this.activeModal.close(true);
    }
  }

  get steps(): Step[] {
    return [
      {
        actions: [
          new Action({
            title: this.translocoService.translate('ok'),
            handler: async (): Promise<void> => {
              return new Promise(resolve => {
                this.confirmDialog();
                resolve();
              });
            },
            isEnabled: () => true,
            isWorking: (): boolean => this.isAddingInProgress.getValue(),
            type: ButtonType.PRIMARY,
          }),
        ],
      },
    ];
  }
}
