import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { Capacitor } from '@capacitor/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { LoadingIndicatorModalComponent } from '../../shared/component/loading-indicator-modal/loading-indicator-modal.component';
import { BarcodeParserService } from '../../shared/service/barcode-parser.service';
import { isValidHttpUrl } from '../../shared/util/http-utils';
import { AppStateService } from './app-state.service';
import { CoreNavigationService } from './core-navigation.service';
import { NotificationService, NotificationType } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class BarcodeScannerService {
  isPreparedToScan$ = new BehaviorSubject<boolean>(false);
  isScanning$ = new BehaviorSubject<boolean>(false);

  constructor(
    private navigationService: CoreNavigationService,
    private translocoService: TranslocoService,
    private barcodeParser: BarcodeParserService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private router: Router,
    private appStateService: AppStateService
  ) {}

  supportsScanner(): boolean {
    return Capacitor.isNativePlatform();
  }

  prepare(): void {
    if (!Capacitor.isNativePlatform()) {
      console.log('QR Code scanner is only supported by mobile devices.');
      return;
    }
    BarcodeScanner.prepare()
      .catch(err => {
        console.error('QR-Code scanner is not prepared.', err);
      })
      .then(() => {
        this.isPreparedToScan$.next(true);
      });
  }

  async checkPermission(): Promise<boolean> {
    if (!Capacitor.isNativePlatform()) {
      console.log('QR Code scanner is only supported by mobile devices.');
      return Promise.resolve(false);
    }
    if (!this.isPreparedToScan$.getValue()) {
      console.warn('BarcodeScanner is not prepared.');
      return Promise.resolve(false);
    }
    return new Promise(async resolve => {
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (status.granted) {
        resolve(true);
      } else if (status.denied) {
        BarcodeScanner.openAppSettings();
        resolve(false);
      }
    });
  }

  private showOrHideBody(): void {
    const body = document.body;
    const isScanning = this.isScanning$.getValue();
    if (body) {
      body.style.backgroundColor = isScanning ? 'unset' : '#e9e9e9';
    }
  }

  async startScanner(): Promise<void> {
    if (!Capacitor.isNativePlatform()) {
      throw new Error('QR Code scanner is only supported by mobile devices!');
    }

    const allowed = await this.checkPermission();
    if (!allowed) {
      this.showOperationFailedNotification('qrcode-scanner-no-permission');
      return;
    }

    this.isScanning$.next(true);
    BarcodeScanner.hideBackground();
    this.showOrHideBody();
    const result = await BarcodeScanner.startScan();
    if (!result.hasContent) {
      this.showOperationFailedNotification('qrcode-scanner-no-data');
      return;
    }

    this.isScanning$.next(false);
    this.showOrHideBody();
    const url = result.content || '';

    if (!isValidHttpUrl(url)) {
      this.showOperationFailedNotification('qrcode-scanner-not-url', { result: result.content });
      return;
    }
    const ref = this.modalService.open(LoadingIndicatorModalComponent, {
      backdrop: 'static',
      keyboard: false,
    });
    (ref.componentInstance as LoadingIndicatorModalComponent).loadingHintKey = 'loading-hint-barcode-result';

    this.barcodeParser
      .transformBarcodeUrlToTargetUrl(url)
      .pipe(first())
      .subscribe(
        targetUrl => this.openTargetUrl(targetUrl),
        err => {
          ref.close();
          throw err;
        },
        () => ref.close()
      );
  }

  stopScanner() {
    if (!Capacitor.isNativePlatform()) {
      throw new Error('QR Code scanner is only supported by mobile devices!');
    }
    BarcodeScanner.stopScan();
    this.isScanning$.next(false);
  }

  private openTargetUrl(url: string) {
    if (url !== this.router.routerState.snapshot.url) {
      this.navigationService.goto(url);
    } else {
      this.showOperationFailedNotification('qrcode-scanner-same-url');
      this.appStateService.isSideBarOpen.value = false;
    }
  }

  private showOperationFailedNotification(key: string, keyParams?: HashMap) {
    this.notificationService.pushNotification({
      type: NotificationType.Information,
      title: this.translocoService.translate('notification-error-operation-failed'),
      text: this.translocoService.translate(key, keyParams),
    });
  }
}
