<div class="mass-actions" *transloco="let t">
  <div class="mass-actions__top-container">
    <div class="font-weight-bold mb-6">
      {{ title }}
    </div>
    <div class="mass-actions__alert-wrapper">
      @if (isAlertVisible) {
        <div
          class="alert alert-dismissible fade show p-2"
          [ngClass]="messageType === typeOfMessage.warning ? 'alert-warning' : 'alert-danger'"
          role="alert">
          <fa-icon [icon]="messageIcon" class="fa-solid mass-actions__message-icon mr-2"></fa-icon>
          <div class="mr-3 d-flex align-items-center">{{ message }}</div>
          <button
            type="button"
            class="close pt-0 pr-1 pl-1 pb-1"
            data-dismiss="alert"
            aria-label="Close"
            (click)="closeAlert()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      }
    </div>
    <p class="mass-actions__caption">{{ caption }}</p>
  </div>
  <div class="mass-actions__actions">
    <app-select inputId="cloud-options" class="ml-3" [(ngModel)]="chosenOption" [options]="dropdownOptions">
    </app-select>
    <button
      class="btn btn-light mass-actions__actions__cloud mr-3 p-1 px-2"
      (click)="triggerAction()"
      [disabled]="isLoading || !chosenOption || actionsDisabled">
      @if (!isLoading) {
        <fa-icon class="mass-actions__actions__cloud__icon" [icon]="cloudIcon"></fa-icon>
      }
      @if (isLoading) {
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      }
    </button>
    <div *ngIf="actionResponse" class="mass-actions__actions__message">
      <div [ngClass]="[actionResponse.Success ? 'text-success' : 'text-danger', 'py-2 text-left pl-2 small']">
        {{ t(actionResponse.Key, { param: actionResponse.Param }) }}
      </div>
    </div>
  </div>
</div>
