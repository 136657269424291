import { DeviceOperationalStatus } from '../../model/device.model';

export default class ProgressPercentageCalculator {
  static calc(status?: DeviceOperationalStatus): number | undefined {
    if (!status) {
      return undefined;
    }
    if (typeof status.progressPercentage !== 'undefined') {
      return status.progressPercentage;
    } else if (
      typeof status.progressTemporal !== 'undefined' &&
      status.progressTemporal.estimatedSecondsRemaining != null
    ) {
      const progressTemporal = status.progressTemporal;

      const nowSeconds = new Date().getTime() + 1000;
      const estimatedDurationTotalnSeconds = progressTemporal.estimatedSecondsRemaining || 0;
      const elapsedDurationSeconds = (nowSeconds - progressTemporal.startedAt.getTime()) / 1000;
      return Math.floor((100 * elapsedDurationSeconds) / estimatedDurationTotalnSeconds);
    } else if (typeof status.progressAbsolute !== 'undefined') {
      const progressAbsolute = status.progressAbsolute;
      return Math.floor((100 * progressAbsolute.currentValue) / progressAbsolute.maximumValue);
    }
    return undefined;
  }
}
