import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytes',
})
export class ByteFormatterPipe implements PipeTransform {
  transform(bytes: string | number, precision?: number): any {
    if (isNaN(parseFloat(bytes.toString())) || !isFinite(Number(bytes))) {
      return '-';
    }

    if (typeof precision === 'undefined') {
      precision = 1;
    }

    const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];

    // Please consider that Megabytes are used here, thus a factor of 1000 is used.
    const actualValue = Math.floor(Math.log(Number(bytes)) / Math.log(1000));

    return (Number(bytes) / Math.pow(1000, Math.floor(actualValue))).toFixed(precision) + ' ' + units[actualValue];
  }
}
