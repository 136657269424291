import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckVisibilityService {
  private behaviour: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  set visibility(isVisible: boolean) {
    this.behaviour.next(isVisible);
  }

  get visibility(): boolean {
    return this.behaviour.getValue();
  }

  get asObservable(): Observable<boolean> {
    return this.behaviour;
  }

  stop(): void {
    this.behaviour?.unsubscribe();
  }
}
