import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { hasInvalidUserInput } from '../model/forms';

@Pipe({
  name: 'shouldShowError',
  pure: false,
})
export class ShouldShowErrorMessagePipe implements PipeTransform {
  transform(form: AbstractControl | null): boolean {
    return hasInvalidUserInput(form);
  }
}
