import { Component, Injector, Input, OnInit, effect, inject } from '@angular/core';
import {
  Device,
  DeviceConnectionStatusType,
  DeviceOperationalStatusType,
} from 'src/app/device/device-shared/model/device.model';
import { ProgressInformation, ProgressInformationCalculator } from './progress-information.calculator';
import { SharedDeviceService } from '../../service/shared-device.service';

interface OperationalStatusComponentData {
  operationalStatusKnown: boolean;
  active: boolean;
  message?: string;
  progressInformation?: ProgressInformation;
}

function mapDeviceInfo(device: Device | undefined): OperationalStatusComponentData {
  const status = device?.status;
  return {
    operationalStatusKnown: status?.connection.type === DeviceConnectionStatusType.Connected && !!status.operational,
    active:
      status?.connection.type === DeviceConnectionStatusType.Connected &&
      status?.operational?.type === DeviceOperationalStatusType.Active,
    message: status?.operational?.message,
    progressInformation: !!status ? ProgressInformationCalculator.calculateProgress(status) : undefined,
  };
}

@Component({
  selector: 'app-device-operational-status',
  templateUrl: './operational-status.component.html',
  styleUrls: ['./operational-status.component.scss'],
})
export class OperationalStatusComponent implements OnInit {
  @Input() deviceId!: string;
  data: OperationalStatusComponentData | undefined;

  sharedDeviceService = inject(SharedDeviceService);
  injector = inject(Injector);

  ngOnInit(): void {
    effect(
      () => {
        this.data = mapDeviceInfo(this.sharedDeviceService.device()?.data);
      },
      { injector: this.injector }
    );
  }
}
