import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { DeviceNotification, DeviceNotificationLevel } from 'src/app/device/device-shared/model/device.model';

@Component({
  selector: 'app-device-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() notification!: DeviceNotification;

  toastIconCssClass: string = '';

  locale$?: Observable<string>;

  constructor(translocoService: TranslocoService) {
    this.locale$ = translocoService.langChanges$;
  }

  ngOnInit(): void {
    switch (this.notification?.level) {
      case DeviceNotificationLevel.Critical:
        this.toastIconCssClass = 'critical';
        break;
      case DeviceNotificationLevel.Error:
        this.toastIconCssClass = 'error';
        break;
      case DeviceNotificationLevel.Warning:
        this.toastIconCssClass = 'warning';
        break;
      default:
        this.toastIconCssClass = 'info';
        break;
    }
  }
}
