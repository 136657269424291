import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

function createEndpoint(deviceId: string): string {
  return `${environment.backendApi.shared.uri}/devices/${deviceId}/virtual-laboratory`;
}

export enum MoveDeviceToLaboratoryResult {
  Success,
  NotAuthorized,
  UnprocessableRequest,
  MissingDeviceId,
}

@Injectable({
  providedIn: 'root',
})
export class VirtualLaboratoryDeviceApiService {
  isMovingLaboratory: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  moveDeviceToLaboratory(deviceId: string, laboratoryId: string): Observable<MoveDeviceToLaboratoryResult> {
    if (!deviceId) {
      return of(MoveDeviceToLaboratoryResult.MissingDeviceId);
    }
    this.isMovingLaboratory.next(true);
    const url = createEndpoint(deviceId);
    return this.http
      .put<void>(url, {
        id: laboratoryId,
      })
      .pipe(
        map(response => {
          this.isMovingLaboratory.next(false);
          return response;
        })
      )
      .pipe(
        map(_ => MoveDeviceToLaboratoryResult.Success),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            const httpErrorResponse: HttpErrorResponse = error;
            switch (httpErrorResponse.status) {
              case 403:
                return of(MoveDeviceToLaboratoryResult.NotAuthorized);
              case 422:
                return of(MoveDeviceToLaboratoryResult.UnprocessableRequest);
              default:
                throw error;
            }
          } else {
            throw error;
          }
        })
      );
  }
}
