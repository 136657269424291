export interface ApiUserPreviewReference {
  id: string;
  email: string;
}

export enum VirtualLaboratoryUserAssignmentRole {
  LabManager = 'LAB_MANAGER',
  LabTechnician = 'LAB_TECHNICIAN',
}

export function virtualLaboratoryUserAssignmentRoleSort(
  role: VirtualLaboratoryUserAssignmentRole,
  other: VirtualLaboratoryUserAssignmentRole
) {
  if (
    role === VirtualLaboratoryUserAssignmentRole.LabManager &&
    other !== VirtualLaboratoryUserAssignmentRole.LabManager
  ) {
    return 1;
  } else if (
    other === VirtualLaboratoryUserAssignmentRole.LabManager &&
    role !== VirtualLaboratoryUserAssignmentRole.LabManager
  ) {
    return -1;
  } else {
    return 0;
  }
}

export interface ApiVirtualLaboratoryUserAssignment {
  user: ApiUserPreviewReference;
  role: VirtualLaboratoryUserAssignmentRole;
}

export interface VirtualLaboratoryUserAssignment {
  user: ApiUserPreviewReference;
  role: VirtualLaboratoryUserAssignmentRole;
}
