import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'src/environments/environment';
import { NotificationService, NotificationType, PushedNotification } from '../service/notification.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
    private appInsights: ApplicationinsightsAngularpluginErrorService
  ) {}

  handleError(error: any) {
    if (!environment.production) {
      console.error('Generic Error encountered');
      console.error(error);
    }

    let httpErrorResponse: HttpErrorResponse | undefined;
    if (error instanceof HttpErrorResponse) {
      httpErrorResponse = error as HttpErrorResponse;
    } else if (error.rejection instanceof HttpErrorResponse) {
      httpErrorResponse = error.rejection as HttpErrorResponse;
    }

    if (error.rejection.errorCode === 'access_denied' && error.rejection.errorMessage.includes('AADB2C90091')) {
      return;
    }

    if (httpErrorResponse) {
      this.showNotification(httpErrorResponse);
    } else {
      this.notificationService.pushNotification({
        type: NotificationType.Error,
        title: this.translocoService.translate('notification-error-unexpected-error-title'),
        text: this.translocoService.translate('notification-error-unexpected-error-text'),
        expiresAt: NotificationService.createDefaultExpiry(),
      });
    }

    if (!httpErrorResponse || httpErrorResponse.status < 500) {
      this.appInsights.handleError(error);
    }
  }

  private showNotification(httpErrorResponse: HttpErrorResponse) {
    const resolveErrorType = (status: number) => {
      switch (status) {
        case 401:
          return 'unauthorized';
        case 403:
          return 'forbidden';
        case 409:
          return 'conflict';
        case 422:
          return 'unprocessable-request';
        default:
          return 'unexpected-error';
      }
    };

    const errorType = resolveErrorType(httpErrorResponse.status);

    const notification: PushedNotification | undefined = {
      type: NotificationType.Error,
      title: this.translocoService.translate(`notification-error-${errorType}-title`),
      text: this.translocoService.translate(`notification-error-${errorType}-text`),
      expiresAt: NotificationService.createDefaultExpiry(),
    };

    this.notificationService.pushNotification(notification);
  }
}
