import { Component, OnDestroy, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { BackendPermissionsService, MenuPermissions } from 'src/app/access-rights/backend-permissions.service';
import { emptyPermissions } from 'src/app/access-rights/permissions.utils';
import { SharedMediaQueryService } from 'src/app/shared/service/shared-media-query.service';
import { Subscriptions } from 'src/app/shared/util/Subscriptions';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  menuPermissions: MenuPermissions = emptyPermissions<MenuPermissions>();
  isMobile: boolean | undefined = false;

  private subscriptions: Subscriptions = new Subscriptions();

  constructor(private mediaService: SharedMediaQueryService, private indexService: BackendPermissionsService) {
    this.subscriptions.put(
      this.indexService.index$.subscribe(permissions => {
        this.menuPermissions = permissions;
      })
    );
  }

  ngOnInit(): void {
    this.subscriptions.put(
      this.mediaService
        .checkScreenSize('575', 'portrait')
        .pipe(switchMap(_ => this.mediaService.screenSize$))
        .subscribe(boolean => (this.isMobile = boolean))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribeAll();
  }
}
