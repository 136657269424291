import { ChangeDetectionStrategy, Component, computed, inject, Injector, Input, Signal } from '@angular/core';

import { DeviceResult } from '../../api/shared-device-api.service';
import {
  ConnectionStatusComponentFlags,
  createFlags,
  DeviceStatusType,
} from '../connection-status/connection-status.component';
import { SharedDeviceService } from '../../service/shared-device.service';

export function mapDeviceInfo(device: DeviceResult | undefined): DeviceStatusType {
  return {
    connectionStatusType: device?.data?.status.connection.type,
    operationalStatusType: device?.data?.status.operational?.type,
  } as DeviceStatusType;
}

@Component({
  selector: 'app-summary-connection-status',
  template: '<app-device-connection-status [flags]="flags()" [autoWidth]="true"></app-device-connection-status> ',
  styleUrls: ['./summary-connection-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryConnectionStatusComponent {
  @Input() deviceId?: string;
  @Input() fullWidth = false;
  @Input() autoWidth = false;

  flags: Signal<ConnectionStatusComponentFlags | undefined>;

  constructor() {
    this.flags = computed(() => createFlags(mapDeviceInfo(this.sharedDeviceService.device())));
  }

  sharedDeviceService = inject(SharedDeviceService);
  injector = inject(Injector);
}
