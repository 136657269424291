import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/model/core-api-response';
import { environment } from 'src/environments/environment';
import { DeviceFile } from '../../device-shared/model/device.model';

interface ApiDeviceFileDownloadUri {
  downloadUri: string;
}

interface ApiDeviceFile {
  id: string;
  name: string;
  contentType: string;
  contentLength: number;
  eTag: string;
  createdAt: Date;
  lastModifiedAt: Date;
}

@Injectable({
  providedIn: 'root',
})
export class SharedDeviceFileService {
  constructor(private http: HttpClient) {}

  getFiles(deviceId: string, filetype: string, scope?: string): Observable<DeviceFile[]> {
    const url = new URL(`${environment.backendApi.shared.uri}/devices/${deviceId}/files/${filetype}`);

    if (scope === 'generic') {
      url.searchParams.append('scope', 'generic');
    }

    return this.http
      .get<ApiResponse<ApiDeviceFile[]>>(url.href)
      .pipe(map(response => response.data))
      .pipe(
        map(files => {
          return files.map(file => {
            return {
              id: file.id,
              name: file.name,
              contentType: file.contentType,
              contentLength: file.contentLength,
              eTag: file.eTag,
              createdAt: file.createdAt,
              lastModifiedAt: file.lastModifiedAt,
            } as DeviceFile;
          });
        })
      );
  }

  getFileDownloadUrl(deviceId: string, filetype: string, fileId: string, scope?: string): Observable<string> {
    const url = new URL(
      `${environment.backendApi.shared.uri}/devices/${deviceId}/files/${filetype}/${fileId}/download-uri`
    );

    if (scope === 'generic') {
      url.searchParams.append('scope', 'generic');
    }

    return this.http
      .get<ApiResponse<ApiDeviceFileDownloadUri>>(url.href)
      .pipe(map(response => response.data.downloadUri));
  }
}
