<button
  class="btn btn-vendor"
  [class.btn-vendor]="!isTriggered || inProgress"
  [class.btn-success]="isSuccess"
  [class.btn-danger]="isError"
  [ngClass]="customClass"
  (click)="click()">
  @if (isSuccess) {
    <i class="fa fa-check"></i>
  }
  @if (isError) {
    <i class="fa fa-times"></i>
  }
  @if (inProgress) {
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  }
  {{ label }}
</button>

<ng-template #successModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'async-button-success-modal-title' | transloco }}
    </h4>
  </div>
  <div class="modal-body">
    <p>{{ label }} {{ 'async-button-success-modal-message' | transloco }}.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-vendor" (click)="modal.close()">
      {{ 'modal-close-button-label' | transloco }}
    </button>
  </div>
</ng-template>
<ng-template #errorModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'async-button-error-modal-title' | transloco }}
    </h4>
  </div>
  <div class="modal-body">
    <p>{{ label }} {{ 'async-button-error-modal-message' | transloco }}</p>
    @if (errorMessage) {
      <p>{{ errorMessage }}</p>
    }
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-vendor" (click)="modal.close()">
      {{ 'modal-close-button-label' | transloco }}
    </button>
  </div>
</ng-template>
